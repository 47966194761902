import { ReactNode, useEffect, useRef } from 'react';
import { camelize } from 'humps';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Flash from 'components/Flash';
import Section from 'components/Section';

import FormSection from './Section';

const t = defineMessages({
  error: {
    id: 'generic_form_error_message',
    defaultMessage: 'You have {errorCount} error(s) on your form.',
  },
  unknownError: {
    id: 'generic_form_unknownError',
    defaultMessage: 'An unknown error occurred',
  },
});

interface Props {
  config: { sections: any[] } | any[];
  errors: any;
  renderField: (field: any) => ReactNode;
  autoFocus: boolean;
  autoFocusSectionWithTitle?: string;
  shouldRenderErrors?: boolean;
}

export default function FlattenedForm({
  config,
  errors,
  renderField,
  autoFocus,
  autoFocusSectionWithTitle,
  shouldRenderErrors = true,
}: Props) {
  const { formatMessage } = useIntl();
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView();
    }
  }, []);

  const renderError = () => {
    const errorCount = Object.keys(errors).length;

    if (errorCount === 0) return null;
    if (errors.noSource) {
      const noSourceErrors = errors.noSource.map((err) => err.title || err.detail || formatMessage(t.unknownError));

      return (
        <Section withoutPadding>
          <Flash list={noSourceErrors} />
        </Section>
      );
    }

    return (
      <Section withoutPadding>
        <Flash>
          <FormattedMessage {...t.error} values={{ errorCount }} />
        </Flash>
      </Section>
    );
  };

  const renderSection = (fields, section?: any, sectionIndex = 0) => {
    if (!fields.length) return null;

    const shouldSectionFocus =
      section?.title && autoFocusSectionWithTitle
        ? camelize(section.title) === camelize(autoFocusSectionWithTitle)
        : false;

    const fieldList = fields.map((field, index) => {
      const shouldFieldFocus = autoFocusSectionWithTitle ? false : index === 0 && sectionIndex === 0;

      return renderField({
        ...field,
        autoFocus: autoFocus ? shouldFieldFocus : false,
      });
    });

    const key = section?.key || (section?.title ? camelize(section.title) : `form_section_${fields[0].id}`);

    const formSection = (
      <FormSection key={key} title={section?.title}>
        {fieldList}
      </FormSection>
    );

    return shouldSectionFocus ? (
      <div key={`ref_${key}`} ref={sectionRef}>
        {formSection}
      </div>
    ) : (
      formSection
    );
  };

  const form = Array.isArray(config)
    ? renderSection(config)
    : config.sections.map((section, index) => renderSection(section.fields, section, index));

  return (
    <>
      <div className="form-errors-summary">{shouldRenderErrors ? renderError() : null}</div>

      <div className="form formv1" role="form">
        {form}
      </div>
    </>
  );
}
