import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Dashboard = lazy(() => import('./components/Dashboard'));
const Device = lazy(() => import('./devices/[deviceId]'));

export function WorkbenchRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="devices/:deviceId/*" element={<Device />} />
      <Route path="*" element={<Navigate to="/not_found" replace />} />
    </Routes>
  );
}
