import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

import useToggleDelay from '../hooks/useToggleDelay';
import Button from './Button';

interface Props {
  isActive: boolean;
  buttonIcon: string;
  buttonTitle: string;
  buttonIsDisabled?: boolean;
  children: ReactNode | ((setIsOpen: Dispatch<SetStateAction<boolean>>) => ReactNode);
  direction?: 'row' | 'column';
  maxSize?: number;
}

export default function Popout({
  isActive,
  buttonIcon,
  buttonTitle,
  buttonIsDisabled = false,
  children,
  direction = 'row',
  maxSize,
}: Props) {
  const [isMouseOver, toggleIsMouseOver] = useToggleDelay();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isMouseOver) setIsOpen(false);
  }, [isMouseOver]);

  return (
    <div
      className="rte-popout"
      onMouseEnter={() => toggleIsMouseOver(true)}
      onMouseLeave={() => toggleIsMouseOver(false)}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        isActive={isActive || isOpen}
        title={buttonTitle}
        isDisabled={buttonIsDisabled}
      >
        <Icon>{buttonIcon}</Icon>
      </Button>

      <div
        className={cx('rte-menu-wrapper', `-direction-${direction}`)}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        <div
          className={cx('rte-menu', `-direction-${direction}`)}
          style={{
            opacity: isOpen ? 1 : 0,
            maxWidth: direction === 'row' ? maxSize : undefined,
            maxHeight: direction === 'column' ? maxSize : undefined,
            transform:
              direction === 'row' ? `translateX(${isOpen ? '0' : '-8px'})` : `translateY(${isOpen ? '0' : '-8px'})`,
          }}
        >
          {children instanceof Function ? children(setIsOpen) : children}
        </div>
      </div>
    </div>
  );
}
