import cx from 'classnames';
import numberOrBoundary from 'lodash/clamp';
import range from 'lodash/range';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppSelector } from 'hooks/redux';

import Icon from 'components/Icon';

const t = defineMessages({
  stats: {
    id: 'paginator_stats',
    defaultMessage: 'Showing {minItem} to {maxItem} of {total} results.',
  },
});

export default function Paginator({
  networkType,
  pagesBefore = 3,
  pagesAfter = 3,
  isNarrow = false,
  loadPage,
}: {
  networkType: string;
  pagesBefore?: number;
  pagesAfter?: number;
  isNarrow?: boolean;
  loadPage: (page: number) => void;
}) {
  const pagination = useAppSelector((state) => state.pagination);

  const {
    currentPage = 0,
    pageSize = 30,
    totalCount = 0,
    totalPages = 0,
  }: any = pagination && pagination[networkType] ? pagination[networkType] : {};

  return (
    <Pages
      currentPage={currentPage}
      pageSize={pageSize}
      totalCount={totalCount}
      totalPages={totalPages}
      pagesBefore={pagesBefore}
      pagesAfter={pagesAfter}
      isNarrow={isNarrow}
      loadPage={loadPage}
    />
  );
}

export function Pages({
  currentPage = 0,
  pageSize = 30,
  totalCount = 0,
  totalPages = 0,
  pagesBefore = 3,
  pagesAfter = 3,
  isNarrow = false,
  loadPage,
}: {
  currentPage?: number;
  pageSize?: number;
  totalCount?: number;
  totalPages?: number;
  pagesBefore?: number;
  pagesAfter?: number;
  isNarrow?: boolean;
  loadPage: (page: number) => void;
}) {
  const actualPage = currentPage + 1;

  const renderBefore = () => {
    if (actualPage === 1) return null;

    return (
      <li>
        <button type="button" className="pagination__entry" onClick={() => loadPage(actualPage - 2)}>
          <Icon>navigate_before</Icon>
        </button>
      </li>
    );
  };

  const renderEntry = (index: number) => {
    return (
      <li key={index}>
        <button
          type="button"
          className={cx('pagination__entry', {
            'is-current': actualPage === index,
          })}
          onClick={() => loadPage(index - 1)}
        >
          {index}
        </button>
      </li>
    );
  };

  const renderEntries = () => {
    const minPage = actualPage - pagesBefore;
    const maxPage = actualPage + pagesAfter;

    const lowerBound = numberOrBoundary(minPage, 1, Infinity);
    const upperBound = numberOrBoundary(maxPage, 1, totalPages);

    return range(lowerBound, upperBound + 1).map((i) => renderEntry(i));
  };

  const renderAfter = () => {
    if (actualPage === totalPages) return null;

    return (
      <li>
        <button type="button" className="pagination__entry" onClick={() => loadPage(actualPage)}>
          <Icon>navigate_next</Icon>
        </button>
      </li>
    );
  };

  if ((totalPages === 1 && pageSize !== totalCount) || totalCount === 0) {
    return null;
  }

  const maxItemIndexOnPage = actualPage * pageSize <= totalCount ? actualPage * pageSize : totalCount;

  const minItemIndexOnPage = actualPage > 1 ? (actualPage - 1) * pageSize + 1 : 1;

  return (
    <div className={cx('pagination', { '-is-narrow': isNarrow })}>
      <h5 className="pagination__info">
        <FormattedMessage
          {...t.stats}
          values={{
            minItem: minItemIndexOnPage,
            maxItem: maxItemIndexOnPage,
            total: totalCount,
          }}
        />
      </h5>
      <ul className="pagination__actions">
        {renderBefore()}
        {renderEntries()}
        {renderAfter()}
      </ul>
    </div>
  );
}
