import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';

import ErrorPage from '../components/ErrorPage';

const t = defineMessages({
  headline: {
    id: 'notfound_headline',
    defaultMessage: 'Oops, looks like the page you were looking for cannot be found.',
  },
  backHome: {
    id: 'notfound_back_home',
    defaultMessage: 'Bring me back home',
  },
  backHomeTitle: {
    id: 'notfound_back_home_title',
    defaultMessage: 'Back to dashboard',
  },
});

export default function NotFound() {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage>
      <div className="page-container">
        <h2 className="page-title is-shaking">
          <Icon>location_searching</Icon>
          <br />
          <FormattedMessage {...t.headline} />
        </h2>
        <br />

        <NavLink to="/" title={formatMessage(t.backHomeTitle)}>
          <Button color="success">
            <FormattedMessage {...t.backHome} />
          </Button>
        </NavLink>
      </div>
    </ErrorPage>
  );
}
