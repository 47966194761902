import { ReactNode, useRef } from 'react';
import { decamelize } from 'humps';
import { MessageDescriptor, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { cancelActivity } from 'store/modules/activity';

import { SECOND_IN_MILLIS } from 'utils/date';
import { isMessageDescriptor } from 'utils/intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import ActionButton from 'components/ActionButton';

import t from './translations';

interface TableRemoveOverlayProps {
  activityName?: string;
  resourceType: string;
  resourceId: string;
  idAttribute?: string;

  confirmationQuestion: MessageDescriptor | ReactNode;
  confirm: () => void;
}

export default function TableRemoveOverlay({
  resourceType,
  resourceId,
  idAttribute = 'id',
  activityName,
  confirmationQuestion: rawQuestion,
  confirm,
}: TableRemoveOverlayProps) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const nodeRef = useRef<HTMLDivElement>(null);
  const activity = useAppSelector((state) => state.activity);

  const name = activityName || `REMOVE_${decamelize(resourceType).toUpperCase()}`;

  const payloadId = activity?.payload?.[resourceType]?.[idAttribute];

  const isDeleting = activity && activity.name === name && payloadId && payloadId === resourceId;

  const confirmationQuestion = isMessageDescriptor(rawQuestion) ? formatMessage(rawQuestion) : rawQuestion;

  return (
    <CSSTransition in={isDeleting} timeout={SECOND_IN_MILLIS} classNames="table-overlay" nodeRef={nodeRef}>
      {isDeleting ? (
        <div ref={nodeRef} className="table-overlay-remove">
          <h5>{confirmationQuestion || formatMessage(t.defaultConfirmationQuestion)}</h5>
          <ActionButton
            remove
            icon="check"
            title={t.confirmDelete}
            onClick={(e) => {
              e.stopPropagation();
              confirm();
              dispatch(cancelActivity());
            }}
          />
          <ActionButton
            remove
            icon="close"
            title={t.cancelDelete}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cancelActivity());
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </CSSTransition>
  );
}
