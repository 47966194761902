/** Used to generate unique IDs. */
const idCounter = {};

export default function uniqueId(prefix = '$prefix$') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  const id = ++idCounter[prefix];

  if (prefix === '$prefix$') {
    return `${id}`;
  }

  return `${prefix}${id}`;
}
