import { schema } from 'normalizr';

import { parseDate, parseDateTime, schemaDeserializers } from 'api/deserialize';

import { registerFormErrorAction } from 'store/modules/errors';
import { networkActionTypes } from 'store/utils';

import { billingItemSchema } from './billingItems';

const exportableSchema = new schema.Entity(
  'exportables',
  {
    billingItems: [billingItemSchema],
  },
  {
    processStrategy: (exportable) => ({
      ...exportable,
      ...schemaDeserializers({
        startDate: parseDate,
        endDate: parseDate,
        exportedAt: parseDateTime,
        downloadedAt: parseDateTime,
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })(exportable),
      centers: exportable.centers.map(
        schemaDeserializers({
          createdAt: parseDateTime,
          updatedAt: parseDateTime,
        })
      ),
    }),
  }
);

export const GET_EXPORTABLES = networkActionTypes('GET_EXPORTABLES');

const PAGE_SIZE = 30;

export const getExportables = ({
  centerIds,
  exportTypes,
  page,
}: {
  centerIds: string[];
  exportTypes: string[];
  page: number;
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_EXPORTABLES,
    url: 'exportables',
    params: { centerIds, exportTypes, page, pageSize: PAGE_SIZE },
    method: 'GET',
    normalizeSchema: { data: [exportableSchema] },
  },
});

export const GET_EXPORTABLE = networkActionTypes('GET_EXPORTABLE');

export const getExportable = (exportableId: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_EXPORTABLE,
    url: `exportables/${exportableId}`,
    method: 'GET',
    normalizeSchema: { exportable: exportableSchema },
  },
});

export const GET_EXPORTABLE_BILLING_ITEMS = networkActionTypes('GET_EXPORTABLE_BILLING_ITEMS');

export const getExportableItems = (
  exportableId: string,
  params: { errored?: boolean; page: number }
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_EXPORTABLE_BILLING_ITEMS,
    url: `exportables/${exportableId}/billing_items`,
    params: { ...params, pageSize: 50 },
    method: 'GET',
    actionPayload: { exportableId },
    normalizeSchema: { data: [billingItemSchema] },
  },
});

export const CREATE_EXPORTABLE = networkActionTypes('CREATE_EXPORTABLE');

export const createExportable = (
  exportable: {
    exportType: ExportableT['exportType'];
    centerIds: string[];
    startDate: Date | undefined;
    endDate: Date;
  },
  actionPayload: Record<string, unknown>
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_EXPORTABLE,
    url: 'exportables',
    method: 'POST',
    payload: { exportable },
    actionPayload,
    normalizeSchema: { exportable: exportableSchema },
  },
});

export const SYNC_EXPORTABLE = networkActionTypes('SYNC_EXPORTABLE');

export const syncExportable = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: SYNC_EXPORTABLE,
    url: `exportables/${id}/sync`,
    method: 'PUT',
    normalizeSchema: { exportable: exportableSchema },
  },
});

export const READY_EXPORTABLE = networkActionTypes('READY_EXPORTABLE');

registerFormErrorAction(READY_EXPORTABLE.FAILURE);

export const readyExportable = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: READY_EXPORTABLE,
    url: `exportables/${id}/ready`,
    method: 'PUT',
    normalizeSchema: { exportable: exportableSchema },
  },
});

export const DROP_BILLING_ITEM = networkActionTypes('DROP_BILLING_ITEM');

export const dropBillingItem = (exportableId: string, itemId: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: DROP_BILLING_ITEM,
    url: `exportables/${exportableId}/billing_items/${itemId}/remove_from_exportable`,
    method: 'POST',
    actionPayload: { exportableId, itemId },
  },
});
