import { RightClaimT } from 'rights';

export interface CheckAuthOptions {
  currentUser?: UserT | null;
  patient?: PatientT | null;
  rights?: RightClaimT[];
  patientStatus?: 'enrolled' | 'not_enrolled';
  booleanMatch?: boolean;
  partialRights?: boolean;
}

export default function checkAuth({
  currentUser,
  patient,
  rights,
  patientStatus,
  booleanMatch = true,
  partialRights = true,
}: CheckAuthOptions) {
  if (!currentUser && Array.isArray(rights)) {
    return false;
  }

  if (!patient && patientStatus) {
    return false;
  }

  const rightsCheck = partialRights ? 'some' : 'every';
  const userRights = (currentUser?.rights || []).map((right) => right.name);

  const rightsMatch = Array.isArray(rights) ? rights[rightsCheck]((right) => userRights.includes(right)) : true;

  const patientStatusMatch = patientStatus ? patient?.enrollmentStatus === patientStatus : true;

  return booleanMatch && rightsMatch && patientStatusMatch;
}
