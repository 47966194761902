import { CSSProperties, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

export type Props = {
  id: string;
  defaultValue?: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
  style?: CSSProperties;
};

export default function Boolean({ defaultValue = false, onChange, disabled = false, style }: Props) {
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const [active, setActive] = useState(defaultValue);

  useEffect(() => {
    setActive(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChangeRef.current(active);
  }, [active]);

  const toggleSwitch = () => {
    if (!disabled) setActive((prevActive) => !prevActive);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div
        role="presentation"
        className={cx('form-toggle', {
          'is-active': active,
          'is-disabled': disabled,
        })}
        style={style}
        onClick={toggleSwitch}
      >
        <div className="form-toggle-button" />
      </div>
    </div>
  );
}
