import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/Button';
import Icon from 'components/Icon';

import ErrorPage from '../components/ErrorPage';

const t = defineMessages({
  headline: {
    id: 'error_headline',
    defaultMessage: 'Oops, looks like an error has occurred.',
  },
  refreshState: {
    id: 'error_refresh_state',
    defaultMessage: 'Reload the app',
  },
  refreshStateTitle: {
    id: 'error_refresh_state_title',
    defaultMessage: 'Reload this page',
  },
});

type Props = {
  onClickCallBack?: () => void;
};

export default function Error({ onClickCallBack }: Props) {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage onClick={() => onClickCallBack?.()}>
      <div className="page-container">
        <h2 className="page-title is-shaking">
          <Icon>flash_on</Icon>
          <br />
          <FormattedMessage {...t.headline} />
        </h2>

        <br />

        <a href={window.location.origin} title={formatMessage(t.refreshStateTitle)}>
          <Button color="success">
            <FormattedMessage {...t.refreshState} />
          </Button>
        </a>
      </div>
    </ErrorPage>
  );
}
