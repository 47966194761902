import { IntlShape } from 'react-intl';

import { http } from 'api/client';
import { deserialize, parseDate, parseDateTime, parseForm } from 'api/deserialize';
import { FileDescriptor, PaginationMeta } from 'api/types';

import { FormT } from 'components/Form/schema';
import extractConfigValues from 'components/FormV3/dynamic/extractConfigValues';
import { Rte } from 'components/RTE/domain';

export interface PlanningAbsence {
  avatarUrl: string;
  endDate: Date;
  firstName: string;
  id: string;
  lastName: string;
  note: string;
  patientId: string;
  startDate: Date;
  title: string;
}

export interface Complication {
  id: string;
  session: SessionT;
  occurredAt: Date;
  reportedBy: UserT;
  params: ComplicationParam[];
  title: string;
  type: string;
  report: string;
  createdAt: Date;
  updatedAt: Date;
  site: { id: string };
  canModify: boolean;
}

interface ComplicationParam {
  id: string;
  label: string;
  value: string | boolean | Date;
}

export interface Encounter {
  id: string;
  patientId: string;
  title: string | null;
  body: Rte.Node[] | null;
  date: Date;
  user: UserT;
  documents: FileDescriptor[];
  createdAt: Date;
  updatedAt: Date;
  encounterTypeId: string;
  encounterTypeName: string;
  encounterFormDefinition: FormT | null;
  encounterTypeTemplate: FormT | null;
  values: Record<string, any> | null;
}

export interface EncounterType {
  id: string;
  name: string;
  body: Rte.Node[] | null;
  encounterFormDefinition: FormT | null;
  builtin: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface Address {
  id: string;
  addressTypes: string[];
  addressLine: string;
  zipCode: string;
  municipality: string;
  state: string;
  country: string;
  note: string | null;
}

export async function fetchPatient(patientId: string) {
  const { data } = await http.get<PatientT>(`patients/${patientId}`);

  return deserializePatient(data);
}

interface FetchComplicationsArg {
  id: string;
  type: 'treatment' | 'access_site';
  complicationTypes?: string[];
  page?: number;
  pageSize?: number;
}

export async function fetchComplications({
  id,
  type,
  complicationTypes,
  page = 0,
  pageSize = 30,
}: FetchComplicationsArg) {
  const { data } = await http.get<{
    data: Complication[];
    meta: PaginationMeta;
  }>(`patients/${id}/complications`, {
    params: {
      type,
      complicationTypes,
      page,
      pageSize,
    },
  });

  return deserialize(data, {
    'data[].createdAt': parseDateTime,
    'data[].updatedAt': parseDateTime,
    'data[].occurredAt': parseDateTime,
    'data[].meta.interruptionResumed': parseDateTime,
    'data[].reportedBy.createdAt': parseDateTime,
    'data[].reportedBy.updatedAt': parseDateTime,
    'data[].params[]': (param) =>
      param.id === 'interruption_resumed' ? deserialize(param, { value: parseDateTime }) : param,
    'data[].site.createdAt': parseDateTime,
    'data[].site.updatedAt': parseDateTime,
    'data[].site.placedAt': parseDateTime,
    'data[].site.lastUsed': parseDateTime,
    'data[].site.technique.createdAt': parseDateTime,
    'data[].site.technique.updatedAt': parseDateTime,
    'data[].session.createdAt': parseDateTime,
    'data[].session.updatedAt': parseDateTime,
    'data[].session.startedAt': parseDateTime,
    'data[].session.endedAt': parseDateTime,
    'data[].session.center.createdAt': parseDateTime,
    'data[].session.center.updatedAt': parseDateTime,
    'data[].session.position.createdAt': parseDateTime,
    'data[].session.position.updatedAt': parseDateTime,
    'data[].session.backTransportType.createdAt': parseDateTime,
    'data[].session.backTransportType.updatedAt': parseDateTime,
    'data[].session.forthTransportType.createdAt': parseDateTime,
    'data[].session.forthTransportType.updatedAt': parseDateTime,
    'data[].session.room.createdAt': parseDateTime,
    'data[].session.room.updatedAt': parseDateTime,
    'data[].session.room.positions[].createdAt': parseDateTime,
    'data[].session.room.positions[].updatedAt': parseDateTime,
    'data[].session.room.stock.createdAt': parseDateTime,
    'data[].session.room.stock.updatedAt': parseDateTime,
  });
}

interface EncountersArg {
  page?: number;
  pageSize?: number;
  patientId?: string;
  professions?: string[];
  types?: string[];
}

export async function fetchEncounters({ page = 0, pageSize = 30, patientId, professions, types }: EncountersArg) {
  const { data } = await http.get<{ data: Encounter[]; meta: PaginationMeta }>(`patients/${patientId}/encounters`, {
    params: { page, pageSize, types, professions },
  });

  return {
    data: data.data.map(deserializeEncounter),
    meta: data.meta,
  };
}

export interface SessionPeriodT {
  id: string;
  date: Date;
  period?: string;
}

export async function fetchSessionsPeriod({ patientId, sessionId }: { patientId: string; sessionId: string }) {
  const { data } = await http.get<{ sessionPeriod: SessionPeriodT }>(
    `patients/${patientId}/sessions/${sessionId}/period`
  );

  return deserialize(data.sessionPeriod, {
    date: parseDateTime,
  });
}

export async function fetchSessionsPeriods({
  date,
  endDate,
  startDate,
  patientId,
}: {
  date?: Date | null;
  endDate?: Date | null;
  startDate?: Date | null;
  patientId: string;
}) {
  const { data } = await http.get<{ sessionPeriods: SessionPeriodT[] }>(`patients/${patientId}/sessions/periods`, {
    params: { startDate: startDate || date, endDate: endDate || date },
  });

  return data.sessionPeriods.map((sessionDate) =>
    deserialize(sessionDate, {
      date: parseDateTime,
    })
  );
}

export function deserializePatient(patient: PatientT): PatientT {
  return deserialize(patient, {
    'birthdate': parseDate,
    'enrolledAt': parseDate,
    'dialysisSince': parseDate,
    'lastUnenrollmentAt': parseDate,
    'updatedAt': parseDateTime,
    'createdAt': parseDateTime,
    'attendingDoctor.createdAt': parseDateTime,
    'attendingDoctor.updatedAt': parseDateTime,
    'identifiers[].createdAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
    'activePatientTreatments[].createdAt': parseDateTime,
    'activePatientTreatments[].updatedAt': parseDateTime,
    'activePatientTreatments[].startDate': parseDateTime,
    'activePatientTreatments[].endDate': parseDateTime,
  });
}

export function deserializeEncounter(encounter: Encounter) {
  const serialized = deserialize(encounter, {
    'date': parseDateTime,
    'encounterType': deserializeEncounterType,
    'createdAt': parseDateTime,
    'updatedAt': parseDateTime,
    'documents[].createdAt': parseDateTime,
  });

  const encounterTypeTemplate = serialized.encounterFormDefinition
    ? parseForm(serialized.encounterFormDefinition, serialized.values)
    : null;

  const values = encounterTypeTemplate ? extractConfigValues(encounterTypeTemplate) : {};

  return {
    ...serialized,
    encounterTypeTemplate,
    values,
  };
}

export function deserializeEncounterType(encounterType: EncounterType) {
  return deserialize(encounterType, {
    encounterFormDefinition: parseForm,
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
    deletedAt: parseDateTime,
  });
}

export function selectSessionDateOptions(sessionPeriods: SessionPeriodT[], formatDate: IntlShape['formatDate']) {
  return sessionPeriods.map(({ date, id, period }) => {
    const formattedDate = formatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    return {
      date,
      label: period ? `${formattedDate} (${period})` : formattedDate,
      value: id,
    };
  });
}
