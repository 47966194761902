import { CSSProperties, FocusEvent, MouseEvent, ReactNode } from 'react';
import cx from 'classnames';

export interface ButtonProps {
  children?: ReactNode;
  color?: AppColor | 'white' | 'gray';
  size?: 'small';
  block?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event: MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (event: FocusEvent<HTMLButtonElement>) => void;
  title?: string;
  type?: 'button' | 'submit';
}

export default function Button({
  className,
  color = 'gray',
  size,
  block = false,
  style,
  children,
  disabled = false,
  isLoading = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onBlur,
  title,
  type = 'button',
}: ButtonProps) {
  return (
    <button
      type={type}
      className={cx('button', className, `-color-${color}`, {
        '-size-small': size === 'small',
        '-is-block': block,
        '-is-loading': isLoading && !disabled,
      })}
      style={style}
      disabled={disabled || isLoading}
      onClick={onClick}
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
}
