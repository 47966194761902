import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Formik, FormikErrors, Form as FormikForm, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { defineMessages, useIntl } from 'react-intl';

import { useAppSelector } from 'hooks/redux';
import { ShouldTrigger, useTrigger } from 'hooks/useTrigger';

import { FormT } from 'components/Form/schema';
import { Rte } from 'components/RTE/domain';

import FormError from './components/FormError';
import useErrorParser from './useErrorParser';

type FormStyle =
  // Styled for use in ActionBar.
  | 'actionbar'
  // Styled like a classic HTML form.
  // Can be used to render forms outside of an ActionBar.
  | 'classic';

export interface FormProps<Values extends FormikValues = FormikValues> {
  onSubmit: SubmitHandler<Values>;
  shouldSubmit?: ShouldTrigger;
  initialValues: Values | InitialValues<Values>;
  formStyle?: FormStyle;
  children: ReactNode;
}

export default function Form<Values extends FormikValues = FormikValues>({
  onSubmit,
  shouldSubmit,
  initialValues,
  formStyle = 'actionbar',
  children,
}: FormProps<Values>) {
  const { formatMessage } = useIntl();
  const formikPropsRef = useRef<FormikProps<Values>>();

  const enhancedOnSubmit = useSubmitHandler(onSubmit);
  const [globalFormErrors, fieldErrors] = useExternalErrors<Values>(formikPropsRef);

  useTrigger(shouldSubmit, () => {
    formikPropsRef.current?.submitForm();
  });

  useEffect(() => {
    if (fieldErrors) {
      formikPropsRef.current?.setErrors(fieldErrors);
    }
  }, [fieldErrors]);

  return (
    <Formik onSubmit={enhancedOnSubmit} initialValues={initialValues as Values}>
      {(formikProps) => {
        formikPropsRef.current = formikProps;

        const errorCount = (globalFormErrors?.length ?? 0) + Object.keys(formikProps.errors).length;

        const showError = formikProps.submitCount > 0 && errorCount;

        return (
          <FormikForm className={cx('formv3', `form-style-${formStyle}`)}>
            {showError ? (
              <div className="formv3__global-error">
                <FormError
                  error={formatMessage(t.formContainsErrors, {
                    amount: errorCount,
                  })}
                  detailsList={globalFormErrors}
                />
              </div>
            ) : null}

            {children}
          </FormikForm>
        );
      }}
    </Formik>
  );
}

/**
 * The `onSubmit` prop that the developer can use is a modified version of the
 * one from Formik. We override the `setErrors` method that's available in the
 * `formikHelpers` object (2nd argument of onSubmit) so we can pass `ErrorT[]`
 * as well as the type Formik supports by default.
 *
 * When `ErrorT[]` is passed, we transform the array before passing it to the
 * original `setErrors` function so the developer doesn't have to do this work.
 */

export type SubmitHandler<Values extends FormikValues> = (
  values: Values,
  formikHelpers: Omit<FormikHelpers<Values>, 'setErrors'> & {
    setErrors: (errors: ErrorT[] | FormikErrors<Values>) => void;
  }
) => void | Promise<any>;

function useSubmitHandler<Values extends FormikValues = FormikValues>(onSubmit: SubmitHandler<Values>) {
  const parseErrors = useErrorParser();

  return useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      function setErrors(errors: ErrorT[] | FormikErrors<Values>) {
        const fieldErrors = Array.isArray(errors) ? parseErrors(errors, values).fieldErrors : errors;

        helpers.setErrors(fieldErrors);
      }

      onSubmit(values, { ...helpers, setErrors });
    },
    [onSubmit, parseErrors]
  );
}

function useExternalErrors<Values extends FormikValues = FormikValues>(
  formikPropsRef: MutableRefObject<FormikProps<Values> | undefined>
) {
  const [globalFormErrors, setGlobalFormErrors] = useState<string[]>();
  const [fieldErrors, setFieldErrors] = useState<FormikErrors<Values>>();
  const storeErrors = useAppSelector((state) => state.errors);
  const parseErrors = useErrorParser();

  useEffect(() => {
    const errors = storeErrors.filter((error) => error.handlerType === 'form');

    if (errors.length > 0) {
      const values = formikPropsRef.current?.values;
      const { globalFormErrors, fieldErrors } = parseErrors(errors, values);

      setGlobalFormErrors(globalFormErrors);
      setFieldErrors(fieldErrors);
    }
  }, [formikPropsRef, parseErrors, storeErrors]);

  return [globalFormErrors, fieldErrors] as const;
}

type SingularFormFieldValue = string | number | boolean | Date | File | Rte.Node[] | FormT;

export type InitialValues<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends SingularFormFieldValue | null | undefined
    ? Obj[Prop] | null
    : Obj[Prop] extends (infer A)[]
    ? InitialValues<A>[] | null
    : InitialValues<Obj[Prop]>;
};

const t = defineMessages({
  formContainsErrors: {
    id: 'form_form_contains_errors',
    defaultMessage: 'Your form contains {amount, plural, one {# error} other {# errors}}.',
  },
});
