import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Checkbox from 'components/Checkbox';

import t from './translations';

export default function CheckboxGroup({
  value: externalValue,
  defaultValue,
  options,
  onChange,
}: {
  value?: CheckboxOptionT['value'][];
  defaultValue?: CheckboxOptionT[];
  options: CheckboxOptionT[];
  onChange: (val: CheckboxOptionT['value'][]) => void;
}) {
  const { formatMessage } = useIntl();

  const [selectedValues, setSelectedValues] = useState<CheckboxOptionT[]>(defaultValue ?? externalValue ?? []);

  const allOptionsSelected = options.every(({ value, disabled }) =>
    !disabled ? selectedValues.includes(value) : true
  );

  const toggleText = formatMessage(allOptionsSelected ? t.deselectAll : t.selectAll);

  useEffect(() => {
    if (externalValue) setSelectedValues(externalValue);
  }, [externalValue]);

  const select = (values: CheckboxOptionT[]) => {
    // prevent setting state twice to avoid flashing
    if (!externalValue) setSelectedValues(values);
    onChange(values);
  };

  const toggle = () => {
    if (allOptionsSelected) {
      // Keep all the selected options that are disabled.
      select(selectedValues.filter((val) => options.find(({ value, disabled }) => value === val && disabled)));
    } else {
      // Keep all options that are not disabled or that are disabled and already selected.
      select(
        options.reduce(
          (result, { value, disabled }) =>
            !disabled || selectedValues.find((val) => val === value) ? [...result, value] : result,
          [] as CheckboxOptionT[]
        )
      );
    }
  };

  const newSelectedValues = (newValue: any): any[] => {
    if (selectedValues.includes(newValue)) {
      return selectedValues.filter((selectedValue) => selectedValue !== newValue);
    }

    return [...selectedValues, newValue];
  };

  return (
    <div className="form-select form-checkbox-wrapper">
      <div className="form-checkbox-group">
        {options ? (
          <div>
            {options.map(({ label, value, disabled }) => (
              <Checkbox
                key={`check_box_${label}_${value.toString()}`}
                label={label}
                value={value}
                checked={selectedValues.includes(value)}
                disabled={disabled}
                onChange={(newValue) => select(newSelectedValues(newValue))}
              />
            ))}
          </div>
        ) : null}
      </div>

      <button type="button" className="form-label-link" onClick={toggle}>
        {toggleText}
      </button>
    </div>
  );
}
