/*global document*/

export default async function fontLoader() {
  const styleTag = document.createElement('style');

  const { default: appIconsLoader } = await import('./app-icons/appIconsLoader');
  const { default: materialIconsLoader } = await import('./material-icons/materialIconsLoader');
  const { default: openSansLoader } = await import('./open-sans/openSansLoader');

  const fonts = [openSansLoader(), materialIconsLoader(), appIconsLoader()].join('\n');
  const allFonts = document.createTextNode(fonts);
  styleTag.appendChild(allFonts);

  document.head.appendChild(styleTag);
}
