import {
  CheckboxGroupInput,
  CheckboxGroupInputProps,
  CheckboxOption,
  DateTimeInput,
  DateTimeInputProps,
  ListInput,
  ListInputProps,
  RadioGroupInput,
  RadioGroupInputProps,
  SearchInput,
  SearchInputProps,
  TextInput,
  TextInputProps,
} from 'components/Inputs';
import { ListOption, Value } from 'components/Inputs/ListInput';
import { BaseInputProps } from 'components/Inputs/types';

import FilterField from './Field';

export type FilterFieldProps<P> = Omit<P, keyof BaseInputProps<any>> &
  Pick<BaseInputProps<any>, 'name' | 'autoFocus'> & { label?: string };

export function SearchFilterField(props: FilterFieldProps<SearchInputProps>) {
  return <FilterField Input={SearchInput} {...props} />;
}

export function CheckboxGroupFilterField<O extends CheckboxOption = CheckboxOption>(
  props: FilterFieldProps<CheckboxGroupInputProps<O>>
) {
  return <FilterField Input={CheckboxGroupInput} {...props} />;
}

export function RadioGroupFilterField(props: FilterFieldProps<RadioGroupInputProps>) {
  return <FilterField Input={RadioGroupInput} {...props} />;
}

export function ListFilterField<
  V extends Value = Value,
  O extends ListOption = ListOption,
  M extends boolean = boolean
>(props: FilterFieldProps<ListInputProps<V, O, M>>) {
  return (
    <FilterField
      Input={ListInput}
      {...(props as any)}
      clearable={props.clearable === undefined ? false : props.clearable}
    />
  );
}

export function DateFilterField(props: FilterFieldProps<Omit<DateTimeInputProps, 'ui'>>) {
  return (
    <FilterField
      Input={DateTimeInput}
      {...props}
      ui="date"
      clearable={props.clearable === undefined ? false : props.clearable}
    />
  );
}

export function TimeFilterField(props: FilterFieldProps<Omit<DateTimeInputProps, 'ui'>>) {
  return (
    <FilterField
      Input={DateTimeInput}
      {...props}
      ui="time"
      clearable={props.clearable === undefined ? false : props.clearable}
    />
  );
}

export function TextFilterField(props: FilterFieldProps<TextInputProps>) {
  return <FilterField Input={TextInput} {...props} />;
}
