// TODO: Make a proper time component that localises well to AM/PM (Taiwan) and 24h time selection

import { Component } from 'react';
import cx from 'classnames';
import range from 'lodash/range';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Select from 'react-select';

import { ClearIndicator, DropdownIndicator } from 'components/ReactSelect';
import { SelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import t from './translations';

const getMinutes = (value) => {
  const minutes = value.getMinutes();
  return minutes - (minutes % 5);
};

export type TimeProps = {
  id: string;
  label?: string;
  value: Date | null | undefined;
  defaultValue?: Date | null | undefined;
  onChange: (arg0: Date | null | undefined) => void;
  disabled?: boolean;
  autoFocus?: boolean;
};

type State = {
  hours: number | null | undefined;
  minutes: number | null | undefined;
};

const newState = (props: Props): { hours: number | null | undefined; minutes: number | null | undefined } => {
  const { value, defaultValue } = props;
  const val = value || defaultValue;

  return {
    hours: val ? val.getHours() : undefined,
    minutes: val ? getMinutes(val) : undefined,
  };
};

type Props = TimeProps & WrappedComponentProps;

class Time extends Component<Props, State> {
  static displayName = 'TimeFormItem';
  static contextType = SelectMenuPortalTargetContext;

  constructor(props: Props) {
    super(props);
    this.state = newState(props);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState(newState(this.props));
    }
  }

  selectTime({ hours, minutes }: { hours?: number; minutes?: number }) {
    const { onChange } = this.props;
    const { hours: stateHours, minutes: stateMinutes } = this.state;

    const h = hours || hours === 0 ? hours : stateHours;
    const m = minutes || minutes === 0 ? minutes : stateMinutes;

    this.setState({
      hours: h,
      minutes: m,
    });

    if ((h || h === 0) && (m || m === 0)) {
      onChange(new Date(1970, 0, 1, h, m));
    }
  }

  clearTime() {
    const { onChange } = this.props;

    this.setState({ hours: undefined, minutes: undefined });
    onChange(undefined);
  }

  renderClearButton() {
    const { defaultValue, disabled } = this.props;
    const { hours, minutes } = this.state;

    if (!defaultValue) return null;
    if ((hours === undefined && minutes === undefined) || disabled === true) return null;

    return <ClearIndicator onClick={() => this.clearTime()} />;
  }

  render() {
    const { hours, minutes } = this.state;
    const { id, disabled, autoFocus, intl } = this.props;

    const toOptions = (number) => {
      const numberString = `${number}`;
      const finalString = numberString.length === 1 ? `0${numberString}` : numberString;
      return {
        label: finalString,
        value: number,
      };
    };

    const menuPortalTarget = this.context;

    const hourOptions = range(0, 24).map(toOptions);
    const minuteOptions = range(0, 59, 5).map(toOptions);
    const hourOption = hourOptions.find((o) => o.value === hours) || null;
    const minuteOption = minuteOptions.find((o) => o.value === minutes) || null;

    return (
      <div
        className={cx('Select__time-container', 'form-select', {
          'Select__time-container--has-value': hours || minutes,
        })}
      >
        <Select
          className="Select form-select"
          classNamePrefix="Select"
          isDisabled={disabled}
          name={`${id}_hours`}
          options={hourOptions}
          placeholder={intl.formatMessage(t.hourPlaceholder)}
          value={hourOption}
          onChange={(option: any) => this.selectTime({ hours: option ? option.value : undefined })}
          isClearable={false}
          openMenuOnFocus
          autoFocus={autoFocus}
          components={{ DropdownIndicator: DropdownIndicator as any }}
        />
        <p>:</p>
        <Select
          className="Select form-select"
          classNamePrefix="Select"
          isDisabled={disabled}
          name={`${id}_minutes`}
          options={minuteOptions}
          placeholder={intl.formatMessage(t.minutePlaceholder)}
          value={minuteOption}
          onChange={(option: any) => this.selectTime({ minutes: option ? option.value : undefined })}
          isClearable={false}
          openMenuOnFocus
          components={{ DropdownIndicator: DropdownIndicator as any }}
          menuPortalTarget={menuPortalTarget}
          styles={menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 4 }) } : undefined}
        />

        {this.renderClearButton()}
      </div>
    );
  }
}

export default injectIntl(Time);
