import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'login_sign_in',
    defaultMessage: 'Please sign in',
  },
  loginMicrosoft: {
    id: 'login_login_microsoft',
    defaultMessage: 'Login with Microsoft',
  },
  errorMessageLoginFallback: {
    id: 'login_error_message_login_fallback',
    defaultMessage: 'Incorrect email or password. Please try again.',
  },
  errorMessageFallback: {
    id: 'login_error_message_fallback',
    defaultMessage: 'An error occurred while handling your request',
  },
  username: {
    id: 'login_form_username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'login_form_password',
    defaultMessage: 'Password',
  },
  passwordConfirmation: {
    id: 'login_form_password_password_confirmation',
    defaultMessage: 'Password confirmation',
  },
  email: {
    id: 'login_form_email',
    defaultMessage: 'Email',
  },
  loginSubmit: {
    id: 'login_form_sign_in',
    defaultMessage: 'Sign in',
  },
  requestSubmit: {
    id: 'login_form_request_reset',
    defaultMessage: 'Request reset',
  },
  resetPasswordSubmit: {
    id: 'login_form_reset_password',
    defaultMessage: 'Reset password',
  },
  forgotPassword: {
    id: 'login_forgot_password',
    defaultMessage: 'Forgot your password?',
  },
  recoverAccount: {
    id: 'login_recover_account',
    defaultMessage: 'Recover your account?',
  },
  requestAccountUnlock: {
    id: 'login_request_account_unlock',
    defaultMessage: 'Unlock your account?',
  },
  accountUnlock: {
    id: 'login_account_unlock',
    defaultMessage: 'Account unlock',
  },
  returnLogin: {
    id: 'login_return_login',
    defaultMessage: 'Return to login',
  },
  recoverSuccessMessage: {
    id: 'login_recover_success_messsage',
    defaultMessage: 'In several minutes, you will receive an email with a personal link.',
  },
  resetPassword: {
    id: 'login_reset_password',
    defaultMessage: 'Set new password',
  },
  resetSuccessMessage: {
    id: 'login_reset_success_message',
    defaultMessage: 'The password has been reset successfully - please log in with the new password.',
  },
  unlockSuccessMessage: {
    id: 'login_unlock_success_message',
    defaultMessage: 'The account has been unlocked successfully - please try to log in again.',
  },
  passwordInfo: {
    id: 'login_rest_password_info',
    defaultMessage: 'Please do not use your username or "nephroflow" in the password.',
  },
  acceptationEnvironmentDisclaimer: {
    id: 'acceptation_environment_disclaimer',
    defaultMessage: 'This is an acceptation environment.',
  },
});
