import { useCallback, useState } from 'react';

import uniqueId from 'utils/uniqueId';

export default function useUniqueId(prefix: string) {
  const [id, setId] = useState(() => uniqueId(prefix));

  const refreshId = useCallback(() => {
    setId(uniqueId(prefix));
  }, [prefix]);

  return [id, refreshId] as const;
}
