import { ReactNode } from 'react';
import cx from 'classnames';

import omitNullable from 'utils/omitNullable';

export interface DataListValue {
  label: ReactNode;
  value: ReactNode;

  hasTable?: boolean;
  wrap?: boolean;
  actionButton?: ReactNode;
}

export interface DateListProps {
  values: (DataListValue | null | undefined)[];
  long?: boolean;
}

export default function DataList({ values, long = false }: DateListProps) {
  return <dl className={cx('description-horizontal', { 'dt-long': long })}>{omitNullable(values).map(renderValue)}</dl>;
}

function renderValue({ label, value, hasTable, wrap, actionButton }: DataListValue, index: number) {
  const className = cx({
    wrap,
    'has-table': hasTable,
    'action-button': !!actionButton,
  });

  const dtElement = (
    <dt className={className} key={`label-${index}`}>
      {label}
    </dt>
  );

  const ddElement = (
    <dd className={className} key={`value-${index}`}>
      {value}
    </dd>
  );

  return [dtElement, actionButton, ddElement].filter(Boolean);
}
