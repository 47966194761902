/* eslint-disable local/sort-imports */

import 'core-js/stable';
import 'react-toastify/dist/ReactToastify.css';
import './styles/application.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import Root from 'views';

import { initSentry } from 'utils/sentry';
import loadFonts from 'assets/fonts/fontLoader';

loadFonts();
initSentry();

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root')
);
