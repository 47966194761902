import { ValidationT } from '../schema';
import { contentTypeValidator } from './contentType';
import { formatValidator } from './format';
import { maxLengthValidator } from './maxLength';
import { maxValueValidator } from './maxValue';
import { minLengthValidator } from './minLength';
import { minValueValidator } from './minValue';
import { requiredValidator } from './required';

export { required } from './required';
export { minValue } from './minValue';
export { maxValue } from './maxValue';
export { minLength } from './minLength';
export { maxLength } from './maxLength';
export { format } from './format';
export {
  reportTemplateContentTypes,
  attachmentContentTypes,
  documentContentTypes,
  imageContentTypes,
  contentTypes,
} from './contentType';

export function getValidator(config: ValidationT): Validator {
  const validatorConfigCreator = VALIDATOR_CONFIG_CREATORS[config.type];
  const validatorConfig = validatorConfigCreator(config as any);

  return createValidator(validatorConfig);
}

export type Validator = (value: unknown) => ValidationResult;

type ValidatorConfigCreators = MapValidationConfig<ValidationT, 'type'>;

const VALIDATOR_CONFIG_CREATORS: ValidatorConfigCreators = {
  required: requiredValidator,
  min_value: minValueValidator,
  max_value: maxValueValidator,
  min_length: minLengthValidator,
  max_length: maxLengthValidator,
  format: formatValidator,
  content_type: contentTypeValidator,
};

const validateType = (allowedTypes: string[] | undefined, value: unknown) => {
  if (allowedTypes === undefined || allowedTypes === null) return true;
  if (value === undefined || value === null) return true;

  const checkersByType = {
    string: (val: unknown) => typeof val === 'string',
    array: (val: unknown) => Array.isArray(val),
    number: (val: unknown) => typeof val === 'number',
    object: (val: unknown) => typeof val === 'object',
  };

  return allowedTypes.some((type) => checkersByType[type](value) || false);
};

interface ValidationConfig {
  validator: (value: any) => boolean;
  errorMessage: string;
  allowedTypes?: string[];
}

export type ValidationResult = { valid: true; error: undefined } | { valid: false; error: string };

function createValidator({ allowedTypes, validator, errorMessage }: ValidationConfig) {
  return (value: unknown): ValidationResult => {
    if (import.meta.env.DEV && !validateType(allowedTypes, value)) {
      // eslint-disable-next-line no-console
      console.error('Invalid type');
    }

    return validator(value) ? { valid: true, error: undefined } : { valid: false, error: errorMessage };
  };
}

// Modified from https://stackoverflow.com/questions/50125893/typescript-derive-map-from-discriminated-union

type DiscriminateUnionValidationConfig<T, K extends keyof T, V extends T[K]> = T extends Record<K, V>
  ? (validation: T) => ValidationConfig
  : never;

type MapValidationConfig<T extends Record<K, string>, K extends keyof T> = {
  [Value in T[K]]: DiscriminateUnionValidationConfig<T, K, Value>;
};
