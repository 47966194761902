import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { fetchCardTypeOptions } from 'api/endpoints/devices';

import { sexOptions } from 'translatedResources/sex';

import { ShouldSubmit } from 'components/ActionBar';
import Form, { DateTimeField, ListField, TextField } from 'components/FormV3';
import Section from 'components/Section';

import { PatientCard } from '../fetchers';
import t from '../translations';

interface Props {
  card?: PatientCard;
  centers: OptionT[];
  submit: (values: { name: string; category: string }) => void;
  shouldSubmit?: ShouldSubmit;
}

// As far as the SX patient card is concern; sex is binary.
const SUPPORTED_SEXES = ['M', 'F'];

export default function PatientCardForm({ card, centers, submit, shouldSubmit }: Props) {
  const intl = useIntl();

  const binarySexOptions = sexOptions(intl).filter((option) => SUPPORTED_SEXES.includes(option.value));

  const { data: modelOptions = [] } = useQuery(['devices/configuredDialysisModels'], () => fetchCardTypeOptions());

  return (
    <Form onSubmit={submit} shouldSubmit={shouldSubmit} initialValues={(card || {}) as any}>
      <Section>
        <ListField name="centerIds" multiple label={t.center} options={centers} validation={{ required: true }} />

        <ListField name="machineId" label={t.type} options={modelOptions} validation={{ required: true }} />

        <TextField name="identifier" label={t.identifier} validation={{ required: true }} />

        <TextField name="externalIdentifier" label={t.externalIdentifier} />

        <TextField name="firstName" label={t.firstName} validation={{ required: true }} />

        <TextField name="lastName" label={t.lastName} validation={{ required: true }} />

        <ListField name="sex" label={t.sex} options={binarySexOptions} validation={{ required: true }} />

        <DateTimeField name="birthdate" ui="date" label={t.birthdate} validation={{ required: true }} />
      </Section>
    </Form>
  );
}
