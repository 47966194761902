import t from '../errorTranslations';
import { FormatValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export function formatValidator(validation: FormatValidationT) {
  const regex = new RegExp(validation.args[0]);

  return {
    allowedTypes: ['string', 'number'],
    validator: (val: string | number) => val === undefined || (String(val).match(regex) || []).length > 0,
    errorMessage: formatErrorMessage(validation),
  };
}

export function format(regex: FormatValidationT['args'][number], intlOrErrMsg: IntlOrErrMsg): FormatValidationT {
  return {
    type: 'format',
    args: [regex],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl, args }: FormatValidationT) {
  const regex = String(args[0]);

  if (errorMessage) return errorMessage.replace('#{regex}', regex);
  if (intl) return intl.formatMessage(t.format, { regex });
  return '';
}
