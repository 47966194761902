import { useState } from 'react';
import { AnyAction, Dispatch } from 'redux';

import {
  forbidden,
  methodNotAllowed,
  networkError,
  notFound,
  payloadTooLarge,
  serverError,
} from 'store/modules/network';

import { useAppDispatch } from 'hooks/redux';

interface ErrorArgs {
  failureType: string;
  error: Record<string, any>;
  requestDescription?: NetworkRequestDescription;
}

export default function storeErrorHandler(dispatchter: Dispatch<AnyAction>) {
  return ({ error, requestDescription, failureType }: ErrorArgs) => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const { response } = error;

    // The server encountered an unexpected condition that
    // prevented it from fulfilling the request.
    // https://httpstatuses.com/500
    if (response?.status >= 500) {
      dispatchter({ type: failureType, payload: null, error: true });
      return dispatchter(serverError({ failureType }));
    }

    // The server is refusing to process a request because,
    // the request payload is larger than the server is willing or able to process.
    // https://httpstatuses.com/413
    if (response?.status === 413) {
      dispatchter({ type: failureType, payload: null, error: true });
      return dispatchter(payloadTooLarge({ failureType }));
    }

    // The method received in the request-line is known by the origin server,
    // but not supported by the target resource.
    // https://httpstatuses.com/405
    if (response?.status === 405) {
      dispatchter({ type: failureType, payload: null, error: true });
      return dispatchter(methodNotAllowed({ failureType }));
    }

    // The server understood the request but did not find the resource.
    // https://httpstatuses.com/404
    if (response?.status === 404) {
      dispatchter({ type: failureType, payload: null, error: true });
      return dispatchter(notFound({ failureType }));
    }

    // The server understood the request but refuses to authorize it.
    // https://httpstatuses.com/403
    if (response?.status === 403) {
      dispatchter({ type: failureType, payload: null, error: true });
      return dispatchter(forbidden({ failureType }));
    }

    return dispatchter(networkError(failureType, requestDescription, response?.data));
  };
}

export function useStoreErrorHandler() {
  const dispatch = useAppDispatch();
  const [handler] = useState(() => storeErrorHandler(dispatch));

  return handler;
}
