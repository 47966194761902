import { useCallback } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

import { SECOND_IN_MILLIS } from 'utils/date';

import Flash from 'components/Flash';

import useSyncedRef from './useSyncedRef';

interface ToastOptions {
  type?: AppColor;
  duration?: number | false;
}

const DEFAULT_DURATION = 4 * SECOND_IN_MILLIS;

export default function useToast(options: ToastOptions = {}) {
  const instanceOptionsRef = useSyncedRef(options);

  return useCallback(
    (content: string | string[], options: ToastOptions = {}) => {
      const instanceOptions = instanceOptionsRef.current;
      const type = getOption(instanceOptions, options, 'type', 'info');
      const duration = getOption(instanceOptions, options, 'duration');

      const flashElement = (
        <Flash type={type} close={false}>
          {content}
        </Flash>
      );

      toast(flashElement, {
        progressClassName: cx('toast-progress', `-type-${type}`),
        className: cx('toast', `-type-${type}`),
        position: 'bottom-right',
        autoClose: duration === false ? false : duration || DEFAULT_DURATION,
        closeButton: duration === false,
        draggable: false,
        closeOnClick: duration !== false,
      });
    },
    [instanceOptionsRef]
  );
}

function getOption<K extends keyof ToastOptions>(
  instanceOptions: ToastOptions,
  localOptions: ToastOptions,
  prop: K,
  fallback?: any
): ToastOptions[K] {
  if (prop in localOptions) return localOptions[prop];
  if (prop in instanceOptions) return instanceOptions[prop];
  return fallback;
}
