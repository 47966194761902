import { fromJS } from 'immutable';

import {
  CREATE_CONDITION,
  DELETE_CONDITION,
  GET_CONDITIONS,
  SEARCH_CONDITIONS,
  UPDATE_CONDITION,
} from 'store/modules/entities/actions/conditions';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

const CONDS = ['conditions'];
const CONDS_BY_ID = [...CONDS, 'byId'];
const CONDS_BY_RESULT = [...CONDS, 'byResult'];
const ENTITY_CODES = ['entityCodes'];
const ENTITY_CODES_BY_ID = [...ENTITY_CODES, 'byId'];

export default function conditionsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_CONDITIONS.SUCCESS: {
      const { response } = action.payload;
      const { conditions = {}, codes = {} } = response.entities;
      const { data } = response.result;

      return state
        .mergeIn(CONDS_BY_ID, fromJS(conditions))
        .mergeIn(ENTITY_CODES_BY_ID, fromJS(codes))
        .setIn(CONDS_BY_RESULT, fromJS(data || []));
    }

    case DELETE_CONDITION.SUCCESS: {
      const { conditionId } = action.payload;

      return state.deleteIn([...CONDS_BY_ID, conditionId]);
    }

    case CREATE_CONDITION.SUCCESS: {
      const { response } = action.payload;
      const { conditions = {}, codes = {} } = response.entities;
      const codeId = response.result['conditions/condition'];

      const result = state.getIn(['conditions', 'byResult']).concat(fromJS([codeId]));

      return state
        .mergeIn(CONDS_BY_ID, fromJS(conditions))
        .mergeIn(ENTITY_CODES_BY_ID, fromJS(codes))
        .setIn(CONDS_BY_RESULT, result);
    }

    case UPDATE_CONDITION.SUCCESS: {
      const { response } = action.payload;
      const { conditions = {}, codes = {} } = response.entities;

      return state.mergeIn(CONDS_BY_ID, fromJS(conditions)).mergeIn(ENTITY_CODES_BY_ID, fromJS(codes));
    }

    case SEARCH_CONDITIONS.SUCCESS: {
      const { response } = action.payload;
      const { conditions = {}, codes = {} } = response.entities;

      return state.mergeIn(CONDS_BY_ID, fromJS(conditions)).mergeIn(ENTITY_CODES_BY_ID, fromJS(codes));
    }

    default: {
      return state;
    }
  }
}

export const conditionsSelector = (state: any): ConditionT[] | undefined => {
  const isFetching = !!state.network.GET_CONDITIONS;

  if (isFetching) return;

  const { byId: conditionsById, byResult: conditionsByResult } = state.entities.getIn(CONDS).toJS();
  const { byId: codesById } = state.entities.getIn(ENTITY_CODES).toJS();

  return conditionsByResult
    .map((conditionId) => conditionsById[conditionId])
    .filter((condition) => !!condition)
    .map((condition: Omit<ConditionT, 'codes'> & { codes: string[] }) => ({
      ...condition,
      codes: condition.codes?.map((codeId) => codesById[codeId]).filter((code) => !!code) || [],
    }))
    .sort(sortInline);
};

export const conditionOptionsSelector = (state: any, conditionType?: ConditionTypeT): OptionT[] => {
  const conditions = deindex<ConditionT>(state.entities.getIn(['conditions', 'byId']).toJS());

  if (!conditions) return [];

  const mapper = (condition) => ({
    label: condition.name,
    value: condition.id,
  });

  if (!conditionType) {
    return conditions.map(mapper);
  }

  return conditions.filter((condition) => condition.conditionType === conditionType).map(mapper);
};
