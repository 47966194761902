import DayPicker, { DayPickerProps, LocaleUtils } from 'react-day-picker';
import { ClassNames } from 'react-day-picker/types/ClassNames';
import { useIntl } from 'react-intl';

import { months } from 'translatedResources/Date/Months';
import { weekDaysLong, weekDaysShort } from 'translatedResources/Date/WeekDays';

import { useWeekDayOffset } from 'hooks/useConfig';

const currentYear = new Date().getFullYear();
export const fromMonth = new Date(currentYear - 120, 0, 1, 0, 0);
export const toMonth = new Date(currentYear + 100, 11, 31, 23, 59);

export default function DatePicker(props: DayPickerProps) {
  const localeUtils = useLocaleUtils();

  return (
    <DayPicker
      classNames={datePickerClassNames}
      localeUtils={localeUtils}
      fromMonth={fromMonth}
      toMonth={toMonth}
      renderDay={renderDay}
      {...props}
    />
  );
}

function renderDay(date: Date) {
  return <div className={getClassName('day__inner')}>{date.getDate()}</div>;
}

export function useLocaleUtils(): LocaleUtils {
  const intl = useIntl();
  const weekDayOffset = useWeekDayOffset();

  return {
    ...LocaleUtils,
    formatDay: (date: Date) =>
      intl.formatDate(date, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    formatWeekdayShort: (index: number) => weekDaysShort(intl)[index],
    formatWeekdayLong: (index: number) => weekDaysLong(intl)[index],
    getFirstDayOfWeek: () => weekDayOffset,
    getMonths: () => months(intl) as ReturnType<LocaleUtils['getMonths']>,
    formatMonthTitle: (date: Date) => `${months(intl)[date.getMonth()]} ${date.getFullYear()}`,
  };
}

const BASE_CLASS_NAME = 'datepicker';

export function getClassName(string: string) {
  return `${BASE_CLASS_NAME}__${string}`;
}

export const datePickerClassNames: ClassNames = {
  container: BASE_CLASS_NAME,
  wrapper: getClassName('wrapper'),
  interactionDisabled: `-interaction-disabled`,
  months: getClassName('months'),
  month: getClassName('month'),

  navBar: getClassName('navbar'),
  navButtonPrev: `${getClassName('nav-button')} -prev`,
  navButtonNext: `${getClassName('nav-button')} -next`,
  navButtonInteractionDisabled: `-interaction-disabled`,

  caption: getClassName('caption'),
  weekdays: getClassName('weekdays'),
  weekdaysRow: getClassName('weekdays__row'),
  weekday: getClassName('weekday'),
  body: getClassName('body'),
  week: getClassName('week'),
  weekNumber: getClassName('week-number'),
  day: getClassName('day'),
  footer: getClassName('footer'),
  todayButton: getClassName('today-button'),

  today: '-is-today',
  selected: '-is-selected',
  disabled: '-is-disabled',
  outside: '-is-outside',
};
