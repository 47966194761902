import { ChangeEvent as ReactChangeEvent, useCallback, useEffect, useState } from 'react';

import { BaseInputProps } from '../types';

export default function useBooleanInput({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  name,
  disabled = false,
  autoFocus = false,
}: BaseInputProps<boolean, boolean>) {
  const isControlled = !!externalOnChange;

  const [checked, setChecked] = useState(isControlled ? value ?? false : defaultValue ?? false);

  const onChange = useCallback(
    (event: ReactChangeEvent<HTMLInputElement>) => {
      const input = event.target;

      setChecked(input.checked);
      externalOnChange?.(input.checked);
    },
    [externalOnChange]
  );

  const onBlur = useCallback(() => {
    externalOnBlur?.();
  }, [externalOnBlur]);

  useEffect(() => {
    if (isControlled) setChecked(value ?? false);
  }, [value, isControlled]);

  return {
    id: name,
    name,
    checked,
    onChange,
    onBlur,
    disabled,
    autoFocus,
  };
}
