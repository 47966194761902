import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

import { BaseInputProps } from './types';

export interface RadioOption<V extends string | number | boolean = string> {
  label: string;
  value: V;
}

export interface RadioGroupInputProps<
  V extends string | number | boolean = string,
  O extends RadioOption<V> = RadioOption<V>
> extends BaseInputProps<V> {
  options: O[];
  orientation?: 'row' | 'column';
}

export default function RadioGroupInput<
  V extends string | number | boolean = string,
  O extends RadioOption<V> = RadioOption<V>
>({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  name,
  disabled = false,
  autoFocus = false,
  orientation = 'row',
  options,
}: RadioGroupInputProps<V, O>) {
  const isControlled = !!externalOnChange;

  const [selected, setSelected] = useState<string | number | boolean | null>(() => {
    const val = isControlled ? value : defaultValue;
    return val ?? null;
  });

  const nonExistingOption =
    selected !== null && !options.find(({ value }) => value === selected)
      ? ({ label: selected, value: selected } as O)
      : undefined;

  const shouldAutoFocus = useRef(!disabled && autoFocus);

  useEffect(() => {
    if (isControlled) {
      setSelected(value ?? null);
    }
  }, [isControlled, value]);

  const onChange = (value: V) => {
    setSelected(value);
    externalOnChange?.(value);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const renderOption = (option: O, index: number) => (
    <label key={String(option.value)} className="radiogroup__item">
      <input
        className={cx('radiogroup__item__input', {
          '-is-checked': option.value === selected,
          '-is-disabled': disabled,
        })}
        type="radio"
        name={name}
        id={String(option.value)}
        value={String(option.value)}
        checked={option.value === selected}
        disabled={disabled}
        autoFocus={index === 0 && shouldAutoFocus.current}
        onChange={() => onChange(option.value)}
        onBlur={onBlur}
      />
      <Icon className="radiogroup__item__button">
        {option.value === selected ? 'radio_button_checked' : 'radio_button_unchecked'}
      </Icon>
      <span className="radiogroup__item__label">{option.label}</span>
    </label>
  );

  return (
    <div className="base-input -type-radiogroup">
      <div className={`radiogroup -orientation-${orientation}`}>
        {options.map(renderOption)}
        {nonExistingOption ? renderOption(nonExistingOption, options.length) : null}
      </div>
    </div>
  );
}
