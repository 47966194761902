import { CSSProperties, ReactNode, RefObject, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { arrow, autoUpdate, ComputePositionConfig, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import useClickOutside from 'hooks/useClickOutside';
import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Portal from 'components/Portal';

const ANIMATION_DURATION = 200;

const transitionClassNames = {
  enter: '-trans-enter',
  enterDone: '-trans-enter-done',
  enterActive: '-trans-enter-active',
  exit: '-trans-exit',
  exitActive: '-trans-exit-active',
};

export interface PopoverProps {
  isOpen: boolean;
  content: ReactNode;
  children: ReactNode | ((isOpen: boolean) => ReactNode);
  placement?: ComputePositionConfig['placement'];
  floatingOffset?: number;
  persistContent?: boolean;
  floatingClassname?: string;
  floatingContentClassName?: string;
  floatingContentProps?: Omit<JSX.IntrinsicElements['div'], 'children' | 'classname'>;
  actuatorClassName?: string;
  actuatorProps?: Omit<JSX.IntrinsicElements['div'], 'children' | 'classname'>;
  onOutsideClick?: (event: MouseEvent) => void;
}

export default function Popover({
  isOpen,
  content,
  children,
  placement: preferredPlacement = 'right',
  floatingOffset = 10,
  persistContent,
  floatingClassname,
  floatingContentClassName,
  floatingContentProps,
  actuatorClassName,
  actuatorProps,
  onOutsideClick,
}: PopoverProps) {
  const [entered, setEntered] = useState(false);

  const arrowRef = useRef<HTMLDivElement>(null);
  const { x, y, strategy, refs, placement, reference, floating, middlewareData } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: preferredPlacement,
    middleware: [offset(floatingOffset), flip(), shift(), arrow({ element: arrowRef })],
  });

  const arrowX = middlewareData.arrow?.x;
  const arrowY = middlewareData.arrow?.y;

  const [isVisible, setIsVisible] = useState(false);
  const [floatingElement, setFloatingElement] = useState<HTMLDivElement | null>(null);

  const shouldRender = useDelayedAnimation(isOpen, ANIMATION_DURATION);
  const shouldTransition = isOpen && !!floatingElement;

  useLayoutEffect(() => {
    if (shouldRender && !!floatingElement && !isVisible) {
      requestAnimationFrame(() => setIsVisible(true));
    }

    if (!shouldRender && isVisible) {
      setIsVisible(false);
    }
  }, [shouldRender, floatingElement, isVisible]);

  useClickOutside(floatingElement, (event) => {
    if (entered) onOutsideClick?.(event);
  });

  const floatingRef = useMemo(() => {
    return (element: HTMLDivElement | null) => {
      floating(element);
      setFloatingElement(element);
    };
  }, [floating]);

  return (
    <>
      <div {...actuatorProps} className={cx('popover-actuator', actuatorClassName)} ref={reference}>
        {typeof children === 'function' ? children(shouldRender) : children}
      </div>

      <Portal>
        <CSSTransition
          in={shouldTransition}
          timeout={ANIMATION_DURATION}
          classNames={transitionClassNames}
          nodeRef={refs.floating}
          onEntered={() => setEntered(true)}
          onExit={() => setEntered(false)}
        >
          {shouldRender || persistContent ? (
            <div
              ref={floatingRef}
              style={{
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              }}
              data-floating-placement={placement}
              className={cx('popover-floating', floatingClassname, { '-is-visible': true })}
            >
              <div {...floatingContentProps} className={cx('popover-floating__content', floatingContentClassName)}>
                <div className="popover-floating__inner-content">{content}</div>
                <Arrow
                  innerRef={arrowRef}
                  style={{
                    top: arrowY ?? '',
                    left: arrowX ?? '',
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </CSSTransition>
      </Portal>
    </>
  );
}

function Arrow({ innerRef, style }: { innerRef: RefObject<HTMLDivElement>; style: CSSProperties }) {
  return (
    <div ref={innerRef} className="popover-floating__arrow" data-floating-arrow style={style}>
      <svg className="popover-floating__arrow__svg" width="7" height="14">
        <polygon className="popover-floating__arrow__polygon" points="7,0 0,7, 7,14" />
      </svg>
    </div>
  );
}
