import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { retrieveToken } from 'auth/authLocalStorage';

import { useStoreErrorHandler } from 'api/storeErrorHandler';

import useIsMounted from 'hooks/useIsMounted';
import useSyncedRef from 'hooks/useSyncedRef';
import { ShouldTrigger, useTrigger } from 'hooks/useTrigger';

const CREATE_AUTHENTICATED_ASSET_FAILURE = 'CREATE_AUTHENTICATED_ASSET_FAILURE';

interface Options {
  shouldFetch?: ShouldTrigger;
  reportErrors?: boolean;
  onSuccess?: (blob: Blob) => void;
  onError?: (err: unknown) => void;
}

export default function useAuthenticatedAsset(url: string | null | undefined, options: Options = {}) {
  const isMounted = useIsMounted();
  const storeErrorHandler = useStoreErrorHandler();

  const { shouldFetch, reportErrors, onSuccess, onError } = options;

  const fetchedRef = useRef(false);
  const controllerRef = useRef<AbortController>();
  const hasShouldFetchOptionRef = useRef('shouldFetch' in options);

  const [enabled, setEnabled] = useState(!hasShouldFetchOptionRef.current);
  const [blob, setBlob] = useState<Blob>();
  const urlObject = useObjectUrl(blob);

  const blobRef = useSyncedRef(blob);
  const reportErrorsRef = useSyncedRef(reportErrors);
  const onSuccessRef = useSyncedRef(onSuccess);
  const onErrorRef = useSyncedRef(onError);

  useTrigger(shouldFetch, () => setEnabled(true));

  // If the `url` changes, make sure we re-fetch the blob later on.
  // If `shouldFetch` is initially not given, set `enabled` to `true`
  // so we force a refetch
  useEffect(() => {
    fetchedRef.current = false;
    if (!hasShouldFetchOptionRef.current) setEnabled(true);
  }, [url]);

  // If `enabled` changes to `true` and we have a blob, call `onSuccess`.
  // (We don't refetch the blob)
  useEffect(() => {
    if (enabled && fetchedRef.current && blobRef.current) {
      onSuccessRef.current?.(blobRef.current);
      setEnabled(false);
    }
  }, [enabled, blobRef, onSuccessRef]);

  // If `enabled` changes to `true` and we have not fetched the blob, fetch it.
  useEffect(() => {
    if (enabled && url && !fetchedRef.current) {
      controllerRef.current?.abort();
      controllerRef.current = new AbortController();

      fetchedRef.current = true;

      fetchBlob(url, controllerRef.current.signal)
        .then((blob) => {
          if (isMounted()) {
            setBlob(blob);
            setEnabled(false);
            onSuccessRef.current?.(blob);
          }
        })
        .catch((error) => {
          fetchedRef.current = false;

          if (!isCancelError(error)) {
            onErrorRef.current?.(error);

            if (reportErrorsRef.current) {
              storeErrorHandler({ error, failureType: CREATE_AUTHENTICATED_ASSET_FAILURE });
            }
          }
        })
        .finally(() => {
          controllerRef.current = undefined;
        });
    }
  }, [enabled, url, isMounted, storeErrorHandler, reportErrorsRef, onSuccessRef, onErrorRef]);

  // Register a cleanup function that will cancel the request.
  useEffect(() => {
    return () => {
      controllerRef.current?.abort();
      controllerRef.current = undefined;
    };
  }, []);

  return { blob, urlObject, isFetching: enabled };
}

function useObjectUrl(blob: Blob | undefined) {
  const [url, setUrl] = useState<string>();
  const urlRef = useSyncedRef(url);

  useEffect(() => {
    if (blob && !urlRef.current) {
      setUrl(URL.createObjectURL(blob));
    } else {
      setUrl(undefined);
    }

    return () => {
      if (urlRef.current) {
        URL.revokeObjectURL(urlRef.current);
        urlRef.current = undefined;
      }
    };
  }, [blob, urlRef]);

  return url;
}

const ACTIVE_STORAGE_REDIRECT = 'active_storage/blobs/redirect';
const AZURE_BLOB_DOMAIN = 'blob.core.windows.net';

async function fetchBlob(url: string, signal: AbortSignal) {
  const headers = {};

  // Don't send our Authorization headers to Azure Blob storage
  if (!(url.includes(ACTIVE_STORAGE_REDIRECT) || url.includes(AZURE_BLOB_DOMAIN))) {
    headers['Authorization'] = `Bearer ${retrieveToken().access}`;
  }

  const response = await axios.get(url, { headers, signal, responseType: 'blob' });

  return await response.data;
}

function isCancelError(error: any) {
  return !!error && axios.isCancel(error);
}
