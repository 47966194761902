import { ReactNode } from 'react';
import cx from 'classnames';

interface Props {
  children: ReactNode;
  contentClassName?: string;
  textCentered?: boolean;
}

export default function Body({ children, contentClassName, textCentered = true }: Props) {
  return (
    <div className="modal-content-container">
      <div
        className={cx('modal-content', contentClassName, {
          'is-centered': textCentered,
        })}
      >
        {children}
      </div>
    </div>
  );
}
