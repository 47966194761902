import { Fragment, MouseEvent as ReactMouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import useIsMounted from 'hooks/useIsMounted';

import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

import { BaseInputProps } from './types';

interface PreviewerContext {
  file: File;
  hasMultipleSelected: boolean;
}

export interface FileInputProps<Multi extends boolean = false>
  extends BaseInputProps<Multi extends true ? File[] : File> {
  previewer?: (context: PreviewerContext) => ReactNode;
  multiple?: Multi;
}

export default function FileInput<Multi extends boolean = false>({
  name,
  value,
  defaultValue,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  previewer = defaultFilePreviewer,
  multiple = false as Multi,
}: FileInputProps<Multi>) {
  const { formatMessage } = useIntl();

  const isControlled = !!externalOnChange;

  const inputEl = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[] | null>(
    isControlled ? asArray(value) || null : asArray(defaultValue) || null
  );

  const hasMultipleSelected = (files?.length || 0) > 1;

  const openFilePicker = (event: ReactMouseEvent) => {
    event.stopPropagation();

    inputEl.current?.click();
  };

  const unsetFile = (event: ReactMouseEvent) => {
    event.stopPropagation();

    setFiles(null);
    externalOnChange?.(null);
  };

  const onChange = () => {
    const files = [...(inputEl.current?.files || [])];
    setFiles(files);

    if (multiple) {
      if (files.length) externalOnChange?.(files as any);
    } else {
      if (files[0]) externalOnChange?.(files[0] as any);
    }
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  useEffect(() => {
    if (isControlled) {
      setFiles(asArray(value) || null);
    }
  }, [value, isControlled]);

  return (
    <div className="base-input -type-file" onClick={openFilePicker}>
      <div className="filepicker">
        <input
          id={name}
          name={name}
          ref={inputEl}
          value=""
          type="file"
          className="filepicker__input"
          onChange={onChange}
          onBlur={onBlur}
          multiple={multiple}
        />

        <div
          className={cx('filepicker__preview', {
            '-is-multi': hasMultipleSelected,
          })}
        >
          {files && files.length ? (
            files.map((file, index) => <Fragment key={index}>{previewer({ file, hasMultipleSelected })}</Fragment>)
          ) : (
            <span className="filepicker__preview__placeholder">
              {formatMessage(multiple ? t.selectMultiple : t.selectOne)}
            </span>
          )}
        </div>

        <div
          className={cx('filepicker__actions', {
            '-has-files': files?.length,
            '-no-files': !files?.length,
          })}
        >
          <ActionButton
            icon="cloud_upload"
            title={multiple ? t.selectMultiple : t.selectOne}
            onClick={openFilePicker}
          />

          {files?.length ? (
            <ActionButton
              title={multiple ? t.deselectMultiple : t.deselectOne}
              remove
              icon="delete"
              onClick={unsetFile}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function defaultFilePreviewer({ file, hasMultipleSelected }: PreviewerContext) {
  return <Previewer file={file} imageType={hasMultipleSelected ? 'avatar' : 'image'} />;
}

export function avatarFilePreviewer({ file }: PreviewerContext) {
  return <Previewer file={file} imageType="avatar" />;
}

interface PreviewerProps {
  file: File;
  imageType: 'image' | 'avatar';
}

function Previewer({ file, imageType }: PreviewerProps) {
  const { isLoading, base64 } = useImageReader(file);

  if (isLoading) {
    return (
      <div className="filepicker__preview__file">
        <Loading type="small" />
      </div>
    );
  }

  if (base64) {
    return (
      <div className={cx('filepicker__preview__file', `-type-${imageType}`)}>
        {imageType === 'avatar' ? (
          <div className="avatar with-margin-small" style={{ backgroundImage: `url(${base64})` }} />
        ) : (
          <img className="filepicker__preview__img" src={base64} alt="file" />
        )}
        <span className="filepicker__preview__name">{file.name}</span>
      </div>
    );
  }

  return (
    <div className="filepicker__preview__file -type-generic">
      <Icon className="filepicker__preview__icon">description</Icon>
      <span className="filepicker__preview__name">{file.name}</span>
    </div>
  );
}

export function useImageReader(file: File | null | undefined) {
  const isMounted = useIsMounted();
  const [base64, setBase64] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fileIsValid(file)) {
      setIsLoading(true);

      (async () => {
        const data = await readFile(file);

        if (isMounted()) {
          setBase64(data);
          setIsLoading(false);
        }
      })();
    } else {
      setBase64(null);
    }
  }, [isMounted, file]);

  return { isLoading, base64 };
}

function asArray(value: File | File[] | null | undefined) {
  return value && !Array.isArray(value) ? [value] : value;
}

function fileIsValid(file: File | null | undefined): file is File {
  const MAX_SIZE_IN_BYTES = 5_000_000;

  if (!file) return false;
  if (!file.type.includes('image')) return false;
  if (file.size > MAX_SIZE_IN_BYTES) return false;

  return true;
}

function readFile(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result?.toString() || null);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
}

const t = defineMessages({
  deselectOne: {
    id: 'file_input_deselect_file',
    defaultMessage: 'Deselect file',
  },
  deselectMultiple: {
    id: 'file_input_deselect_files',
    defaultMessage: 'Deselect files',
  },
  selectOne: {
    id: 'file_input_select_file',
    defaultMessage: 'Select a file...',
  },
  selectMultiple: {
    id: 'file_input_select_files',
    defaultMessage: 'Select files...',
  },
});
