import { Suspense, useEffect } from 'react';
import { parse } from 'qs';
import { defineMessages, useIntl } from 'react-intl';
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { LocalStorage } from 'utils/storage';

import { usePatientCardsEnabled, useRecordEnabled, useTrackEnabled, useWorkbenchEnabled } from 'hooks/useConfig';

import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Main from 'components/Main';

import RequireAuth from 'sharedComponents/RequireAuth';
import Profile from 'sharedComponents/Users/Profile';

import { adminRights, AdminRoutes, useAdminEnabled } from 'views/admin';
import Cards from 'views/patientCards';
import { RecordRoutes } from 'views/record';
import { useToolsEnabled } from 'views/record/tools/utils';
import { WorkbenchRoutes } from 'views/workbench';

import { RIGHTS } from 'rights';

import ErrorPage from '../error';
import ForbiddenPage from '../forbidden';
import NotFoundPage from '../notFound';
import ErrorModal from './ErrorModal';
import Header from './Header';
import Sidebar from './Sidebar';

const SupsenseFallback = () => (
  <Main>
    <Loading />
  </Main>
);

export default function AuthenticatedApp() {
  const { search } = useLocation();
  const { formatMessage } = useIntl();

  const cx = ({ isActive }) => 'sidebar__item' + (isActive ? ' -is-active' : '');

  useEffect(() => {
    const { integration } = parse(search, { ignoreQueryPrefix: true });

    if (integration === '1') {
      LocalStorage.set<boolean>('integration_mode', true);
    }
  }, [search]);

  const integrationModeActive = LocalStorage.get<boolean>('integration_mode') || false;

  useEffect(() => {
    if (document.body) {
      if (integrationModeActive) {
        document.body.classList.add('integration');
      } else {
        document.body.classList.remove('integration');
      }
    }
  }, [integrationModeActive]);

  const toolsEnabled = useToolsEnabled();
  const patientCardsEnabled = usePatientCardsEnabled();
  const recordEnabled = useRecordEnabled();
  const workbenchEnabled = useWorkbenchEnabled();
  const trackEnabled = useTrackEnabled();
  const adminEnabled = useAdminEnabled(adminRights);

  const redirectPath = (() => {
    if (recordEnabled) return '/record';
    if (trackEnabled) return '/record';
    if (patientCardsEnabled) return '/patient_cards';
    if (workbenchEnabled) return '/workbench';
    if (adminEnabled) return '/admin';

    return '/profile';
  })();

  return (
    <div className="wrapper">
      {!integrationModeActive ? (
        <>
          <Header />
          <Sidebar>
            <RequireAuth booleanMatch={recordEnabled || trackEnabled}>
              <li role="presentation">
                <NavLink end to="/record" title={formatMessage(t.dashboard)} className={cx}>
                  <Icon>dashboard</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={recordEnabled || trackEnabled}>
              <li role="presentation">
                <NavLink to="record/recent_sessions" title={formatMessage(t.recentSessions)} className={cx}>
                  <Icon>app:session</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={recordEnabled || trackEnabled}>
              <li role="presentation">
                <NavLink to="record/patients" title={formatMessage(t.patients)} className={cx}>
                  <Icon>people</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth rights={[RIGHTS.VIEW_PLANNING]} booleanMatch={recordEnabled}>
              <li role="presentation">
                <NavLink to="record/planning" title={formatMessage(t.planning)} className={cx}>
                  <Icon>event</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth rights={[RIGHTS.VIEW_BRIEFING]} booleanMatch={recordEnabled}>
              <li role="presentation">
                <NavLink to="record/briefings" title={formatMessage(t.briefings)} className={cx}>
                  <Icon>question_answer</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={recordEnabled}>
              <li role="presentation">
                <NavLink to="record/reports" title={formatMessage(t.reports)} className={cx}>
                  <Icon>description</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth rights={[RIGHTS.MANAGE_EXPORTS]} booleanMatch={recordEnabled}>
              <li role="presentation">
                <NavLink to="record/export_center" title={formatMessage(t.exportCenter)} className={cx}>
                  <Icon>save_alt</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={toolsEnabled}>
              <li role="presentation">
                <NavLink to="record/tools" title={formatMessage(t.tools)} className={cx}>
                  <Icon>home_repair_service</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={recordEnabled}>
              <li role="presentation">
                <NavLink to="record/letters" title={formatMessage(t.letters)} className={cx}>
                  <Icon>email</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={patientCardsEnabled}>
              <li role="presentation">
                <NavLink to="patient_cards" title={formatMessage(t.cards)} className={cx}>
                  <Icon>badge</Icon>
                </NavLink>
              </li>
            </RequireAuth>

            <RequireAuth booleanMatch={workbenchEnabled}>
              <li role="presentation">
                <NavLink to="workbench" title={formatMessage(t.workbench)} className={cx}>
                  <Icon>app:workbench</Icon>
                </NavLink>
              </li>
            </RequireAuth>
          </Sidebar>
        </>
      ) : null}

      <Suspense fallback={<SupsenseFallback />}>
        <Routes>
          <Route path="/" element={<Navigate to={redirectPath} />} />
          <Route path="/login/*" element={<Navigate to={redirectPath} />} />

          {recordEnabled || trackEnabled ? <Route path="record/*" element={<RecordRoutes />} /> : null}

          {patientCardsEnabled ? <Route path="patient_cards" element={<Cards />} /> : null}

          {workbenchEnabled ? <Route path="workbench/*" element={<WorkbenchRoutes />} /> : null}

          {adminEnabled ? <Route path="admin/*" element={<AdminRoutes />} /> : null}

          <Route path="profile" element={<Profile />} />

          <Route path="error" element={<ErrorPage />} />
          <Route path="forbidden" element={<ForbiddenPage />} />
          <Route path="not_found" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/not_found" replace />} />
        </Routes>
      </Suspense>

      <ErrorModal />
    </div>
  );
}

const t = defineMessages({
  dashboard: {
    id: 'navigation_ongoing_sessions',
    defaultMessage: 'Ongoing sessions',
  },
  recentSessions: {
    id: 'navigation_recent_sessions',
    defaultMessage: 'Recent sessions',
  },
  patients: {
    id: 'navigation_patients',
    defaultMessage: 'Patients',
  },
  planning: {
    id: 'navigation_planning',
    defaultMessage: 'Planning',
  },
  briefings: {
    id: 'navigation_briefings',
    defaultMessage: 'Briefings',
  },
  reports: {
    id: 'navigation_reports',
    defaultMessage: 'Reports',
  },
  settings: {
    id: 'navigation_settings',
    defaultMessage: 'Settings',
  },
  tools: {
    id: 'navigation_tools',
    defaultMessage: 'Tools',
  },
  letters: {
    id: 'navigation_letters',
    defaultMessage: 'Letters',
  },
  exportCenter: {
    id: 'navigation_export_center',
    defaultMessage: 'Export center',
  },
  cards: {
    id: 'navigation_track_patient_cards',
    defaultMessage: 'Patient cards',
  },
  workbench: {
    id: 'navigation_workbench',
    defaultMessage: 'Workbench',
  },
  trackSessions: {
    id: 'navigation_track',
    defaultMessage: 'Track',
  },
});
