export default function parseDateTime(val: any) {
  const date = new Date(val);

  if (val && date.toString() !== 'Invalid Date') {
    return date;
  }

  if (val === null) return null;

  return undefined;
}
