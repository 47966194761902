import { WheelEvent } from 'react';

type Props = {
  id: string;
  defaultValue?: number;
  onChange: (val: number | null) => void;
  disabled?: boolean;
  autoFocus: boolean;
};

export default function Integer({ id, defaultValue, onChange, disabled = false, autoFocus }: Props) {
  return (
    <input
      id={id}
      type="number"
      step={1}
      disabled={disabled}
      defaultValue={Number.isNaN(defaultValue) ? undefined : defaultValue}
      onBlur={(event) => {
        const value = parseInt(event.target.value.trim(), 10);
        const formattedValue = Number.isNaN(value) ? null : value;

        onChange(formattedValue);
      }}
      autoFocus={autoFocus}
      onWheel={(event: WheelEvent<HTMLInputElement>) => event.currentTarget.blur()}
    />
  );
}
