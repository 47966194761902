import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';

import Flash from 'components/Flash';

import { unlockAccount } from '../fetchers';
import SigninContent from '../SigninContent';
import t from '../translations';

export default function AccountUnlock() {
  const { formatMessage } = useIntl();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);

  const unlock = useMutation(unlockAccount, {
    onSuccess: () => {
      setSuccess(true);
      setError(undefined);
    },
    onError: (error: any) => {
      setError(error?.response?.data?.errors?.[0]?.detail || formatMessage(t.errorMessageFallback));
    },
  });

  useEffect(() => {
    if (token) {
      unlock.mutate({ token });
    }
  }, [token, unlock]);

  return (
    <div className="login">
      <SigninContent title={formatMessage(t.accountUnlock)}>
        {error ? (
          <Flash close={false} type="danger">
            {error}
          </Flash>
        ) : null}

        {success ? <FormattedMessage {...t.resetSuccessMessage} tagName="p" /> : null}

        <Link style={{ marginTop: '10px' }} to="/login">
          <FormattedMessage {...t.returnLogin} />
        </Link>
      </SigninContent>
    </div>
  );
}
