import { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Select from 'react-select';

import { ClearIndicator, DropdownIndicator } from 'components/ReactSelect';
import { SelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import t from './translations';

type Props = {
  id: string;
  label?: string;
  value: string[] | null | undefined;
  defaultValue?: string[] | null | undefined;
  options: (OptionT & { disabled?: boolean })[];
  placeholder?: string;
  onChange: (val: string[] | null | undefined) => void;
  disabled?: boolean;
  autoFocus?: boolean;

  components?: any;
  [key: string]: any;

  intl: IntlShape;
};

const orderOptions = (values?: any[]) => values?.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));

class MultiList extends Component<Props> {
  static displayName = 'MultiListFormItem';
  static contextType = SelectMenuPortalTargetContext;

  componentDidMount() {
    this.resetValue();
  }

  componentDidUpdate() {
    this.resetValue();
  }

  resetValue() {
    const { value, options, onChange } = this.props;

    if (options.length && value && !value.every((val) => options.map((opt) => opt.value).includes(val))) {
      onChange(undefined);
    }
  }

  onValueChange = (options: any, { action, removedValue }: any) => {
    const { onChange } = this.props;

    if (['remove-value', 'pop-value'].includes(action) && !!removedValue?.isFixed) {
      return;
    }

    const cleanedOptions = Array.isArray(options) ? options.map((option) => option.value) : undefined;

    onChange(cleanedOptions);
  };

  render() {
    const {
      id,
      value,
      defaultValue,
      options,
      placeholder,
      onChange,
      disabled,
      autoFocus,
      intl: { formatMessage },
      components,
      ...rest
    } = this.props;

    const menuPortalTarget = this.context;

    const enhancedOptions = options.map((o) => ({
      ...o,
      isFixed: o.disabled || false,
    }));

    const val = value || defaultValue;
    const selectedOptions = val ? enhancedOptions.filter((o) => val.includes(o.value)) : undefined;

    const isClearable = !options.map(({ disabled }) => disabled).includes(true);

    const disabledStyles = {
      multiValueLabel: (base, state) => {
        return state.data.isFixed || disabled
          ? {
              ...base,
              backgroundColor: 'var(--gray)',
              cursor: 'not-allowed !important',
            }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed || disabled ? { ...base, display: 'none' } : base;
      },
    };

    return (
      <Select
        {...rest}
        isMulti
        className="Select Select__multi form-select"
        classNamePrefix="Select"
        isDisabled={disabled}
        isClearable={isClearable}
        name={id}
        options={enhancedOptions}
        placeholder={placeholder || formatMessage(t.placeholder)}
        value={orderOptions(selectedOptions) || null}
        onChange={this.onValueChange}
        openMenuOnFocus
        autoFocus={autoFocus}
        components={{
          ...components,
          ClearIndicator: ClearIndicator as any,
          DropdownIndicator: DropdownIndicator as any,
        }}
        menuPortalTarget={menuPortalTarget}
        styles={
          menuPortalTarget
            ? {
                ...disabledStyles,
                menuPortal: (base) => ({ ...base, zIndex: 4 }),
              }
            : disabledStyles
        }
      />
    );
  }
}

export default injectIntl(MultiList);
