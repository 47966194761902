import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

export default function RadioButtonGroup({
  options,
  autoFocus,
  disabled = false,
  defaultValue,
  onChange,
}: {
  options: OptionT[];
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (val: string) => void;
}) {
  const [value, setValue] = useState(defaultValue || options[0]?.value);

  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // if value is different from defaultValue that means they are out of sync
  // if defaultValue exist it means value is not in sync
  // if defaultValue doesn't exist it means the external component is not in sync.
  useEffect(() => {
    if (value !== defaultValue) {
      defaultValue ? setValue(defaultValue) : onChangeRef.current(value);
    }
  }, [defaultValue, value]);

  return (
    <div className="radio-button-form-field">
      {options.map((option, index) => (
        <button
          disabled={disabled}
          autoFocus={autoFocus && index === 0}
          key={option.value}
          type="button"
          className={cx('form-option', { 'is-active': value === option.value })}
          onClick={() => onChangeRef.current(option.value)}
        >
          <Icon>{value === option.value ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
          {option.label}
        </button>
      ))}
    </div>
  );
}
