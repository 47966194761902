import { RefObject, useEffect } from 'react';

import nodeIsOutsideParent from 'utils/nodeIsOutsideParent';

import useSyncedRef from './useSyncedRef';

export default function useClickOutside(
  parent: HTMLElement | RefObject<HTMLElement | null> | null,
  callback: (event: MouseEvent) => void
) {
  const callbackRef = useSyncedRef(callback);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const node = event.target as HTMLElement | null;

      if (document.contains(node) && nodeIsOutsideParent(unwrap(parent), node)) {
        callbackRef.current(event);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [parent, callbackRef]);
}

function unwrap<T>(value: T | RefObject<T>) {
  return value && typeof value === 'object' && 'current' in value ? value.current : value;
}
