import t from '../errorTranslations';
import { ContentTypeValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

const FILE_TYPES = {
  '.tlf': [''],
  '.png': ['image/png'],
  '.jpg': ['image/jpg', 'image/jpeg'],
  '.jpeg': ['image/jpg', 'image/jpeg'],
  '.gif': ['image/gif'],
  '.tif': ['image/tiff'],
  '.tiff': ['image/tiff'],
  '.bmp': ['image/bmp'],
  '.txt': ['text/plain'],
  '.csv': ['text/csv'],
  '.pdf': ['application/pdf'],
  '.doc': ['application/msword'],
  '.docx': ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  '.xls': ['application/vnd.ms-excel'],
  '.xlsx': ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  '.ppt': ['application/vnd.ms-powerpoint'],
  '.pptx': ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  '.odp': ['application/vnd.oasis.opendocument.presentation'],
  '.ods': ['application/vnd.oasis.opendocument.spreadsheet'],
  '.odt': ['application/vnd.oasis.opendocument.text'],
};

const REPORT_TEMPLATES = ['.tlf'];

const IMAGES = ['.png', '.jpg', '.jpeg', '.gif', '.tiff', '.bmp'];

const DOCUMENTS = ['.txt', '.csv', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.odp', '.ods', '.odt'];

const IMAGES_AND_DOCUMENTS = [...IMAGES, ...DOCUMENTS];

export function contentTypeValidator(validation: ContentTypeValidationT) {
  const types = validation.args[0] || [];

  return {
    allowedTypes: ['object'],

    validator: (file: File | null | undefined) => {
      if (file === undefined || file === null || !(file instanceof File)) {
        return true;
      }

      const regex = new RegExp(`(.+)(${types.join('|')})$`, 'i');

      const match = file.name?.match(regex) || null;

      if (match === null || match.length === 0) {
        return false;
      }

      const fileExtension = match[2];

      const allowedContentType = FILE_TYPES[fileExtension] || [];

      return allowedContentType.indexOf(file.type) > -1;
    },

    errorMessage: formatErrorMessage(validation),
  };
}

export function contentTypes(
  intlOrErrMsg: IntlOrErrMsg,
  types: string[] = IMAGES_AND_DOCUMENTS
): ContentTypeValidationT {
  return {
    type: 'content_type',
    args: [types],
    ...createBaseValidation(intlOrErrMsg),
  };
}

export function attachmentContentTypes(intlOrErrMsg: IntlOrErrMsg): ContentTypeValidationT {
  return contentTypes(intlOrErrMsg, IMAGES_AND_DOCUMENTS);
}

export function reportTemplateContentTypes(intlOrErrMsg: IntlOrErrMsg): ContentTypeValidationT {
  return contentTypes(intlOrErrMsg, REPORT_TEMPLATES);
}

export function documentContentTypes(intlOrErrMsg: IntlOrErrMsg): ContentTypeValidationT {
  return contentTypes(intlOrErrMsg, DOCUMENTS);
}

export function imageContentTypes(intlOrErrMsg: IntlOrErrMsg): ContentTypeValidationT {
  return contentTypes(intlOrErrMsg, IMAGES);
}

function formatErrorMessage({ errorMessage, intl, args }: ContentTypeValidationT) {
  const types = args[0].join(', ');

  if (errorMessage) return errorMessage.replace('#{types}', types);
  if (intl) return intl.formatMessage(t.contentType, { types });
  return '';
}
