export default function parseDate(val: any) {
  const localDate = new Date(val);

  if (val === null) return null;

  if (localDate.toString() === 'Invalid Date') {
    return undefined;
  }

  return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), 12, 0, 0);
}
