import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useRecordEnabled } from 'hooks/useConfig';

import RequireAuthRoute from 'sharedComponents/RequireAuthRoute';

import { RIGHTS } from 'rights';

import { useToolsEnabled } from './tools/utils';

const ActiveSessions = lazy(() => import('./sessionDashboards/active'));
const RecentSessions = lazy(() => import('./sessionDashboards/recent'));
const Patients = lazy(() => import('./patients'));
const Briefings = lazy(() => import('./briefings'));
const Planning = lazy(() => import('./planning'));
const Reports = lazy(() => import('./reports'));
const Letters = lazy(() => import('./letters'));
const ExportCenter = lazy(() => import('./exportCenter'));
const Settings = lazy(() => import('./settings'));
const Tools = lazy(() => import('./tools'));

export function RecordRoutes() {
  const recordEnabled = useRecordEnabled();
  const toolsEnabled = useToolsEnabled();

  return (
    <Routes>
      <Route path="/" element={<ActiveSessions />} />
      <Route path="recent_sessions" element={<RecentSessions />} />
      <Route path="patients/*" element={<Patients />} />

      {recordEnabled ? (
        <>
          <Route
            path="planning"
            element={
              <RequireAuthRoute rights={[RIGHTS.VIEW_PLANNING]}>
                <Planning />
              </RequireAuthRoute>
            }
          />
          <Route
            path="briefings/*"
            element={
              <RequireAuthRoute rights={[RIGHTS.VIEW_BRIEFING]}>
                <Briefings />
              </RequireAuthRoute>
            }
          />
          <Route path="reports" element={<Reports />} />

          <Route path="letters/*" element={<Letters />} />

          <Route
            path="export_center/*"
            element={
              <RequireAuthRoute rights={[RIGHTS.MANAGE_EXPORTS]}>
                <ExportCenter />
              </RequireAuthRoute>
            }
          />
        </>
      ) : null}

      {toolsEnabled ? <Route path="tools/*" element={<Tools />} /> : null}

      <Route
        path="settings/*"
        element={
          <RequireAuthRoute
            rights={[RIGHTS.MANAGE_APPLICATION_DATA, RIGHTS.EDIT_ADMINISTRATIVE_RECORD, RIGHTS.MANAGE_SYSTEM]}
          >
            <Settings />
          </RequireAuthRoute>
        }
      />

      <Route path="*" element={<Navigate to="/not_found" replace />} />
    </Routes>
  );
}
