import { Link } from 'react-router-dom';

import { useRecordEnabled, useTrackEnabled } from 'hooks/useConfig';

import Logo from 'sharedComponents/Logo';

import HeaderCurrentUser from './CurrentUser';
import HeaderSearch from './Search';
import HeaderSignOut from './SignOut';

export default function Header() {
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();

  return (
    <nav className="app-header">
      <div className="app-header-left">
        <Link to="/" className="app-header-logo" title="NephroFlow">
          <Logo isWhite />
        </Link>

        {recordEnabled || trackEnabled ? <HeaderSearch /> : null}
      </div>

      <div className="app-header-right">
        <HeaderCurrentUser />
        <HeaderSignOut />
      </div>
    </nav>
  );
}
