import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

export async function fetchUser(id: string) {
  const { data } = await http.get<{ user: UserT }>(`users/${id}`);

  return deserialize(data.user, {
    'createdAt': parseDateTime,
    'updatedAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}

export function deserializeUser(user: UserT) {
  return deserialize(user, {
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
    deletedAt: parseDateTime,
  });
}
