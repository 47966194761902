import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { stringify } from 'qs';

import { forgetToken, persistToken, retrieveToken } from 'auth/authLocalStorage';
import { UserToken } from 'auth/types';

import { BASE_PATH } from './url';

const refreshURL = 'auth/refresh';
const http = axios.create({ baseURL: BASE_PATH });

let refreshDefer: Deferred | undefined;

function httpInterceptor(refreshSuccess: (token: UserToken) => void, refreshFailure: () => void) {
  http.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const { _isRetry, _isAuthRefresh } = config;

      return {
        ...config,

        transformRequest(data: any, headers: any) {
          const { access, refresh } = retrieveToken();

          if (access && !_isAuthRefresh) {
            headers['Authorization'] = `Bearer ${access}`;
          }

          if (refresh && _isAuthRefresh) {
            headers['X-Refresh-Token'] = `Bearer ${refresh}`;
          }

          if (_isRetry) return data;

          if (!(data instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
            data = data ? JSON.stringify(decamelizeKeys(data)) : undefined;
          }

          return data;
        },

        paramsSerializer(params: any) {
          return stringify(decamelizeKeys(params), {
            indices: false,
            arrayFormat: 'brackets',
          });
        },
      };
    },

    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return { ...response, data: camelizeKeys(response.data) };
    },

    async (error) => {
      if (!isAxiosError(error)) {
        return Promise.reject(error);
      }

      const { _isRetry, _isAuthRefresh } = error.config;

      if (_isAuthRefresh) {
        forgetToken();
        refreshFailure();

        refreshDefer?.resolve('error');
        refreshDefer = undefined;

        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !_isRetry) {
        const retryRequest = {
          ...error.config,
          _isRetry: true,
          _isAuthRefresh: undefined,
        };

        if (!refreshDefer) {
          refreshDefer = defer();

          return http
            .post<UserToken>(refreshURL, undefined, { _isAuthRefresh: true })
            .then((response) => {
              const token = camelizeKeys(response.data) as UserToken;

              persistToken(token, true);
              refreshSuccess(token);

              refreshDefer?.resolve('success');
              refreshDefer = undefined;

              return http(retryRequest);
            })
            .catch(() => Promise.reject(error));
        } else {
          return refreshDefer.promise.then((status) =>
            status === 'error' ? Promise.reject(error) : http(retryRequest)
          );
        }
      }

      return Promise.reject(error);
    }
  );
}

type DeferredStatus = 'error' | 'success';

interface Deferred {
  promise: Promise<DeferredStatus>;
  resolve: (status: DeferredStatus) => void;
}

function defer() {
  const deferred = {};

  deferred['promise'] = new Promise((resolve) => {
    deferred['resolve'] = resolve;
  });

  return deferred as Deferred;
}

function isAxiosError(error: unknown): error is AxiosError {
  return !!error && axios.isAxiosError(error);
}

export { http, httpInterceptor };
