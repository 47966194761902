import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import checkAuth from 'utils/checkAuth';

import { useCurrentUser } from 'providers';

import { RightClaimT } from 'rights';

export interface Props {
  rights: RightClaimT[];
  children: ReactElement;
}

export default function RequireAuthRoute({ rights, children }: Props) {
  const currentUser = useCurrentUser();

  const authorized = checkAuth({ currentUser, rights });

  if (!authorized) {
    return <Navigate to="/forbidden" replace />;
  }

  return children;
}
