import { defineMessages, IntlShape } from 'react-intl';

export const sexTranslations = defineMessages({
  M: {
    id: 'sex_male',
    defaultMessage: 'Male',
  },
  F: {
    id: 'sex_female',
    defaultMessage: 'Female',
  },
  O: {
    id: 'sex_other',
    defaultMessage: 'Other',
  },
  U: {
    id: 'sex_unknown',
    defaultMessage: 'Unknown',
  },
});

export const sexOptions = (intl: IntlShape): OptionT[] =>
  Object.keys(sexTranslations).map((key) => ({
    label: intl.formatMessage(sexTranslations[key]),
    value: key,
  }));
