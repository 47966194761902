import { ReactNode, useEffect, useRef, useState } from 'react';

import useIsMounted from 'hooks/useIsMounted';

import ActionButton from 'components/ActionButton';
import Loading from 'components/Loading';

import t from './translations';

type Props = {
  id: string;
  onChange: (file?: File) => void;
  previewer?: (file?: File) => ReactNode;
};

export default function FileField({ id, onChange, previewer }: Props) {
  const inputEl = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();

  const preview = (file?: File) => {
    if (previewer) return previewer(file);

    return file ? <Preview file={file} /> : null;
  };

  const openFileSelection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (inputEl.current) inputEl.current.click();
  };

  const resetFileSelection = () => {
    setFile(undefined);
    onChange(undefined);
  };

  const handleInputChanged = () => {
    const file = inputEl.current?.files?.[0];

    setFile(file);
    onChange(file);
  };

  return (
    <div className="form-file">
      {preview(file)}

      <input id={id} type="file" ref={inputEl} value="" style={{ display: 'none' }} onChange={handleInputChanged} />

      <div className="form-file__actions">
        <ActionButton icon="cloud_upload" title={t.uploaderButtonTitle} onClick={openFileSelection} />

        {file && <ActionButton title={t.deleteButtonTitle} remove icon="delete" onClick={resetFileSelection} />}
      </div>
    </div>
  );
}

interface PreviewProps {
  file: File;
}

function Preview({ file }: PreviewProps) {
  const { isLoading, base64 } = useFileReader(file);

  if (isLoading) {
    return (
      <div className="form-file__content">
        <Loading type="small" />
      </div>
    );
  }

  return (
    <div className="form-file__content">
      {!!base64 && <img src={base64.toString()} alt="form-file" />}
      <span title={file.name}>{file.name}</span>
    </div>
  );
}

const MAX_SIZE_IN_BYTES = 5 * 1000 * 1000;

export function useFileReader(file?: File) {
  const isMounted = useIsMounted();
  const [base64, setBase64] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const callback = (base64: string | null) => {
      if (isMounted()) {
        setBase64(base64);
        setIsLoading(false);
      }
    };

    if (!!file && file.type.includes('image') && file.size <= MAX_SIZE_IN_BYTES) {
      setIsLoading(true);

      readFile(file)
        .then((data) => callback(data))
        .catch(() => callback(null));
    } else {
      setBase64(null);
    }
  }, [isMounted, file]);

  return { isLoading, base64 };
}

function readFile(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result?.toString() ?? null);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
}
