import { defineMessages } from 'react-intl';

import ActionButton from 'components/ActionButton';

export default function CloseButton({ onClick }: { onClick: () => void }) {
  return <ActionButton title={t.close} icon="close" onClick={onClick} />;
}

const t = defineMessages({
  close: {
    id: 'modal_close_title',
    defaultMessage: 'Close',
  },
});
