const RIGHTS = {
  VIEW_RECORD: 'VIEW_RECORD',

  // Admin
  MANAGE_SYSTEM: 'MANAGE_SYSTEM',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_CENTERS: 'MANAGE_CENTERS',
  MANAGE_APPLICATION_DATA: 'MANAGE_APPLICATION_DATA',

  // Patient record
  EDIT_ADMINISTRATIVE_RECORD: 'EDIT_ADMINISTRATIVE_RECORD',
  EDIT_DIET_RECORD: 'EDIT_DIET_RECORD',
  EDIT_MEDICAL_RECORD: 'EDIT_MEDICAL_RECORD',
  EDIT_NURSING_RECORD: 'EDIT_NURSING_RECORD',
  ENROLL_PATIENT: 'ENROLL_PATIENT',

  // Dialysis
  MANAGE_DIALYSIS_PRESCRIPTION: 'MANAGE_DIALYSIS_PRESCRIPTION',
  PERFORM_SESSION: 'PERFORM_SESSION',
  SIGN_SESSION: 'SIGN_SESSION',

  // Medication
  MANAGE_MEDICATION_PRESCRIPTION: 'MANAGE_MEDICATION_PRESCRIPTION',
  PRESCRIBE_HOME_MEDICATION_PRESCRIPTION: 'PRESCRIBE_HOME_MEDICATION_PRESCRIPTION',
  MANAGE_MEDICATION_SCHEDULE: 'MANAGE_MEDICATION_SCHEDULE',
  VALIDATE_MEDICATION: 'VALIDATE_MEDICATION',

  // Orders
  DESTROY_ORDER: 'DESTROY_ORDER',
  PLACE_ORDER: 'PLACE_ORDER',

  // Wound care
  MANAGE_WOUNDCARE_PRESCRIPTION: 'MANAGE_WOUNDCARE_PRESCRIPTION',

  // Journal
  MANAGE_MEDICAL_JOURNAL: 'MANAGE_MEDICAL_JOURNAL',
  MANAGE_NURSING_JOURNAL: 'MANAGE_NURSING_JOURNAL',
  MANAGE_DIETARY_JOURNAL: 'MANAGE_DIETARY_JOURNAL',
  MANAGE_PSYCHOLOGICAL_JOURNAL: 'MANAGE_PSYCHOLOGICAL_JOURNAL',
  MANAGE_SOCIAL_JOURNAL: 'MANAGE_SOCIAL_JOURNAL',
  READ_MEDICAL_JOURNAL: 'READ_MEDICAL_JOURNAL',
  READ_NURSING_JOURNAL: 'READ_NURSING_JOURNAL',
  READ_DIETARY_JOURNAL: 'READ_DIETARY_JOURNAL',
  READ_PSYCHOLOGICAL_JOURNAL: 'READ_PSYCHOLOGICAL_JOURNAL',
  READ_SOCIAL_JOURNAL: 'READ_SOCIAL_JOURNAL',
  EHR_PUBLISH_JOURNAL: 'EHR_PUBLISH_JOURNAL',

  // Observation
  CREATE_OBSERVATION: 'CREATE_OBSERVATION',

  // Planning
  VIEW_PLANNING: 'VIEW_PLANNING',
  MANAGE_PLANNING: 'MANAGE_PLANNING',

  // Briefings
  VIEW_BRIEFING: 'VIEW_BRIEFING',
  MANAGE_BRIEFING: 'MANAGE_BRIEFING',

  // Exports
  MANAGE_EXPORTS: 'MANAGE_EXPORTS',

  // Patient agenda
  PATIENT_AGENDA_FULL_DETAIL_VIEW: 'PATIENT_AGENDA_FULL_DETAIL_VIEW',
  MANAGE_APPOINTMENT_CONFLICTS: 'MANAGE_APPOINTMENT_CONFLICTS',

  // Letters
  MANAGE_PATIENT_LETTER: 'MANAGE_PATIENT_LETTER',
  VALIDATE_PATIENT_LETTER: 'VALIDATE_PATIENT_LETTER',
} as const;

export type RightClaimT = keyof typeof RIGHTS;

export { RIGHTS };
