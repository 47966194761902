import t from '../errorTranslations';
import { RequiredValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export type RequiredValidator = typeof requiredValidator;

export function requiredValidator(validation: RequiredValidationT) {
  const { args } = validation;

  if (args[0] === true) {
    return {
      validator: (val: any) => val !== undefined && val !== null && val.toString().length > 0,
      errorMessage: formatErrorMessage(validation),
    };
  }

  return {
    validator: () => true,
    errorMessage: '',
  };
}

export function required(intlOrErrMsg: IntlOrErrMsg): RequiredValidationT {
  return {
    type: 'required',
    args: [true],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl }: RequiredValidationT) {
  if (errorMessage) return errorMessage;
  if (intl) return intl.formatMessage(t.required);
  return '';
}
