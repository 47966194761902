import { SVGProps } from 'react';
import cx from 'classnames';

interface Props extends SVGProps<SVGSVGElement> {
  color: string;
  isSelected?: boolean;
  isHoverable?: boolean;
}

export default function ColorPreview(props: Props) {
  const { color, isSelected = false, isHoverable = false, className, ...svgProps } = props;

  return (
    <svg
      {...svgProps}
      className={cx('color-preview', className, {
        '-is-selected': isSelected,
        '-is-hoverable': isHoverable,
      })}
      width={16}
      height={16}
      role="presentation"
    >
      <rect width={16} height={16} rx={8} fill={color} />

      <circle className="color-preview__selected-indicator" cx={8} cy={8} r={3} fill="white" />
    </svg>
  );
}
