import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import { fetchCenterOptions } from 'api/endpoints/centers';
import { useStoreErrorHandler } from 'api/storeErrorHandler';

import fullName from 'utils/fullName';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useCloseActionBar from 'hooks/useCloseActionBar';
import useSearchString from 'hooks/useSearchString';
import useToast from 'hooks/useToast';

import ActionBar from 'components/ActionBar';
import ActionBarManager from 'components/ActionBarManager';
import ActionButton from 'components/ActionButton';
import { Filter, ListFilterField, SearchFilterField } from 'components/Filter';
import Main from 'components/Main';
import { Pages } from 'components/Paginator';
import Section from 'components/Section';
import Sex from 'components/Sex';
import Table, { actionColWidth } from 'components/Table';
import Title from 'components/Title';

import LaunchIDButton from 'sharedComponents/LaunchIDButton';

import {
  ADD_PATIENT_CARD,
  addPatientCardActivity,
  CREATE_PATIENT_CARD_FAILURE,
  DELETE_PATIENT_CARD_FAILURE,
  EDIT_PATIENT_CARD,
  editPatientCardActivity,
  removePatientCardActivity,
  UPDATE_PATIENT_CARD_FAILURE,
} from './actions';
import Form from './components/Form';
import { createPatientCard, deletePatientCard, fetchPatientCards, PatientCard, updatePatientCard } from './fetchers';
import t from './translations';

const QUERY_KEY = 'devices/patientCards';

export default function Cards() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchString({
    centerId: 'all',
    page: 0,
    query: '',
  });

  const { data: centerOptions = [] } = useQuery('treatments/centerOptions', () => fetchCenterOptions());

  const centerOptionsWithAll = [{ label: formatMessage(t.all), value: 'all' }, ...centerOptions];

  const { data } = useQuery([QUERY_KEY, searchParams], () => fetchPatientCards(searchParams));

  const { createPatientCard, updatePatientCard, deletePatientCard } = useMutations(QUERY_KEY);

  const onFilterChange = ({ centerId, query }: { centerId: string; query: string }) => {
    setSearchParams({ centerId, query, page: 0 });
  };

  const onPageChange = (page: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const headers = [
    t.type,
    t.identifier,
    t.externalIdentifier,
    t.patientInformation,
    (key: number) => (
      <Table.Actions key={key} thead>
        <ActionButton color="info" icon="add" title={t.create} onClick={() => dispatch(addPatientCardActivity())} />
      </Table.Actions>
    ),
  ];

  return (
    <Main>
      <Section hasFilter withoutPadding>
        <Section.Title>
          <FormattedMessage {...t.title} />
        </Section.Title>

        <Filter value={{ centerId: searchParams.centerId, query: searchParams.query }} onChange={onFilterChange}>
          <ListFilterField name="centerId" label={formatMessage(t.centerFilter)} options={centerOptionsWithAll} />
          <SearchFilterField name="query" placeholder={formatMessage(t.searchFilter)} minQueryLength={0} />
        </Filter>

        <Table
          fixedColumnWidths={['1fr', '1.5fr', '1.5fr', '2fr', actionColWidth(3)]}
          headers={headers}
          items={data?.data}
          renderItem={(card) => (
            <Row
              key={card.id}
              card={card}
              onEdit={() => dispatch(editPatientCardActivity(card))}
              onDelete={() => dispatch(removePatientCardActivity(card))}
              onDeleteConfirmed={() => deletePatientCard(card.id)}
            />
          )}
        />

        <Pages {...data?.meta} loadPage={onPageChange} />
      </Section>

      <ActionBarManager
        formMapping={{
          [ADD_PATIENT_CARD]: () => <AddActionBar centerOptions={centerOptions} submit={createPatientCard} />,
          [EDIT_PATIENT_CARD]: () => <EditActionBar centerOptions={centerOptions} submit={updatePatientCard} />,
        }}
      />
    </Main>
  );
}

function Row({
  card,
  onEdit,
  onDelete,
  onDeleteConfirmed,
}: {
  card: PatientCard;
  onEdit: () => void;
  onDelete: () => void;
  onDeleteConfirmed: () => void;
}) {
  const { id: cardId, identifier, externalIdentifier, deviceName, birthdate, sex, firstName, lastName } = card;

  return (
    <tr>
      <td>{deviceName}</td>

      <td>{identifier}</td>

      <td>{externalIdentifier}</td>

      <td>
        <Title
          dimmedSubtext={false}
          subtext={
            <div className="flex items-center">
              {birthdate ? <FormattedDate value={birthdate} /> : '-'}
              <Sex value={sex} className="table-icon" />
            </div>
          }
        >
          <strong>{firstName || lastName ? fullName(card) : identifier}</strong>
        </Title>
      </td>

      <Table.Actions>
        <Table.RemoveOverlay
          resourceType="patientCard"
          resourceId={cardId}
          confirmationQuestion={t.deleteConfirmation}
          confirm={onDeleteConfirmed}
        />

        <LaunchIDButton card={card} />

        <ActionButton icon="edit" title={t.update} onClick={onEdit} />

        <ActionButton remove icon="delete" title={t.delete} onClick={onDelete} />
      </Table.Actions>
    </tr>
  );
}

function AddActionBar({ centerOptions, submit }: { centerOptions: OptionT[]; submit: (values: any) => void }) {
  return (
    <ActionBar title={t.create}>
      <Form submit={submit} centers={centerOptions} />
    </ActionBar>
  );
}

function EditActionBar({ centerOptions, submit }: { centerOptions: OptionT[]; submit: (values: any) => void }) {
  const { patientCard } = useAppSelector((state) => state.activity.payload);

  const onSubmit = (values: any) => submit({ ...values, id: patientCard.id });

  return (
    <ActionBar title={t.update}>
      <Form card={patientCard} submit={onSubmit} centers={centerOptions} />
    </ActionBar>
  );
}

function useMutations(queryKey: QueryKey) {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const toast = useToast({ type: 'success' });
  const errorHandler = useStoreErrorHandler();
  const closeActionBar = useCloseActionBar();

  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  const { mutate: createPatientCardMutation } = useMutation(createPatientCard, {
    onSuccess: () => {
      toast(formatMessage(t.createSuccess));
      invalidateQuery();
      closeActionBar();
    },
    onError: (error: any) => {
      errorHandler({ error, failureType: CREATE_PATIENT_CARD_FAILURE });
    },
  });

  const { mutate: updatePatientCardMutation } = useMutation(updatePatientCard, {
    onSuccess: () => {
      toast(formatMessage(t.updateSuccess));
      invalidateQuery();
      closeActionBar();
    },
    onError: (error: any) => {
      errorHandler({ error, failureType: UPDATE_PATIENT_CARD_FAILURE });
    },
  });

  const { mutate: deletePatientCardMutation } = useMutation(deletePatientCard, {
    onSuccess: () => {
      toast(formatMessage(t.deleteSuccess));
      invalidateQuery();
      closeActionBar();
    },
    onError: (error: any) => {
      errorHandler({ error, failureType: DELETE_PATIENT_CARD_FAILURE });
    },
  });

  return {
    createPatientCard: createPatientCardMutation,
    updatePatientCard: updatePatientCardMutation,
    deletePatientCard: deletePatientCardMutation,
  };
}
