import { defineMessages, useIntl } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { Tooltip } from 'components/Popover';

export interface PatientCard {
  machineId: string;
  identifier: string;
  firstName: string;
  lastName: string;
  sex: 'M' | 'F';
  birthdate: Date;
}

export default function LaunchIDButton({ card }: { card: PatientCard }) {
  const { formatMessage } = useIntl();

  const onClick = () => {
    const uri = idLink(card);
    window.location.href = uri;
  };

  return card.machineId === 'nipro_surdial_x' ? (
    <Tooltip placement="top" content={formatMessage(t.tooltip)}>
      <ActionButton icon="credit_card" title="" onClick={onClick} />
    </Tooltip>
  ) : null;
}

function idLink(card: PatientCard) {
  const patient = {
    id: card.identifier,
    firstName: card.firstName,
    lastName: card.lastName,
    sex: card.sex,
    birthdate: card.birthdate.toISOString().slice(0, 10),
  };

  const protocol = 'nephroflow-id';
  const data = btoa(JSON.stringify(patient));

  return `${protocol}://${data}`;
}

const t = defineMessages({
  tooltip: {
    id: 'nephroflow_id_tooltip',
    defaultMessage: "Launch NephroFlow ID. Please verify you've installed NephroFlow ID, before clicking this button.",
  },
});
