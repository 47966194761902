import { FormEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/Button';

import t from '../translations';

interface LoginFormProps {
  onSubmit: (values: { username: string; password: string }) => void;
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  const { formatMessage } = useIntl();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { username, password } = (event.target as HTMLFormElement).elements as any;

    onSubmit({ username: username.value, password: password.value });
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="username" className="sr-only">
        <FormattedMessage {...t.username} />
      </label>

      <input autoFocus type="text" id="username" name="username" placeholder={formatMessage(t.username)} />

      <label htmlFor="password" className="sr-only">
        <FormattedMessage {...t.password} />
      </label>

      <input type="password" id="password" name="password" placeholder={formatMessage(t.password)} />

      <Button color="info" block type="submit">
        <FormattedMessage {...t.loginSubmit} />
      </Button>
    </form>
  );
}
