import { ReactNode } from 'react';
import cx from 'classnames';

import Icon from 'components/Icon';

type valueT = string | any | number;

type Props = {
  style?: any;
  label: ReactNode;
  value: valueT;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: valueT) => void;
};

export default function Checkbox({ style = {}, label = '', value, checked, disabled = false, onChange }: Props) {
  return (
    <button
      style={style}
      className={cx('form-option', {
        'is-active': checked,
        'is-disabled': disabled,
      })}
      onClick={() => onChange(value)}
      type="button"
      disabled={disabled}
    >
      <Icon>{checked ? 'check_box' : 'check_box_outline_blank'}</Icon>
      {label}
    </button>
  );
}
