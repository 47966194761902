import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'store';
import {
  formSelector,
  registerFormValue as registerFormValueAction,
  resetForm as resetFormAction,
  setClientFormErrors as setClientFormErrorsAction,
  setForm as setFormAction,
  valuesSelector,
} from 'store/modules/form';

import { ShouldTrigger } from 'hooks/useTrigger';

import FlattenedForm from './components/FlattenedForm';
import FormItem from './components/Item';
import { dependencyMet } from './utils/dependency';
import injectAdditionalValues from './utils/injectAdditionalValues';
import { getValidator } from './validators';

const DEFAULT_FORM_NAME = 'global';

interface OwnProps {
  config: any[] | any;
  defaultValues?: any;
  formName?: string;
  autoFocus?: boolean;
  autoFocusSectionWithTitle?: string;

  submit?: (values: any) => void;
  shouldSubmit?: ShouldTrigger;
  afterValidate?: (errors: any, values: any) => any;

  customComponents?: any;
  dynamicProps?: any;
  customProps?: any;

  shouldRenderErrors?: boolean;

  errors?: any;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

class Form extends Component<Props> {
  componentDidMount() {
    const { formName = DEFAULT_FORM_NAME, config, defaultValues, setForm, registerFormValue } = this.props;

    setForm(config, formName);
    registerFormValue(defaultValues, formName);
  }

  shouldComponentUpdate(newProps) {
    return !isEqual(newProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const { shouldSubmit, values, errors, validators, setClientFormErrors } = this.props;

    if (shouldSubmit) {
      this.submitForm();
      shouldSubmit();
    }

    if (!isEqual(prevProps.values, values)) {
      if (errors && Object.keys(errors).length > 0) {
        const newErrors = this.validate(validators, values);

        if (!isEqual(newErrors, prevProps.errors)) {
          setClientFormErrors(newErrors);
        }
      }
    }
  }

  componentWillUnmount() {
    const { formName = DEFAULT_FORM_NAME, resetForm } = this.props;
    resetForm(formName);
  }

  validate(validators, values) {
    const { dynamicProps, afterValidate } = this.props;

    const errors = Object.keys(validators).reduce((acc, fieldId) => {
      const dynamicFieldId = getFieldId(fieldId, dynamicProps);
      const fieldValidators = validators[fieldId];
      const errs: any[] = [];

      fieldValidators.forEach((validator) => {
        const valid = validator(values[dynamicFieldId]);
        if (!valid.valid) {
          errs.push(valid.error);
        }
      });

      return errs.length > 0 ? { ...acc, [dynamicFieldId]: errs } : acc;
    }, {});

    return afterValidate ? afterValidate(errors, values) : errors;
  }

  submitForm() {
    const { validators, values, errors, setClientFormErrors, submit } = this.props;

    const newErrors = this.validate(validators, values);

    if (!isEqual(newErrors, errors)) setClientFormErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return false;

    submit?.(values);

    return true;
  }

  renderField({
    id,
    title,
    placeholder,
    disabled,
    type,
    options,
    unit,
    disablePast,
    disableFuture,
    disableToday,
    disableDate,
    enableMultiDates,
    customComponentId,
    validations,
    allowedContentTypes,
    autoFocus,
    style,
    fileDisplayValue,
  }) {
    const {
      dynamicProps,
      values,
      formName = DEFAULT_FORM_NAME,
      registerFormValue,
      errors,
      customComponents,
      customProps,
    } = this.props;

    const isOptional = (validators) => !validators.find((val) => val.type === 'required');

    const dynamicFormProps = dynamicProps && dynamicProps[id] ? dynamicProps[id] : {};

    const dynamicId = dynamicFormProps.id || id;

    const defaultFieldProps = {
      // static props
      value: values[dynamicId],
      defaultValue: values[dynamicId],
      onChange: (val) => registerFormValue(val, formName),
      errors,
      // potentialy dynamic props
      key: dynamicId,
      id: dynamicId,
      label: dynamicFormProps.title || title,
      placeholder: dynamicFormProps.placeholder || placeholder,
      optional: isOptional(dynamicFormProps.validations || validations || []),
      disabled: dynamicFormProps.disabled || disabled,
      type: dynamicFormProps.type || type,
      options: dynamicFormProps.options || options,
      unit: dynamicFormProps.unit || unit,
      style: dynamicFormProps.style || style,
      fileDisplayValue, // Avatar
      disablePast,
      disableFuture,
      disableToday,
      disableDate: dynamicFormProps.disableDate || disableDate,
      enableMultiDates,
      allowedContentTypes,
      autoFocus,
    };

    const customFieldProps = customProps?.[id] || {};

    const customComponent = customComponents ? customComponents[customComponentId] : undefined;

    if (customComponent) {
      const customProps = {
        ...defaultFieldProps,
        ...customComponent.customProps,
      };
      return <customComponent.component {...customProps} />;
    }

    return <FormItem {...{ ...customFieldProps, ...defaultFieldProps }} />;
  }

  render() {
    const { form, errors, autoFocus = true, autoFocusSectionWithTitle, shouldRenderErrors } = this.props;

    if (!form) return null;

    return (
      <FlattenedForm
        autoFocusSectionWithTitle={autoFocusSectionWithTitle}
        autoFocus={!!autoFocus}
        config={form}
        errors={errors}
        renderField={(...args) => this.renderField(...args)}
        shouldRenderErrors={shouldRenderErrors}
      />
    );
  }
}

function getFieldId(staticId, dynamicProps) {
  if (!dynamicProps || !dynamicProps[staticId]) return staticId;

  return dynamicProps[staticId].id || staticId;
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const form = formSelector(state, ownProps.formName || DEFAULT_FORM_NAME);
  const values = valuesSelector(state, ownProps.formName || DEFAULT_FORM_NAME);

  if (!form) return {};

  const formValues = injectAdditionalValues(form, values);

  // Filter form config based on dependencies
  const filteredForm = form.sections
    ? form.sections.reduce(
        (acc1, section) => {
          const filteredFields = section.fields.reduce((acc2, field) => {
            if (dependencyMet(field.dependency, formValues)) {
              return [...acc2, field];
            }

            return acc2;
          }, []);

          if (section.fields.length > 0) {
            return {
              sections: [...acc1.sections, { ...section, fields: filteredFields }],
            };
          }

          return acc1;
        },
        { sections: [] }
      )
    : form.reduce((acc, field) => {
        if (dependencyMet(field.dependency, formValues)) {
          return [...acc, field];
        }

        return acc;
      }, []);

  // Flatten sections to one large list of fields
  const flattenedForm = filteredForm.sections
    ? filteredForm.sections.reduce((acc, section) => [...acc, ...section.fields], [])
    : filteredForm;

  // Filter values based on what fields remain after dependency check
  const filteredValues = flattenedForm.reduce((acc, field) => {
    const id = getFieldId(field.id, ownProps.dynamicProps);
    return { ...acc, [id]: values[id] };
  }, {});

  // Get a list of validators per visible field
  const validators = flattenedForm.reduce(
    (acc, field) => ({
      ...acc,
      [field.id]: field.validations ? field.validations.map(getValidator) : [],
    }),
    {}
  );

  // Transform list of errors into list of errors per field

  const errors = ownProps.errors ? [...state.errors, ...ownProps.errors] : state.errors;

  const filteredErrors = errors
    ? errors
        .filter((err) => err.handlerType === 'form')
        .reduce(
          (acc, error) => ({
            ...acc,
            [error.source || 'noSource']: [...(acc[error.source || 'noSource'] || []), error],
          }),
          {}
        )
    : {};

  return {
    form: filteredForm,
    values: filteredValues,
    errors: filteredErrors,
    validators,
  };
}

const mapDispatchToProps = {
  resetForm: resetFormAction,
  setForm: setFormAction,
  registerFormValue: registerFormValueAction,
  setClientFormErrors: setClientFormErrorsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Form);
export { default as useDisabledStartAndEndDates } from './utils/useDisabledStartAndEndDates';
