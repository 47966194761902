import { Children, cloneElement, CSSProperties, isValidElement, MouseEvent, ReactNode, RefObject } from 'react';
import cx from 'classnames';

import Loading from 'components/Loading';
import { isTabListElement } from 'components/Tabs/TabList';

import SectionSubContent from './SectionSubContent';
import SectionSubTitle from './SectionSubTitle';
import SectionTitle, { isSectionTitleElement } from './SectionTitle';

export interface SectionProps {
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
  contentClassName?: string;
  style?: CSSProperties;
  isLoading?: boolean;
  withoutPadding?: boolean;
  hasFilter?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  innerRef?: RefObject<HTMLElement>;
}

function Section({
  children,
  footer,
  className,
  contentClassName,
  isLoading = false,
  withoutPadding = false,
  hasFilter = false,
  style,
  onClick,
  innerRef,
  ...props
}: SectionProps) {
  const childArray = Children.toArray(children);

  const content = isLoading ? (
    <Loading type="static" />
  ) : (
    childArray.filter((child: any) => !(isSectionTitleElement(child) || isTabListElement(child)))
  );

  const tabTitleElements = childArray
    .filter((child: any) => isTabListElement(child))
    .map((child) => (isValidElement(child) ? cloneElement(child, { isSectiontitle: true }) : child));

  const hasTabTitleElements = tabTitleElements.length > 0;

  const titleElements = childArray
    .filter((child: any) => isSectionTitleElement(child))
    .map((child) =>
      hasTabTitleElements && isValidElement(child) ? cloneElement(child, { withoutBorder: true }) : child
    );

  const hasTitleElements = titleElements.length > 0;

  const sectionContentClassName = cx(contentClassName, 'section-content', {
    '-without-padding': withoutPadding,
    '-has-filter': hasFilter,
  });

  return (
    <section role="presentation" className={className} style={style} onClick={onClick} ref={innerRef}>
      {hasTitleElements || hasTabTitleElements ? (
        <>
          {titleElements}
          {tabTitleElements}

          <div data-testid={props['data-testid']} className={sectionContentClassName}>
            {content}
          </div>

          {footer && !isLoading ? <div className="section-footer">{footer}</div> : null}
        </>
      ) : (
        <div data-testid={props['data-testid']} className={sectionContentClassName}>
          {content}
        </div>
      )}
    </section>
  );
}

export default Object.assign(Section, {
  Title: SectionTitle,
  SubTitle: SectionSubTitle,
  SubContent: SectionSubContent,
});
