import { defineMessages, useIntl } from 'react-intl';

import Button, { ButtonProps } from 'components/Button';

type Props = ButtonProps;

export default function CancelButton({ children, ...buttonProps }: Props) {
  const { formatMessage } = useIntl();

  return <Button {...buttonProps}>{children || formatMessage(t.cancel)}</Button>;
}

const t = defineMessages({
  cancel: {
    id: 'modal_cancel_button',
    defaultMessage: 'Cancel',
  },
});
