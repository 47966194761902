import { IntlShape } from 'react-intl';

import { BaseValidationT } from '../schema';

export type IntlOrErrMsg = IntlShape | string;

export function createBaseValidation(intlOrErrorMessage: IntlOrErrMsg): BaseValidationT {
  if (!intlOrErrorMessage) return {};

  const isString = typeof intlOrErrorMessage === 'string';

  return {
    intl: !isString ? (intlOrErrorMessage as IntlShape) : undefined,
    errorMessage: isString ? (intlOrErrorMessage as string) : undefined,
  };
}
