import { MouseEvent } from 'react';
import cx from 'classnames';
import { useSlate } from 'slate-react';

import Icon from 'components/Icon';

import { Rte } from '../domain';
import Button from './Button';

interface Props {
  level: Rte.HeadingType;
}

export default function HeadingButton({ level }: Props) {
  const editor = useSlate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    editor.toggleBlock(level);
    editor.focus();
  };

  return (
    <Button onClick={handleClick} className={cx({ 'is-active': editor.isBlockActive(level) })}>
      <Icon>{getLevelIcon(level)}</Icon>
    </Button>
  );
}

const getLevelIcon = (level: Rte.HeadingType): string => {
  const levelNr = parseInt(level.slice(-1), 10);

  if (levelNr === 1) return 'looks_one';
  if (levelNr === 2) return 'looks_two';
  return `looks_${levelNr}`;
};
