import { useCallback, useEffect, useState } from 'react';

import useSyncedRef from './useSyncedRef';

export type Trigger = () => void;
export type ShouldTrigger = (() => void) | null;

export function useShouldTrigger() {
  const [hasBeenTriggered, setHasBeenTriggered] = useState(false);
  const turnOn = useCallback(() => setHasBeenTriggered(true), []);
  const turnOff = useCallback(() => setHasBeenTriggered(false), []);

  const shouldTrigger = hasBeenTriggered ? turnOff : null;

  return [shouldTrigger, turnOn] as [ShouldTrigger, Trigger];
}

export function useTrigger(shouldTrigger: ShouldTrigger | undefined, callback: CallableFunction) {
  const callbackRef = useSyncedRef(callback);

  useEffect(() => {
    if (typeof shouldTrigger === 'function') {
      callbackRef.current();
      shouldTrigger();
    }
  }, [callbackRef, shouldTrigger]);
}
