import { FormikValues } from 'formik';

import { FormT } from 'components/Form/schema';

export default function extractConfigValues<Values extends FormikValues>(form: FormT) {
  return {
    ...form.sections
      .flatMap((section) => section.fields)
      .reduce((acc, field) => ({ ...acc, [field.id]: field.value }), {} as Values),
  };
}
