import { useState } from 'react';

import { LocalStorage } from 'utils/storage';

const FEATURE_FLAGS = {
  // FEATURE_NAME: true | false
};

// This type of feature flag should only be used for experimental changes to the manager.
// For new feature modules we have the `useConfiguration` hooks that work with the API.

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export default function useFeatureFlag() {
  const [state, setState] = useState(() => getStateFromStorage());

  const flags = Object.keys(FEATURE_FLAGS) as FeatureFlag[];

  const isEnabled = (flag: FeatureFlag) => state[flag] === true;

  const setFlag = (flag: FeatureFlag, value: boolean) => {
    setState((flags) => {
      LocalStorage.set<boolean>(prefixStorageKey(flag), value);
      return { ...flags, [flag]: value };
    });
  };

  return { flags, isEnabled, setFlag };
}

function prefixStorageKey(flag: string) {
  return `feature_flag_${flag.toLowerCase()}`;
}

function getStateFromStorage() {
  const flags = { ...FEATURE_FLAGS };

  for (const flag of Object.keys(flags)) {
    const value = LocalStorage.get<boolean>(prefixStorageKey(flag));

    if (value !== null) {
      flags[flag] = value;
    } else {
      const initial = flags[flag];
      LocalStorage.set<boolean>(prefixStorageKey(flag), initial);
    }
  }

  return flags;
}
