import { defineMessages, FormattedMessage } from 'react-intl';

import DisplayAvatar from 'components/Avatar';
import Loading from 'components/Loading';

import FileField, { useFileReader } from './File';

const t = defineMessages({
  currentAvatar: {
    id: 'avatar_field_current_avatar',
    defaultMessage: 'Current Avatar',
  },
});

type Props = {
  id: string;
  fileDisplayValue?: any;
  onChange: (args: any) => void;
};

export default function Avatar({ id, fileDisplayValue, onChange }: Props) {
  const previewer = (file?: File) => {
    return <AvatarPreview file={file} url={fileDisplayValue} />;
  };

  return <FileField id={id} onChange={onChange} previewer={previewer} />;
}

function AvatarPreview({ file, url }: { file?: File; url: string | null }) {
  const { isLoading, base64 } = useFileReader(file);

  if (isLoading) {
    return (
      <div className="form-file__content">
        <Loading type="small" />
      </div>
    );
  }

  return (
    <div className="form-file__content">
      <div className="flex items-center" style={{ opacity: !file ? 0.5 : 1 }}>
        {file ? (
          <>
            {base64 ? <div className="avatar with-margin" style={{ backgroundImage: `url(${base64})` }} /> : null}
            <span>{file?.name}</span>
          </>
        ) : (
          <>
            <DisplayAvatar className="with-margin" url={url} />
            <FormattedMessage tagName="span" {...t.currentAvatar} />
          </>
        )}
      </div>
    </div>
  );
}
