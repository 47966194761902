import { ChangeEvent as ReactChangeEvent } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<string>;

type CustomProps = {
  autoComplete?: boolean;
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['textarea'], keyof BaseProps | keyof CustomProps>;

export type TextAreaInputProps = BaseProps & CustomProps & HtmlProps;

export default function TextAreaInput({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  name,
  disabled = false,
  autoFocus = false,
  autoComplete = false,
  placeholder: rawPlaceholder,
  rows = 4,
}: TextAreaInputProps) {
  const { formatMessage } = useIntl();

  const isControlled = !!externalOnChange;

  const onChange = (event: ReactChangeEvent<HTMLTextAreaElement>) => {
    externalOnChange?.(event.target.value);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <textarea
      id={name}
      name={name}
      className="base-input -type-textarea"
      defaultValue={isControlled ? undefined : defaultValue || ''}
      value={isControlled ? value || '' : undefined}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete={autoComplete ? 'on' : 'off'}
      placeholder={rawPlaceholder ? placeholder : undefined}
      rows={rows}
    />
  );
}
