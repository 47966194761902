import t from '../errorTranslations';
import { MinLengthValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export function minLengthValidator(validation: MinLengthValidationT) {
  const minLength = validation.args[0];

  return {
    allowedTypes: ['string', 'array'],
    validator: (val: string) => val === undefined || val.length >= minLength,
    errorMessage: formatErrorMessage(validation),
  };
}

export function minLength(length: number, intlOrErrMsg: IntlOrErrMsg): MinLengthValidationT {
  return {
    type: 'min_length',
    args: [length],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl, args }: MinLengthValidationT) {
  const minLength = String(args[0]);

  if (errorMessage) return errorMessage.replace('#{min_length}', minLength);
  if (intl) return intl.formatMessage(t.minLength, { min: minLength });
  return '';
}
