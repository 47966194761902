import { MouseEvent } from 'react';
import { camelize } from 'humps';
import { useIntl } from 'react-intl';
import { useSlate } from 'slate-react';

import Icon from 'components/Icon';

import { Rte } from '../domain';
import t from '../translations';
import Button from './Button';

interface Props {
  format: Rte.BlockType;
  icon: string;
}

export default function BlockButton({ format, icon }: Props) {
  const { formatMessage } = useIntl();
  const editor = useSlate();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    editor.toggleBlock(format);
    editor.focus();
  };

  return (
    <Button isActive={editor.isBlockActive(format)} onClick={handleClick} title={formatMessage(t[camelize(format)])}>
      <Icon>{icon}</Icon>
    </Button>
  );
}
