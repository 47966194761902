import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import RTE from 'components/RTE';
import { Rte } from 'components/RTE/domain';

import { BaseInputProps } from './types';

export interface RichTextInputProps extends BaseInputProps<Rte.Node[]> {
  placeholder?: MessageDescriptor | string;
}

export default function RichTextInput({
  defaultValue,
  value,
  onChange,
  onBlur,
  name,
  placeholder: rawPlaceholder,
  disabled = false,
  autoFocus = false,
}: RichTextInputProps) {
  const { formatMessage } = useIntl();

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <div className="base-input -type-rich-text">
      <RTE
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={disabled}
        autoFocus={autoFocus}
        placeholder={rawPlaceholder ? placeholder : undefined}
      />
    </div>
  );
}
