import { Component, ReactNode } from 'react';

import { getSentry } from 'utils/sentry';

import ErrorPage from 'views/error';

export default class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: any) {
    const sentry = await getSentry();

    if (sentry) {
      sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        sentry.captureException(error);
      });
    }
  }

  async errorCallback() {
    const sentry = await getSentry();

    sentry?.showReportDialog();
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) return <ErrorPage onClickCallBack={this.errorCallback} />;

    return children;
  }
}
