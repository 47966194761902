import t from '../errorTranslations';
import { MaxValueValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export function maxValueValidator(validation: MaxValueValidationT) {
  const maxValue = validation.args[0];

  return {
    allowedTypes: ['number'],
    validator: (val: number) => val === undefined || val === null || val <= maxValue,
    errorMessage: formatErrorMessage(validation),
  };
}

export function maxValue(value: number, intlOrErrMsg: IntlOrErrMsg): MaxValueValidationT {
  return {
    type: 'max_value',
    args: [value],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl, args }: MaxValueValidationT) {
  const maxValue = String(args[0]);

  if (errorMessage) return errorMessage.replace('#{max}', maxValue);
  if (intl) return intl.formatMessage(t.maxValue, { max: maxValue });
  return '';
}
