import { version } from '../../package.json';

export async function initSentry() {
  if (import.meta.env.PROD) {
    const { init } = await import('@sentry/browser');
    const { BrowserTracing } = await import('@sentry/tracing');

    init({
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      release: version,
      environment: 'production',
      integrations: [new BrowserTracing()],
      tracesSampleRate: parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? '0.0'),
    });
  }
}

export async function getSentry() {
  if (import.meta.env.PROD) return await import('@sentry/browser');
}
