import { ChangeEvent, WheelEvent } from 'react';

type Props = {
  id?: string;
  value?: number | string;
  onChange: (val: number | null) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
};

export default function Float({ id, value, onChange, disabled = false, autoFocus, placeholder }: Props) {
  const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    const formattedValue = Number.isNaN(value) ? null : value;

    onChange(formattedValue);
  };

  // Firefox uses lang attribute first to determine decimal separator,
  // then `Accept-Language` of responses lastly the browser's language
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#localization

  // Chrome uses different settings to determine the decimal separator according to OS system
  // https://developer.chrome.com/docs/extensions/reference/i18n/#how-to-set-browsers-locale

  // Microsoft Edge uses the region of the OS to determine the decimal separator.
  // i.e. If you have english language but belgian region the decimal separator will be `,`

  return (
    <input
      id={id}
      type="number"
      step="any"
      disabled={disabled}
      placeholder={placeholder}
      value={value ?? ''}
      onChange={onChangeEvent}
      autoFocus={autoFocus}
      onWheel={(event: WheelEvent<HTMLInputElement>) => event.currentTarget.blur()}
    />
  );
}
