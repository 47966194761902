import { NormalizedSchema, schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { administrationRouteSchema } from 'store/modules/entities/actions/medicationManagement/administrationRoutes';
import { consumptionSetSchema } from 'store/modules/entities/actions/medicationManagement/consumptionSets';
import { registerFormErrorAction } from 'store/modules/errors';
import { networkActionTypes } from 'store/utils';

const priceSchema = new schema.Entity(
  'prices',
  {},
  { processStrategy: schemaDeserializers({ updatedAt: parseDateTime }) }
);

const packageSchema = new schema.Entity(
  'packages',
  { prices: [priceSchema] },
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
      startedAt: parseDateTime,
    }),
  }
);

const ingredientSchema = new schema.Entity(
  'ingredients',
  {},
  { processStrategy: schemaDeserializers({ updatedAt: parseDateTime }) }
);

const prescribableUnitSchema = new schema.Entity(
  'prescribableUnits',
  {},
  { processStrategy: schemaDeserializers({ updatedAt: parseDateTime }) }
);

const parentConsumptionSetItemSchema = new schema.Entity(
  'parentConsumptionSetItems',
  {},
  {
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
      createdAt: parseDateTime,
    }),
  }
);

const medicinalProductSubSchemas = {
  packages: [packageSchema],
  activeIngredients: [ingredientSchema],
  excipientIngredients: [ingredientSchema],
  administrationRoutes: [administrationRouteSchema],
  prescribableUnits: [prescribableUnitSchema],
  consumptionSets: [consumptionSetSchema],
  parentConsumptionSetItems: [parentConsumptionSetItemSchema],
  defaultRoute: administrationRouteSchema,
};

export const medicinalProductItemSchema = new schema.Entity('medicinalProductItems', medicinalProductSubSchemas, {
  processStrategy: (productItem) => ({
    ...productItem,
    ...schemaDeserializers({
      updatedAt: parseDateTime,
    })(productItem),
    stocks: productItem.stocks?.map(
      schemaDeserializers({
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
        syncedAt: parseDateTime,
      })
    ),
  }),
});

export const GET_MEDICINAL_PRODUCTS = networkActionTypes('GET_MEDICINAL_PRODUCTS');

export type GetMedicinalProductsArgsT = {
  page: number;
  filter: {
    name?: string;
    virtual?: boolean;
    sourceId?: string;
    intradialytic?: boolean;
    stateName?: string;
    ambulatory?: boolean;
    validationRequirement?: string;
    categoryId?: string;
    billedTo?: string;
  };
};

export const getMedicinalProducts = ({ page, filter }: GetMedicinalProductsArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_PRODUCTS,
    url: 'medications/products',
    method: 'GET',
    params: {
      page,
      pageSize: 30,
      filter,
    },
    normalizeSchema: { data: [medicinalProductItemSchema] },
  },
});

export const GET_MEDICINAL_PRODUCT = networkActionTypes('GET_MEDICINAL_PRODUCT');

export const getMedicinalProduct = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_PRODUCT,
    url: `medications/products/${id}`,
    method: 'GET',
    params: {
      codesAsTables: true,
    },
    normalizeSchema: medicinalProductItemSchema,
    actionPayload: { productId: id },
  },
});

export const UPDATE_MEDICINAL_PRODUCT = networkActionTypes('UPDATE_MEDICINAL_PRODUCT');

registerFormErrorAction(UPDATE_MEDICINAL_PRODUCT.FAILURE);

export type UpdateMedicinalProductArgsT = {
  id: string;
  categoryId: string;
  intradialytic: boolean;
  multidose: boolean;
  highRiskMedication: string;
  ambulatory: boolean;
  validationRequirement: string;
  billedTo: string;
};

export const updateMedicinalProduct = ({
  id,
  categoryId,
  intradialytic,
  ambulatory,
  multidose,
  highRiskMedication,
  validationRequirement,
  billedTo,
}: UpdateMedicinalProductArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_PRODUCT,
    url: `medications/products/${id}`,
    method: 'PUT',
    payload: {
      product: {
        categoryId: categoryId || null,
        intradialytic,
        ambulatory,
        multidose,
        highRiskMedication,
        validationRequirement,
        billedTo,
      },
    },
    normalizeSchema: { product: medicinalProductItemSchema },
  },
});

export const DELETE_MEDICINAL_PRODUCT = networkActionTypes('DELETE_MEDICINAL_PRODUCT');

export const deleteMedicinalProduct = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICINAL_PRODUCT,
    url: `medications/products/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});

export const SYNC_MEDICINAL_PRODUCT = networkActionTypes('SYNC_MEDICINAL_PRODUCT');

export const syncMedicinalProduct = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: SYNC_MEDICINAL_PRODUCT,
    url: `medications/products/${id}/sync`,
    method: 'POST',
  },
});

export const CREATE_MEDICINAL_PRODUCT = networkActionTypes('CREATE_MEDICINAL_PRODUCT');

registerFormErrorAction(CREATE_MEDICINAL_PRODUCT.FAILURE);

export type CreateMedicinalProductItemArgsT = {
  sourceId: string;
  name: string;
  validationRequirement: string;
  billedTo: string;
  highRiskMedication: 'yes' | 'no' | 'unknown';
  categoryId: string | null | undefined;
  companyId: string | null | undefined;
  virtual: boolean;
  routes: string[] | null | undefined;
  actualProducts: string[] | null | undefined;
  ingredients: string[] | null | undefined;
  formId: string | null | undefined;
};

export const createMedicinalProductItem = ({
  sourceId,
  name,
  validationRequirement,
  billedTo,
  highRiskMedication,
  categoryId,
  companyId,
  virtual,
  routes,
  actualProducts,
  formId,
  ingredients,
}: CreateMedicinalProductItemArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICINAL_PRODUCT,
    url: 'medications/products',
    method: 'POST',
    payload: {
      product: {
        sourceId,
        virtual,
        name,
        validationRequirement,
        billedTo,
        highRiskMedication,
        categoryId,
        companyId,
        routes,
        actualProducts,
        ingredients,
        formId,
      },
    },
    normalizeSchema: { product: medicinalProductItemSchema },
  },
});

export const GET_PRODUCT_ROUTES = networkActionTypes('GET_PRODUCT_ROUTES');

export const getProductRoutes = (productId: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_PRODUCT_ROUTES,
    url: `medications/products/${productId}/products_routes`,
    method: 'GET',
    actionPayload: { productId },
    normalizeSchema: {
      administrationRoutes: [administrationRouteSchema],
    },
  },
});

export interface PrescribableUnitArgs {
  name: string;
  conversionFactor: number;
  active: boolean;
  defaultPrescriptionUnit: boolean;
}

export const CREATE_PRESCRIBABLE_UNIT = networkActionTypes('CREATE_PRESCRIBABLE_UNIT');

export const createPrescribableUnit = (productId: string, prescribableUnit: PrescribableUnitArgs): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_PRESCRIBABLE_UNIT,
    url: `medications/products/${productId}/prescribable_units`,
    method: 'POST',
    payload: { prescribableUnit },
    actionPayload: { productId },
    normalizeSchema: { prescribableUnits: [prescribableUnitSchema] },
  },
});

export const UPDATE_PRESCRIBABLE_UNIT = networkActionTypes('UPDATE_PRESCRIBABLE_UNIT');

export const updatePrescribableUnit = (
  productId: string,
  { id, ...prescribableUnit }: PrescribableUnitArgs & { id: string }
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PRESCRIBABLE_UNIT,
    url: `medications/products/${productId}/prescribable_units/${id}`,
    method: 'PUT',
    payload: {
      prescribableUnit,
    },
    actionPayload: { id, productId },
    normalizeSchema: { prescribableUnits: [prescribableUnitSchema] },
  },
});

export const intradialyticMedicinalProductItemSchema = new schema.Entity(
  'medicinalProductItems',
  medicinalProductSubSchemas,
  {
    idAttribute: ({ id, consumptionSetId }) => {
      const idKey = `product_id_${id}`;

      if (!consumptionSetId) return idKey;

      return `${idKey}_consumption_id_${consumptionSetId}`;
    },
    processStrategy: schemaDeserializers({
      updatedAt: parseDateTime,
    }),
  }
);

export type SearchIntradialyticProductsResponse = NetworkSuccessResponse<
  NormalizedSchema<
    {
      medicinalProductItems: Record<string, IntradialyticPrescribableMedicinalProductItemT>;
    },
    { products: string[] }
  >
>;

export const SEARCH_INTRADIALYTIC_PRODUCTS = networkActionTypes('SEARCH_INTRADIALYTIC_PRODUCTS');

export const searchIntradialyticProducts = ({
  name,
  virtual = false,
}: {
  name: string;
  virtual?: boolean;
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: SEARCH_INTRADIALYTIC_PRODUCTS,
    url: 'medications/intradialytic_products/search/local',
    method: 'GET',
    params: { name, virtual, limit: 100 },
    normalizeSchema: {
      products: [intradialyticMedicinalProductItemSchema],
    },
  },
});

export const CREATE_ADMINISTRATION_ROUTE_PRODUCT = networkActionTypes('CREATE_ADMINISTRATION_ROUTE_PRODUCT');

export const createAdministrationRouteProduct = (
  productId: string,
  routeId: string,
  defaultRoute: boolean
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_ADMINISTRATION_ROUTE_PRODUCT,
    url: 'medications/products_routes',
    method: 'POST',
    payload: {
      productId: productId,
      routeId: routeId,
      default: defaultRoute,
    },
    normalizeSchema: { product: medicinalProductItemSchema },
    actionPayload: { productId },
  },
});

export const UPDATE_ADMINISTRATION_ROUTE_PRODUCT = networkActionTypes('UPDATE_ADMINISTRATION_ROUTE_PRODUCT');

export const updateAdministrationRouteProduct = (defaultRoute: boolean, productRouteId: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_ADMINISTRATION_ROUTE_PRODUCT,
    url: `medications/products_routes/${productRouteId}`,
    method: 'PUT',
    payload: {
      default: defaultRoute,
    },
    normalizeSchema: { product: medicinalProductItemSchema },
  },
});

export const DELETE_ADMINISTRATION_ROUTE_PRODUCT = networkActionTypes('DELETE_ADMINISTRATION_ROUTE_PRODUCT');

export const deleteAdministrationRouteProduct = (
  productId: string,
  routeId: string,
  productRouteId: string
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_ADMINISTRATION_ROUTE_PRODUCT,
    url: `medications/products_routes/${productRouteId}`,
    method: 'DELETE',
    actionPayload: { routeId, productId },
  },
});
