import { CSSProperties, ReactNode } from 'react';
import cx from 'classnames';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import assert from 'utils/assert';
import { isMessageDescriptor } from 'utils/intl';
import omitNullable from 'utils/omitNullable';

import Loading from 'components/Loading';

import TableFullWidthCell from './FullWidthCell';
import { useOptionalTableGroup } from './GroupContext';
import t from './translations';

export type Header = null | undefined | string | MessageDescriptor | ((key: number) => ReactNode);

export type ColumnWidth = string | null | undefined;

export interface Props<T = any> {
  items: T[] | null | undefined;

  renderItem?: (value: T, index: number, array: T[]) => ReactNode;
  renderGroup?: (value: T, index: number, array: T[]) => ReactNode;

  headers?: Header[];
  fixedColumnWidths?: ColumnWidth[];
  noItemsText?: ReactNode;
  className?: string;

  isSticky?: boolean;
  isScrollable?: boolean;
  isAlignedToTop?: boolean;
  withBorders?: boolean;

  style?: CSSProperties;
  tableWrapperStyle?: CSSProperties;
}

interface NormalizedProps<T> extends Props<T> {
  headers: NonNullable<Header>[];
  fixedColumnWidths: NonNullable<ColumnWidth>[] | undefined;

  isSticky: boolean;
  isScrollable: boolean;
  isAlignedToTop: boolean;
  withBorders: boolean;
}

export default function Table<T>(rawProps: Props<T>) {
  const tableGroupContext = useOptionalTableGroup();

  const props = normalizeProps(rawProps);

  const {
    renderGroup,
    fixedColumnWidths,
    className,
    style: customStyle,
    tableWrapperStyle,
    isSticky,
    isScrollable,
    isAlignedToTop,
    withBorders,
  } = props;

  if (tableGroupContext) {
    assert(fixedColumnWidths, 'The prop `fixedColumnWidths` is required on tables rendered inside `Table.Group`.');
  }

  const style = fixedColumnWidths ? { '--fixed-column-widths': fixedColumnWidths.join(' ') } : undefined;

  return (
    <div
      className={cx('table', {
        '-is-group-parent': !!renderGroup,
        '-is-group-child': !!tableGroupContext,
        '-is-grid': !!fixedColumnWidths || isSticky,
        '-is-sticky': isSticky,
        '-is-aligned-top': isAlignedToTop,
        '-is-scrollable': isScrollable,
        '-with-borders': withBorders,
      })}
      style={{ ...tableWrapperStyle, ...style }}
    >
      <table className={className} style={customStyle}>
        <TableHead {...props} />
        <TableBody {...props} />
      </table>
    </div>
  );
}

function TableHead<T>({ headers }: NormalizedProps<T>) {
  const { formatMessage } = useIntl();

  if (!headers.length) {
    return <thead />;
  }

  const renderString = (string: string, index: number) => (
    <th key={index} title={string}>
      {string}
    </th>
  );

  const renderHeader = (header: NonNullable<Header>, index: number) => {
    if (isMessageDescriptor(header)) header = formatMessage(header);

    if (typeof header === 'function') return header(index);
    if (typeof header === 'string') return renderString(header, index);

    return null;
  };

  return (
    <thead>
      <tr>{headers.map(renderHeader)}</tr>
    </thead>
  );
}

function TableBody<T>({ items, renderItem, renderGroup, noItemsText }: NormalizedProps<T>) {
  const tableGroupContext = useOptionalTableGroup();

  if (!items) {
    return (
      <tbody>
        <tr>
          <TableFullWidthCell className="table-cell -is-loading">
            <Loading type="static" />
          </TableFullWidthCell>
        </tr>
      </tbody>
    );
  }

  if (items.length === 0) {
    return (
      <tbody>
        <tr>
          <TableFullWidthCell className="table-cell -is-empty">
            {noItemsText || <FormattedMessage {...t.noItems} />}
          </TableFullWidthCell>
        </tr>
      </tbody>
    );
  }

  const children = (() => {
    if (renderItem) return items.map(renderItem);
    if (renderGroup) return items.map(renderGroup);

    return null;
  })();

  return (
    <tbody ref={tableGroupContext?.ref} style={tableGroupContext?.style}>
      {children}
    </tbody>
  );
}

function normalizeProps<T>(props: Props<T>): NormalizedProps<T> {
  return {
    ...props,

    headers: props.headers ? omitNullable(props.headers) : [],
    fixedColumnWidths: props.fixedColumnWidths ? omitNullable(props.fixedColumnWidths) : undefined,
    isSticky: props.isSticky ?? false,
    isScrollable: props.isScrollable ?? false,
    isAlignedToTop: props.isAlignedToTop ?? false,
    withBorders: props.withBorders ?? false,
  };
}
