import { ReactNode } from 'react';
import cx from 'classnames';

export interface TabPanelProps {
  id: string;
  active?: boolean | ((id: string) => boolean);
  children: ReactNode;
}

export default function TabPanel({ id, active = false, children }: TabPanelProps) {
  const isActive = active instanceof Function ? active(id) : active;

  return (
    <div
      className={cx('tab-panels__panel', { '-is-active': isActive })}
      id={`panel-${id}`}
      role="tabpanel"
      aria-labelledby={id}
      aria-hidden={!isActive}
    >
      {children}
    </div>
  );
}
