import {
  AndDependencyT,
  ContainsDependencyT,
  DependencyT,
  EqDependencyT,
  NotDependencyT,
  OrDependencyT,
  PresentDependencyT,
  PrimitiveT,
} from '../schema';

type Values = Record<string, unknown>;

export function cleanFieldId(raw: string) {
  return raw.startsWith('$') ? raw.substring(1) : raw;
}

type DependencyCheckers = MapDependencyChecker<DependencyT, 'type', 'args'>;

export const DEPENDENCY_CHECKERS: DependencyCheckers = {
  not: (values, args) => {
    return values[cleanFieldId(args[0])] !== args[1];
  },

  eq: (values, args) => {
    return values[cleanFieldId(args[0])] === args[1];
  },

  present: (values, args) => {
    const value = values[cleanFieldId(args[0])];

    return value !== null && value !== undefined && value !== '';
  },

  contains: (values, args) => {
    const value = values[cleanFieldId(args[0])];

    return Array.isArray(value) ? value.includes(args[1]) : false;
  },

  or: (values, args) => {
    return args.some((dep) => dependencyMet(dep, values));
  },

  and: (values, args) => {
    return args.every((dep) => dependencyMet(dep, values));
  },
};

export function dependencyMet(dependency: DependencyT | null | undefined, values: Values) {
  if (!dependency) return true;

  const { type, args } = dependency;
  const checker = DEPENDENCY_CHECKERS[type];

  return checker(values, args as any);
}

export function not(fieldId: string, value: PrimitiveT) {
  return { type: 'not', args: [fieldId, value] } as NotDependencyT;
}

export function eq(fieldId: string, value: PrimitiveT) {
  return { type: 'eq', args: [fieldId, value] } as EqDependencyT;
}

export function present(fieldId: string) {
  return { type: 'present', args: [fieldId] } as PresentDependencyT;
}

export function contains(fieldId: string, value: PrimitiveT) {
  return { type: 'contains', args: [fieldId, value] } as ContainsDependencyT;
}

export function or(dep1: DependencyT, dep2: DependencyT) {
  return { type: 'or', args: [dep1, dep2] } as OrDependencyT;
}

export function and(dep1: DependencyT, dep2: DependencyT) {
  return { type: 'and', args: [dep1, dep2] } as AndDependencyT;
}

// Modified from https://stackoverflow.com/questions/50125893/typescript-derive-map-from-discriminated-union

type DiscriminateUnionDependencyChecker<T, K1 extends keyof T, K2 extends keyof T, V extends T[K1]> = T extends Record<
  K1,
  V
>
  ? (values: Values, args: T[K2]) => boolean
  : never;

type MapDependencyChecker<T extends Record<K1, string>, K1 extends keyof T, K2 extends keyof T> = {
  [Value in T[K1]]: DiscriminateUnionDependencyChecker<T, K1, K2, Value>;
};
