import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import DayPicker from 'react-day-picker';
import { useIntl } from 'react-intl';

import { isFutureDay, isPastDay, isSameDay, stringToDate, today } from 'utils/date';

import { months } from 'translatedResources/Date/Months';
import { weekDaysLong, weekDaysShort } from 'translatedResources/Date/WeekDays';

import { useWeekDayOffset, useWeekendDays } from 'hooks/useConfig';

import { ClearIndicator, DropdownIndicator } from 'components/ReactSelect';
import YearMonthPicker from 'components/YearMonthPicker';

import t from './translations';

type BaseProps<T> = {
  id?: string;
  value?: T;
  defaultValue?: T;
  onChange: (val: T) => void;
  onClose?: () => void;
  disabled?: boolean;
  notClearable?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableToday?: boolean;
  disableDate?: (day: Date) => boolean;
  enableMultiDates?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
};

export type DateProps = BaseProps<Date | null | undefined> | BaseProps<Date[] | null | undefined>;

function assureArray(value: any) {
  return Array.isArray(value) ? value : [value];
}

function useFormatValue() {
  const { formatDate } = useIntl();

  return useCallback(
    (days: Date[]) =>
      days.length
        ? days
            .sort((a, b) => a.getTime() - b.getTime())
            .map((day) => formatDate(day))
            .join(', ')
        : '',
    [formatDate]
  );
}

function useInput(): [string | undefined, (value: Date[] | string) => void] {
  const [input, setInput] = useState<string>('');
  const formatValue = useFormatValue();

  return [
    input,
    useCallback(
      (value: Date[] | string) => {
        setInput(Array.isArray(value) ? formatValue(value) : value);
      },
      [formatValue]
    ),
  ];
}

function useModifiers(disablePast, disableFuture, disableToday, disableDate) {
  const modifierPresets = useModifierPresets();
  const disabled: ((day: Date) => boolean)[] = [];

  if (disablePast) disabled.push(modifierPresets.isPast);
  if (disableFuture) disabled.push(modifierPresets.isFuture);
  if (disableToday) disabled.push(modifierPresets.isToday);
  if (disableDate) disabled.push(disableDate);

  return {
    disabled: (day) => disabled.some((fn) => fn(day)),
  };
}

export default function DateField({
  id,
  disabled,
  enableMultiDates = false,
  autoFocus = false,
  disablePast,
  disableFuture,
  disableToday,
  disableDate,
  notClearable,
  defaultValue,
  value,
  onChange: externalOnChange,
  placeholder,
}: DateProps) {
  const { formatMessage } = useIntl();

  const [input, setInput] = useInput();
  const [isOpen, setIsOpen] = useState<boolean>(autoFocus);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);

  const valueRef = useRef(value);
  const defaultValueRef = useRef(defaultValue);

  const modifiers = useModifiers(disablePast, disableFuture, disableToday, disableDate);
  const formatValue = useFormatValue();

  const isDisabled = (day: Date) => modifiers.disabled(day);

  const onChange = (days, close = true) => {
    const newValue: any = enableMultiDates ? days : days[0];

    externalOnChange(newValue);

    setInput(days);
    setSelectedDays(days);
    if (close) setIsOpen(false);
  };

  const close = () => {
    if (!isHovering) {
      setIsOpen(false);
    }
  };

  const onSelectDay = (day: Date, selected: Date[]) => {
    const days = [...selectedDays];

    if (enableMultiDates) {
      if (selected) {
        const i = days.findIndex((d) => isSameDay(d, day));
        days.splice(i, 1);
      } else {
        days.push(day);
      }

      onChange(days, false);
    } else {
      days.length = 0;
      days.push(day);

      onChange(days);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      const day = stringToDate(event.target.value);

      if (day && !isDisabled(day)) {
        onChange([day], false);
      } else {
        setInput(selectedDays);
      }
    }
  };

  const onFocus = () => setIsOpen(true);

  const onBlur = () => {
    setInput(selectedDays);
    close();
  };

  // Set initial value
  useEffect(() => {
    let days: Date[] = [];
    if (defaultValueRef.current) {
      days = assureArray(defaultValueRef.current);
    } else if (valueRef.current) {
      days = assureArray(valueRef.current);
    }

    setInput(days);
    setSelectedDays(days);
  }, [setInput]);

  // Update value
  useEffect(() => {
    const next = assureArray(value || []);
    const prev = assureArray(valueRef.current || []);

    const nextValue = formatValue(next);
    const prevValue = formatValue(prev);

    if (nextValue !== prevValue) {
      setInput(nextValue);
      setSelectedDays(next);

      valueRef.current = value;
    }
  }, [value, setInput, formatValue]);

  const currentMonth = selectedDays.length ? selectedDays[selectedDays.length - 1] : undefined;

  const formattedPlacholder =
    placeholder || formatMessage(!enableMultiDates ? t.placeholderSingle : t.placeholderMulti);

  return (
    <div
      className={cx('Select', 'Select__date-picker', 'form-select', {
        'Select__date-picker--menu-is-open': isOpen,
        'Select__multi': enableMultiDates,
      })}
    >
      {!enableMultiDates ? (
        <div className="Select__input-zone">
          <input
            autoComplete="off"
            disabled={disabled}
            id={id}
            type="text"
            value={input}
            placeholder={formattedPlacholder}
            readOnly={false}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={(event) => {
              setInput(event.target.value);
            }}
          />
        </div>
      ) : (
        <div className="date-input" tabIndex={0} onFocus={onFocus} onBlur={onBlur} role="textbox">
          {input || <span className="form-placeholder">{formattedPlacholder}</span>}
        </div>
      )}

      {!notClearable && !disabled && !!selectedDays.length && (
        <ClearIndicator
          onClick={() => {
            onChange([]);
          }}
        />
      )}

      {!disabled && <DropdownIndicator hasValue={selectedDays.length > 0} onClick={() => setIsOpen(!isOpen)} />}

      <DatePicker
        className={cx({
          'is-open': isOpen,
          'is-closed': !isOpen,
        })}
        modifiers={modifiers}
        selectedDays={selectedDays}
        currentMonth={currentMonth}
        isDisabled={isDisabled}
        // @ts-ignore
        onChange={onSelectDay}
        onClose={() => setIsOpen(false)}
        onKeyDown={(e) => setIsOpen(!(e.key === 'Tab'))}
        onBlur={close}
        containerProps={{
          onMouseOver: () => setIsHovering(true),
          onMouseOut: () => setIsHovering(false),
        }}
      />
    </div>
  );
}

type Props = {
  modifiers?: any;
  currentMonth?: Date;

  onChange: (day: Date, selected: boolean) => void;
  onClose: () => void;
  isDisabled: (day: Date) => boolean;
};

function DatePicker(props: Props) {
  const { modifiers, currentMonth, onChange, onClose, isDisabled } = props;

  const intl = useIntl();

  const [month, setMonth] = useState(currentMonth || new Date());

  const weekDayOffset = useWeekDayOffset();

  useEffect(() => {
    setMonth(currentMonth || new Date());
  }, [currentMonth]);

  const translatedWeekdaysShort = weekDaysShort(intl);
  const translatedWeekdaysLong = weekDaysLong(intl);
  const translatedMonths = months(intl);

  const localeUtils = {
    formatDay: (date) =>
      intl.formatDate(date, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    formatWeekdayShort: (index) => translatedWeekdaysShort[index],
    formatWeekdayLong: (index) => translatedWeekdaysLong[index],
    getFirstDayOfWeek: () => weekDayOffset,
    getMonths: () => translatedMonths,
    formatMonthTitle: (date) => `${translatedMonths[date.getMonth()]} ${date.getFullYear()}`,
  };

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 120, 0, 1, 0, 0);
  const toMonth = new Date(currentYear + 100, 11, 31, 23, 59);

  return (
    <DayPicker
      {...props}
      // @ts-ignore
      localeUtils={localeUtils}
      modifiers={modifiers}
      initialMonth={month}
      month={month}
      fromMonth={fromMonth}
      toMonth={toMonth}
      captionElement={
        <YearMonthPicker
          localeUtils={localeUtils}
          fromMonth={fromMonth}
          toMonth={toMonth}
          onChange={setMonth}
          onClose={onClose}
        />
      }
      onDayClick={(day, { selected }: any) => {
        if (!isDisabled(day)) {
          onChange(day, selected);
        }
      }}
    />
  );
}

interface ModifierPresets {
  isDayOfWeek: (weekday: number) => (day: Date) => boolean;
  isWeekend: (day: Date) => boolean;
  isPast: (day: Date) => boolean;
  isToday: (day: Date) => boolean;
  isDate: (date: Date) => (day: Date) => boolean;
  isFuture: (day: Date) => boolean;
}

function useModifierPresets(): ModifierPresets {
  const weekendDays: number[] = useWeekendDays();

  return {
    isDayOfWeek: (weekday) => (day) => day.getDay() === weekday,
    isWeekend: (day) => weekendDays.includes(day.getDay()),
    isPast: (day) => isPastDay(day),
    isToday: (day) => isSameDay(today(), day),
    isDate: (date) => (day) => isSameDay(date, day),
    isFuture: (day) => isFutureDay(day),
  };
}
