import { ReactNode } from 'react';
import cx from 'classnames';

interface Props {
  children?: ReactNode;
  className?: string;
  noScroll?: boolean;
}

export default function Main({ className, children, noScroll = false }: Props) {
  return <main className={cx(className, { '-no-scroll': noScroll })}>{children}</main>;
}
