// Set default locale for UI, this should either be a language code with country code or languague code.
// See https://github.com/unicode-org/cldr-json/tree/main/cldr-json/cldr-localenames-modern/main for reference.
// Examples: en, en-GB, nl-NL, nl-BE etc...
export const locale = import.meta.env.REACT_APP_LOCALE || 'en';

// Determine the format of manual date input standard: 'DMY'

let formatter: Intl.DateTimeFormat | undefined;

try {
  formatter = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
} catch (error) {
  formatter = undefined;
}

export const DATE_FORMAT_MANUAL_INPUT = (function dateFormatManualInput(parts) {
  const fallback = ['D', 'M', 'Y'];

  if (!parts) return fallback;

  const filteredParts = parts.filter(({ type }) => ['day', 'month', 'year'].includes(type));

  if (filteredParts.length !== 3) return fallback;

  return filteredParts.map(({ type }) => type.charAt(0).toUpperCase());
})(formatter?.formatToParts(new Date()));
