import { networkActionTypes } from 'store/utils';

import { patientSchema } from '../patients';

export const GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS = networkActionTypes('GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS');

export const getMedicinalTherapyPlanTypePatients = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_THERAPY_PLAN_TYPE_PATIENTS,
    url: `medications/therapy_plans/types/${id}/patients`,
    method: 'GET',
    normalizeSchema: { therapyPlanTypePatients: [patientSchema] },
  },
});
