import t from '../errorTranslations';
import { MinValueValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export function minValueValidator(validation: MinValueValidationT) {
  const minValue = validation.args[0];

  return {
    allowedTypes: ['number'],
    validator: (val: number) => val === undefined || val === null || val >= minValue,
    errorMessage: formatErrorMessage(validation),
  };
}

export function minValue(value: number, intlOrErrMsg: IntlOrErrMsg): MinValueValidationT {
  return {
    type: 'min_value',
    args: [value],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl, args }: MinValueValidationT) {
  const minValue = String(args[0]);

  if (errorMessage) return errorMessage.replace('#{min}', minValue);
  if (intl) return intl.formatMessage(t.minValue, { min: minValue });
  return '';
}
