import { useNavigate } from 'react-router-dom';

import useUniqueId from 'hooks/useUniqueId';

import Icon from 'components/Icon';

import PatientInput from 'sharedComponents/inputs/PatientInput';

export default function HeaderSearch() {
  const navigate = useNavigate();

  const [key, refreshKey] = useUniqueId('navbar_patient_search');

  const handleChange = (id: string | null) => {
    refreshKey();

    if (id) {
      navigate(`/record/patients/${id}/dashboard`);

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  return (
    <div className="app-header-search">
      <PatientInput key={key} name="app-header-search" onChange={handleChange} scope="enrolled" multiple={false} />
      <Icon>search</Icon>
    </div>
  );
}
