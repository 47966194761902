import { fromJS, Set } from 'immutable';
import { Action } from 'redux';

import { RootState } from 'store';
import {
  CLEAR_MEDICINAL_THERAPY_PLANS,
  CREATE_MEDICINAL_THERAPY_PLAN,
  CreateMedicinalTherapyPlanResponse,
  GET_MEDICINAL_THERAPY_PLAN,
  GET_MEDICINAL_THERAPY_PLAN_CALENDAR,
  GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS,
  GET_MEDICINAL_THERAPY_PLANS,
  GetMedicinalTherapyPlanCalendarResponse,
  GetMedicinalTherapyPlanLabResultsResponse,
  GetMedicinalTherapyPlanResponse,
  GetMedicinalTherapyPlansResponse,
  STOP_MEDICINAL_THERAPY_PLAN,
  StopMedicinalTherapyPlanResponse,
} from 'store/modules/entities/actions/medication/therapyPlans';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

import { TherapyPlanType } from 'views/record/settings/clinicalSettings/therapyPlanTypes/fetchers';

const THERAPY_PLANS = ['medicinalTherapyPlans'];
const THERAPY_PLANS_BY_ID = [...THERAPY_PLANS, 'byId'];
const THERAPY_PLAN_TYPES = ['medicinalTherapyPlanTypes'];
const THERAPY_PLANS_BY_PATIENT_ID = [...THERAPY_PLANS, 'byPatientId'];
const THERAPY_PLAN_CALENDARS = ['medicinalTherapyPlanCalendars'];
const THERAPY_PLAN_CALENDAR_DATES = ['medicinalTherapyPlanCalendarDates'];
const THERAPY_PLAN_CALENDAR_DATE_ENTRIES = ['medicinalTherapyPlanCalendarDateEntries'];
const LAB_RESULTS = ['labResults', 'results'];
const LAB_OBSERVATION_TYPES = ['labResults', 'observationTypes'];

export default function medicinalTherapyPlansReducer(state: RootState['entities'], action: Action<string>) {
  switch (action.type) {
    case GET_MEDICINAL_THERAPY_PLANS.SUCCESS: {
      const {
        payload: {
          response: {
            entities: { therapyPlans = {}, therapyPlanTypes = {} },
            result: { therapyPlans: therapyPlanIds },
          },
          patientId,
        },
      } = action as GetMedicinalTherapyPlansResponse;

      return state
        .mergeDeepIn(THERAPY_PLANS_BY_ID, fromJS(therapyPlans))
        .mergeDeepIn(THERAPY_PLAN_TYPES, fromJS(therapyPlanTypes))
        .setIn([...THERAPY_PLANS_BY_PATIENT_ID, patientId], Set(therapyPlanIds));
    }

    case GET_MEDICINAL_THERAPY_PLAN.SUCCESS: {
      const {
        payload: {
          response: {
            entities: { therapyPlans = {}, therapyPlanTypes = {} },
            result: { therapyPlan: therapyPlanId },
          },
          patientId,
        },
      } = action as GetMedicinalTherapyPlanResponse;

      return state
        .mergeDeepIn(THERAPY_PLANS_BY_ID, fromJS(therapyPlans))
        .mergeDeepIn(THERAPY_PLAN_TYPES, fromJS(therapyPlanTypes))
        .setIn(
          [...THERAPY_PLANS_BY_PATIENT_ID, patientId],
          Set(state.getIn([...THERAPY_PLANS_BY_PATIENT_ID, patientId])).add(therapyPlanId)
        );
    }

    case GET_MEDICINAL_THERAPY_PLAN_CALENDAR.SUCCESS: {
      const {
        payload: {
          response: {
            entities: { therapyPlanCalendars = {}, therapyPlanCalendarDates = {}, therapyPlanCalendarDateEntries = {} },
          },
        },
      } = action as GetMedicinalTherapyPlanCalendarResponse;

      return state
        .mergeIn(THERAPY_PLAN_CALENDARS, therapyPlanCalendars)
        .mergeIn(THERAPY_PLAN_CALENDAR_DATES, therapyPlanCalendarDates)
        .mergeIn(THERAPY_PLAN_CALENDAR_DATE_ENTRIES, therapyPlanCalendarDateEntries);
    }

    case GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS.SUCCESS: {
      const {
        payload: {
          response: {
            entities: { labResults = {}, observationTypes = {} },
            result: { results: labResultIds },
          },
          patientId,
          therapyPlanId,
        },
      } = action as GetMedicinalTherapyPlanLabResultsResponse;

      const patientObservationTypes = Object.keys(observationTypes);

      return state
        .mergeIn([...LAB_RESULTS, 'byId'], fromJS(labResults))
        .setIn([...LAB_RESULTS, 'byTherapyPlanPatientId', patientId], fromJS(labResultIds))
        .mergeIn([...LAB_OBSERVATION_TYPES, 'byId'], fromJS(observationTypes))
        .setIn([...LAB_OBSERVATION_TYPES, 'byTherapyPlanPatientId', patientId], fromJS(patientObservationTypes))
        .setIn([...LAB_OBSERVATION_TYPES, 'byTherapyPlanId', therapyPlanId], fromJS(patientObservationTypes));
    }

    case CREATE_MEDICINAL_THERAPY_PLAN.SUCCESS: {
      const {
        payload: {
          response: {
            entities: { therapyPlans = {} },
          },
        },
      } = action as CreateMedicinalTherapyPlanResponse;

      return state.mergeDeepIn(THERAPY_PLANS_BY_ID, fromJS(therapyPlans));
    }

    case STOP_MEDICINAL_THERAPY_PLAN.SUCCESS: {
      const {
        payload: { patientId, therapyPlanId },
      } = action as StopMedicinalTherapyPlanResponse;

      return state
        .deleteIn([...THERAPY_PLANS_BY_ID, therapyPlanId])
        .updateIn([...THERAPY_PLANS_BY_PATIENT_ID, patientId], (set: Set<string>) => set.remove(therapyPlanId));
    }

    case CLEAR_MEDICINAL_THERAPY_PLANS: {
      return state.setIn(THERAPY_PLANS_BY_ID, fromJS({})).setIn(THERAPY_PLANS_BY_PATIENT_ID, fromJS({}));
    }

    default: {
      return state;
    }
  }
}

export function medicinalTherapyPlansSelector(state: RootState, patientId?: string) {
  if (!patientId) return undefined;

  const plansById = state.entities.getIn(THERAPY_PLANS_BY_ID).toJS() as Record<
    string,
    Omit<TherapyPlanT, 'therapyPlanType'> & {
      therapyPlanType: string;
    }
  >;
  const typesById = state.entities.getIn(THERAPY_PLAN_TYPES)?.toJS() as Record<string, TherapyPlanType>;
  const byPatientId = state.entities.getIn([...THERAPY_PLANS_BY_PATIENT_ID, patientId])?.toJS() as string[] | undefined;

  if (!byPatientId) return undefined;

  return byPatientId.map((id) => {
    const therapyPlan = plansById[id];
    const therapyPlanType = typesById[therapyPlan.therapyPlanType];

    return { ...therapyPlan, therapyPlanType } as TherapyPlanT;
  });
}

export function medicinalTherapyPlanSelector(state: RootState, therapyPlanId: string) {
  const therapyPlan = state.entities.getIn([...THERAPY_PLANS_BY_ID, therapyPlanId])?.toJS() as
    | (Omit<TherapyPlanT, 'therapyPlanType'> & {
        therapyPlanType: string;
      })
    | undefined;

  if (!therapyPlan) return undefined;

  const therapyPlanType = state.entities.getIn([...THERAPY_PLAN_TYPES, therapyPlan.therapyPlanType])?.toJS() as
    | TherapyPlanType
    | undefined;

  if (!therapyPlanType) return undefined;

  return { ...therapyPlan, therapyPlanType } as TherapyPlanT;
}

export function medicinalTherapyPlanCalendarDateSelector(state: RootState, therapyPlanId: string, date: Date) {
  const dateString = date.toISOString().slice(0, 10);

  const calendarDate = state.entities
    .getIn([...THERAPY_PLAN_CALENDAR_DATES, `${therapyPlanId}-${dateString}`])
    ?.toJS() as (Omit<TherapyPlanCalendarDateT, 'entries'> & { entries: string[] }) | undefined;

  if (!calendarDate) return undefined;

  const entries = state.entities
    .getIn(THERAPY_PLAN_CALENDAR_DATE_ENTRIES)
    .filter((_: any, key: string) => calendarDate.entries.includes(key))
    .toJS() as Record<string, TherapyPlanCalendarDateEntryT>;

  return {
    ...calendarDate,
    entries: calendarDate.entries.map((id) => entries[id]),
  } as TherapyPlanCalendarDateT;
}

export function medicinalTherapyPlanLabResultsSelector(state: RootState, patientId: string) {
  const isFetching =
    !!state.network.GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS ||
    state.network.GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS === undefined;

  if (isFetching) return undefined;

  const labResultIds = state.entities.getIn([...LAB_RESULTS, 'byTherapyPlanPatientId', patientId])?.toJS() as
    | string[]
    | undefined;

  if (!labResultIds) return [];

  const labResults = state.entities
    .getIn([...LAB_RESULTS, 'byId'])
    .filter((_: any, key: string) => labResultIds.includes(key))
    .toJS() as Record<string, LabResultT>;

  return deindex(labResults).sort(sortInline('effectiveDateTime', 'desc'));
}

export function medicinalTherapyPlanLabObservationTypesSelector(
  state: RootState,
  patientId: string,
  therapyPlanId: string
) {
  const byId = state.entities.getIn([...LAB_OBSERVATION_TYPES, 'byId']).toJS() as Record<string, LabObservationTypeT>;

  const byPatientId = state.entities.getIn([...LAB_OBSERVATION_TYPES, 'byTherapyPlanPatientId', patientId])?.toJS() as
    | string[]
    | undefined;

  const byTherapyPlanId = state.entities.getIn([...LAB_OBSERVATION_TYPES, 'byTherapyPlanId', therapyPlanId])?.toJS() as
    | string[]
    | undefined;

  if (!byPatientId || !byTherapyPlanId) return undefined;

  return byPatientId
    .filter((id) => byTherapyPlanId.includes(id))
    .map((id) => byId[id])
    .filter(Boolean);
}
