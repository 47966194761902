import fullName from 'utils/fullName';

export interface PatientCompact {
  id: string;
  title: string;
  fullName: string;
  avatarThumbUrl: string;
  birthdate?: Date | null;
}

export function selectPatientOptions(
  patients: {
    id: string;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    sex: SexT;
    birthdate: Date | null;
    avatarUrl: string;
    avatarThumbUrl: string;
  }[]
) {
  return patients.map<PatientOptionT>((patient) => ({
    label: fullName(patient),
    value: patient.id,
    avatarUrl: patient.avatarUrl,
    avatarThumbUrl: patient.avatarThumbUrl,
    birthdate: patient.birthdate,
    sex: patient.sex,
  }));
}
