import { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import cx from 'classnames';

import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Popover, { PopoverProps } from './Popover';

interface Props {
  content: ReactNode;
  children: ReactElement;
  title?: string;
  placement?: PopoverProps['placement'];
  actuatorClassName?: PopoverProps['actuatorClassName'];
  floatingClassName?: PopoverProps['floatingClassname'];
  floatingContentClassName?: PopoverProps['floatingContentClassName'];
  floatingOffset?: PopoverProps['floatingOffset'];
  containsPill?: boolean;
  onChange?: (isOpen: boolean) => void;
  disabled?: boolean;
}

export type TooltipProps = Props;

const ANIMATION_DURATION = 50;

export default function Tooltip({
  content,
  children,
  title,
  placement,
  actuatorClassName,
  floatingClassName,
  floatingContentClassName,
  floatingOffset = 15,
  containsPill = false,
  disabled = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const isVisible = useDelayedAnimation(isOpen, ANIMATION_DURATION);

  const onMouseEnter = () => {
    setIsOpen(true);
  };

  const onMouseLeave = () => {
    setIsOpen(false);
  };

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Popover
      isOpen={!disabled ? isVisible : false}
      floatingContentProps={!disabled ? { onMouseEnter, onMouseLeave } : {}}
      actuatorProps={!disabled ? { onMouseEnter, onMouseLeave, onClick } : {}}
      placement={placement}
      actuatorClassName={cx('-is-tooltip-actuator', actuatorClassName, { '-is-pill-actuator': containsPill })}
      floatingClassname={cx('-is-tooltip-floating', floatingClassName)}
      floatingContentClassName={cx('tooltip', floatingContentClassName)}
      floatingOffset={floatingOffset}
      content={
        <>
          {title && <h5 className="tooltip__title">{title}</h5>}
          <div className="tooltip__content">{content}</div>
        </>
      }
    >
      {children}
    </Popover>
  );
}
