type Props = {
  id: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (value?: string) => void;
  autoFocus: boolean;
  placeholder?: string;
};

export default function Email({ id, defaultValue, disabled = false, onChange, autoFocus, placeholder }: Props) {
  return (
    <input
      id={id}
      type="email"
      placeholder={placeholder}
      disabled={disabled}
      defaultValue={defaultValue}
      onBlur={(event) => onChange(event.target.value.trim() || undefined)}
      autoFocus={autoFocus}
    />
  );
}
