import { Children, cloneElement, isValidElement, ReactNode, useRef } from 'react';
import cx from 'classnames';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import { cancelActionBarActivity } from 'store/modules/activity';

import { isMessageDescriptor } from 'utils/intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ShouldTrigger, useShouldTrigger } from 'hooks/useTrigger';

import ActionButton from 'components/ActionButton';
import Loading from 'components/Loading';

const t = defineMessages({
  closeTitle: { id: 'action_bar_close_title', defaultMessage: 'Close' },
  submitTitle: { id: 'action_bar_submit_title', defaultMessage: 'Submit' },
});

type Props = {
  children: ReactNode;
  contentClassName?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  title: MessageDescriptor | string;
  usedInPopover?: boolean;
};

export type ShouldSubmit = ShouldTrigger;

export default function ActionBar({
  children,
  contentClassName,
  isDisabled,
  isLoading: externalIsLoading,
  title: rawTitle,
  usedInPopover = false,
}: Props) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [shouldSubmit, submit] = useShouldTrigger();

  const dismiss = () => dispatch(cancelActionBarActivity());

  const clonedChildren = Children.map(children, (child) => {
    if (
      isValidElement(child) &&
      (child.props.submit instanceof Function /* Form, FormV2 */ ||
        child.props.onSubmit instanceof Function) /* FormV3 */
    ) {
      return cloneElement(child, { shouldSubmit });
    }

    return child;
  });

  const isLoading = useAppSelector((state) => {
    const { network, activity } = state;

    let networkKeyCheck = activity?.name;

    if (activity?.name?.startsWith('EDIT_')) {
      networkKeyCheck = activity?.name.replace('EDIT_', 'UPDATE_');
    }
    if (activity?.name?.startsWith('ADD_')) {
      networkKeyCheck = activity?.name.replace('ADD_', 'CREATE_');
    }

    const networkKey = Object.keys(network).find((n) => n === networkKeyCheck);

    return networkKey ? network[networkKey] : false;
  });

  const ref = useRef<HTMLButtonElement>(null);
  const focusSubmit = () => {
    if (ref.current) ref.current.focus();
  };

  const title = isMessageDescriptor(rawTitle) ? formatMessage(rawTitle) : rawTitle;

  const renderContent = () => (
    <>
      <div className="actionbar-header">
        <div className="actionbar-header-left">
          <ActionButton icon="close" title={t.closeTitle} onClick={dismiss} />
        </div>

        <h6>{title}</h6>

        <div className="actionbar-header-right">
          {externalIsLoading || isLoading ? (
            <Loading type="small" />
          ) : (
            <ActionButton
              ref={ref}
              color="info"
              icon="check"
              title={t.submitTitle}
              disabled={isDisabled}
              onClick={submit}
            />
          )}
        </div>
      </div>

      <div className={cx('actionbar-content', contentClassName)}>
        {clonedChildren}
        <div tabIndex={0} style={{ width: 0, height: 0, overflow: 'hidden' }} onFocus={focusSubmit} role="button" />
      </div>
    </>
  );

  if (usedInPopover) {
    return <div className="activity-popover-actionbar">{renderContent()}</div>;
  }

  return renderContent();
}
