import { useRef } from 'react';
import range from 'lodash/range';
import { defineMessages } from 'react-intl';

import ActionButton from 'components/ActionButton';

const t = defineMessages({
  closeButton: {
    id: 'year_month_picker_close_button',
    defaultMessage: 'Close',
  },
});

type Props = {
  onChange: (val: Date) => void;
  onClose: () => void;
  fromMonth: any;
  toMonth: any;
  date?: Date;
  localeUtils: any;
};

export default function YearMonthPicker({ date, localeUtils, fromMonth, toMonth, onChange, onClose }: Props) {
  const months = localeUtils.getMonths();
  const years = range(fromMonth.getFullYear(), toMonth.getFullYear());

  const monthRef = useRef<HTMLSelectElement>(null);
  const yearRef = useRef<HTMLSelectElement>(null);

  const handleChange = () => {
    if (monthRef.current && yearRef.current) {
      const month = parseInt(monthRef.current.value, 10);
      const year = parseInt(yearRef.current.value, 10);

      onChange(new Date(year, month));
    }
  };

  return (
    <div className="DayPicker-Caption">
      <select
        ref={monthRef}
        name="month"
        onChange={handleChange}
        value={date ? date.getMonth() : new Date().getMonth()}
      >
        {months.map((month: string, i: number) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        ref={yearRef}
        name="year"
        onChange={handleChange}
        value={date ? date.getFullYear() : new Date().getFullYear()}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <ActionButton
        title={t.closeButton}
        icon="expand_less"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      />
    </div>
  );
}
