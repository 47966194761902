import cx from 'classnames';

type Props = {
  innerRef?: any;
  innerProps?: any;
  style?: any;
  onClick: () => void;
  hasValue?: boolean;
};

const DropdownIndicator = (props: Props) => {
  const { style, onClick, hasValue, innerProps, innerRef } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      role="presentation"
      style={style}
      className="Select__dropdown-indicator-container"
      onClick={onClick}
    >
      <span
        className={cx('Select__dropdown-indicator', {
          'Select__dropdown-indicator--active': hasValue,
        })}
      />
    </div>
  );
};

export default DropdownIndicator;
