interface NamedPerson {
  fullName?: null | string;
  lastName?: null | string;
  firstName?: null | string;
  title?: null | string;
}

export default function fullName<P extends NamedPerson>(person?: P | null | undefined, withTitle = false): string {
  if (!person) return '';

  const { fullName, lastName, firstName, title } = person;

  if (fullName) return fullName;

  return [withTitle ? title : '', lastName, firstName].filter((str) => str && str.length > 0).join(' ');
}
