import { BaseEditor, Editor, Element, Node, Path, Range, Text, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { Rte } from './domain';
import { slateHelpers } from './slateHelpers';

export function withVariables<T extends BaseEditor & ReactEditor & HistoryEditor>(_editor: T) {
  const editor = _editor as any as Rte.Editor;
  const { isVoid, isInline, insertData } = editor;

  editor.variables = {};

  editor.isVoid = (element: Element) => {
    return isVoid(element) || element.type === 'variable';
  };

  editor.isInline = (element: Element) => {
    return isInline(element) || element.type === 'variable';
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    const variables = Object.keys(editor.variables);
    const matches = [...text.matchAll(/{{([a-z0-9]+(?:\.[a-z0-9]+)*)}}/g)].filter((match) =>
      variables.includes(match[1])
    );

    if (!matches.length) {
      insertData(data);
      return;
    }

    const nodes: Node[] = [];

    for (let i = 0; i < matches.length + 1; i++) {
      const prevMatch = matches[i - 1];
      const nextMatch = matches[i];

      const prevEnd = (prevMatch?.index ?? 0) + (prevMatch?.[0].length ?? 0);
      const nextStart = nextMatch?.index ?? text.length;

      nodes.push({
        text: text.slice(prevEnd, nextStart),
      });

      if (nextMatch) {
        nodes.push(slateHelpers.createVariable(nextMatch[1]));
      }
    }

    editor.insertFragment(nodes);
  };

  editor.insertVariable = (variable: string, range?: Range) => {
    const [match] = Editor.nodes<Rte.Text>(editor, {
      at: range,
      match: (node) => Text.isText(node),
    });
    const originNode = match?.[0] as Rte.Text | undefined;

    if (range) {
      Transforms.select(editor, range);
    } else {
      if (editor.hasSelection()) {
        Transforms.deselect(editor);
      }
    }

    const node = slateHelpers.createVariable(variable);

    if (originNode?.bold) node.bold = true;
    if (originNode?.italic) node.italic = true;
    if (originNode?.underline) node.underline = true;
    if (originNode?.style) node.style = { ...originNode.style };

    Transforms.insertNodes(editor, node);
    Transforms.move(editor);
    ReactEditor.focus(editor);
  };

  editor.completeVariable = () => {
    const match = editor.isCursorInTextNode();

    if (match) {
      const [node, path] = match;
      const { text } = node;

      const variables = Object.keys(editor.variables);
      const regMatch = text.match(/{{([a-z0-9]+(?:\.[a-z0-9]+)*)}}/);

      if (regMatch && variables.includes(regMatch[1])) {
        const start = regMatch.index ?? 0;
        const end = start + regMatch[0].length;

        const variable = regMatch[1];
        const range: Range = {
          anchor: { path, offset: start },
          focus: { path, offset: end },
        };

        editor.insertVariable(variable, range);
      }
    }
  };

  editor.isCursorInTextNode = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes<Rte.Text>(editor, {
        match: (node, path) => Text.isText(node) && Path.equals(path, selection.anchor.path),
      });

      return match;
    }
  };

  editor.isCursorInPartialVariable = () => {
    const { selection } = editor;
    const match = editor.isCursorInTextNode();

    if (match) {
      const [node, path] = match;
      const { text } = node;

      const cursor = selection?.focus.offset ?? 0;
      const regMatch = text.match(/{{([a-z0-9]+(?:\.[a-z0-9]*)*)?/);

      if (regMatch) {
        const start = regMatch.index ?? 0;
        const end = start + regMatch[0].length;

        if (start <= cursor && end >= cursor) {
          const range: Range = {
            anchor: { path, offset: start },
            focus: { path, offset: end },
          };

          const partialVar = regMatch[1];

          return [range, partialVar];
        }
      }
    }
  };

  return editor;
}
