import { IntlShape } from 'react-intl';

import t from 'components/Form/errorTranslations';

type DescriptionObj<T> = { value: T; errorMessage: string };
type Description<T> = T | DescriptionObj<T>;

export interface Validation {
  required?: Description<boolean>;
  minValue?: Description<number>;
  maxValue?: Description<number>;
  minLength?: Description<number>;
  maxLength?: Description<number>;
  format?: Description<RegExp>;
  contentType?: Description<ContentType>;
}

type ContentType = string[] | 'image' | 'document' | 'attachment' | 'reportTemplate';

export { validate, isDescriptionObject };

function validate(validation: Validation | undefined, intl: IntlShape, value: any) {
  if (!validation) {
    return undefined;
  }

  for (const entry of Object.entries(validation)) {
    const key = entry[0] as keyof Validation;
    const desc = entry[1] as Description<any>;

    const [arg, message] = parseDescription(desc);

    switch (key) {
      case 'required': {
        const error = validateRequired(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'minLength': {
        const error = validateMinLength(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'maxLength': {
        const error = validateMaxLength(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'minValue': {
        const error = validateMinValue(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'maxValue': {
        const error = validateMaxValue(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'format': {
        const error = validateFormat(arg, { value, message, intl });
        if (error) return error;
        break;
      }

      case 'contentType': {
        let types = arg as ContentType;

        const reportTemplates = ['.tlf'];
        const images = ['.png', '.jpg', '.jpeg', '.gif', '.tiff', '.bmp'];
        const documents = [
          '.txt',
          '.csv',
          '.pdf',
          '.doc',
          '.docx',
          '.xls',
          '.xlsx',
          '.ppt',
          '.pptx',
          '.odp',
          '.ods',
          '.odt',
        ];

        if (types === 'image') types = images;
        if (types === 'document') types = documents;
        if (types === 'reportTemplate') types = reportTemplates;
        if (types === 'attachment') types = [...images, ...documents];

        const error = validateContentType(types, { value, message, intl });

        if (error) return error;
        break;
      }

      default:
        break;
    }
  }
}

interface ValidatorContext {
  value: unknown;
  message: string | undefined;
  intl: IntlShape;
}

function validateRequired(required: boolean, { value, message, intl }: ValidatorContext) {
  if (
    required &&
    (value === null || value === undefined || ((isArray(value) || isString(value)) && value.length === 0))
  ) {
    return message || intl.formatMessage(t.required);
  }
}

function validateMinLength(min: number, { value, message, intl }: ValidatorContext) {
  if (isString(value) || isArray(value)) {
    if (value.length < min) {
      return message || intl.formatMessage(t.minLength, { min });
    }
  }
}

function validateMaxLength(max: number, { value, message, intl }: ValidatorContext) {
  if (isString(value) || isArray(value)) {
    if (value.length > max) {
      return message || intl.formatMessage(t.maxLength, { max });
    }
  }
}

function validateMinValue(min: number, { value, message, intl }: ValidatorContext) {
  if (isNumber(value)) {
    if (value < min) {
      return message || intl.formatMessage(t.minValue, { min });
    }
  }
}

function validateMaxValue(max: number, { value, message, intl }: ValidatorContext) {
  if (isNumber(value)) {
    if (value > max) {
      return message || intl.formatMessage(t.maxValue, { max });
    }
  }
}

function validateFormat(regex: RegExp, { value, message, intl }: ValidatorContext) {
  if (isString(value) || isNumber(value)) {
    if ((String(value).match(regex) || []).length <= 0) {
      return message || intl.formatMessage(t.format, { regex: String(regex) });
    }
  }
}

function validateContentType(types: string[], { value, message, intl }: ValidatorContext) {
  const typeRegexes = {
    tlf: /^$/,
    png: /^image\/png$/,
    jpg: /^image\/jpe?g$/,
    jpeg: /^image\/jpe?g$/,
    gif: /^image\/gif$/,
    tif: /^image\/tiff$/,
    tiff: /^image\/tiff$/,
    bmp: /^image\/bmp$/,
    txt: /^text\/plain$/,
    csv: /^text\/csv$/,
    pdf: /^application\/pdf$/,
    doc: /^application\/msword$/,
    docx: /^application\/vnd.openxmlformats-officedocument.wordprocessingml.document$/,
    xls: /^application\/vnd.ms-excel$/,
    xlsx: /^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet$/,
    ppt: /^application\/vnd.ms-powerpoint$/,
    pptx: /^application\/vnd.openxmlformats-officedocument.presentationml.presentation$/,
    odp: /^application\/vnd.oasis.opendocument.presentation$/,
    ods: /^application\/vnd.oasis.opendocument.spreadsheet$/,
    odt: /^application\/vnd.oasis.opendocument.text$/,
  };

  function getFileError(file: File) {
    const path = file.name.split('.');
    const ext = path[path.length - 1];
    const nameRegex = new RegExp(`(${types.join('|')})$`);

    if (!nameRegex.test(file.name) || !typeRegexes[ext]?.test(file.type)) {
      return message || intl.formatMessage(t.contentType, { types: types.join(', ') });
    }
  }

  if (isFile(value)) {
    return getFileError(value);
  }

  if (isArray(value)) {
    return value.reduce<string | undefined>((error, file) => error || getFileError(file as File), undefined);
  }
}

function parseDescription(arg: Description<any>) {
  if (isDescriptionObject(arg)) {
    return [arg.value, arg.errorMessage] as const;
  } else {
    return [arg, undefined] as const;
  }
}

function isDescriptionObject<T>(arg: Description<T>): arg is DescriptionObj<T> {
  return typeof arg === 'object' && 'value' in arg && 'errorMessage' in arg;
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

function isFile(value: unknown): value is File {
  return value instanceof File;
}
