import { ForwardedRef, forwardRef } from 'react';
import cx from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import Icon from 'components/Icon';

type CustomProps = {
  icon: string;
  title: MessageDescriptor | string;
  disabled?: boolean;
  color?: AppColor;
  size?: 'mini';
  remove?: boolean;
  spinning?: boolean;
  executing?: boolean;
};

type HtmlProps = Omit<JSX.IntrinsicElements['button'], keyof CustomProps>;

export type Props = CustomProps & HtmlProps;

function ActionButtonImplementation({
  forwardedRef,

  icon,
  title: rawTitle,
  disabled = false,
  color,
  size,
  remove,
  spinning,
  executing = false,

  className,
  ...buttonProps
}: Props & { forwardedRef: ForwardedRef<HTMLButtonElement> }) {
  const { formatMessage } = useIntl();

  const title = isMessageDescriptor(rawTitle) ? formatMessage(rawTitle) : rawTitle;

  return (
    <button
      ref={forwardedRef}
      {...buttonProps}
      title={title}
      type="button"
      className={cx(
        'action',
        `-color-${color || 'none'}`,
        `-size-${size || 'normal'}`,
        {
          '-is-remove': remove,
          '-is-spinning': spinning || executing,
        },
        className
      )}
      disabled={disabled}
    >
      <Icon>{!executing ? icon : 'autorenew'}</Icon>
    </button>
  );
}

const ActionButton = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <ActionButtonImplementation {...props} forwardedRef={ref} />
));
ActionButton.displayName = 'ActionButton';

export default ActionButton;
