import { ReactNode } from 'react';
import cx from 'classnames';

interface AffixProps {
  prefix?: boolean;
  suffix?: boolean;
  children: ReactNode;
}

export default function Affix({ prefix, suffix, children }: AffixProps) {
  return (
    <div
      className={cx('affix', {
        '-prefix': prefix,
        '-suffix': suffix,
      })}
    >
      {children}
    </div>
  );
}
