import { FormattedMessage } from 'react-intl';

import { useStrongPasswordPolicy } from 'hooks/useConfig';

import { ShouldSubmit } from 'components/ActionBar';
import Flash from 'components/Flash';
import Form, { PasswordField } from 'components/FormV3';
import Section from 'components/Section';

import t from './translations';

interface Props {
  requiresOldPassword?: boolean;
  submit: (arg0: any) => void;
  shouldSubmit?: ShouldSubmit;
}

export default function UpdatePasswordForm({ requiresOldPassword = false, submit, shouldSubmit }: Props) {
  const strongPasswordPolicy = useStrongPasswordPolicy();

  return (
    <>
      {strongPasswordPolicy ? (
        <Section withoutPadding>
          <Flash type="info" close={false}>
            <FormattedMessage {...t.passwordInfo} />
          </Flash>
        </Section>
      ) : null}

      <Form onSubmit={submit} shouldSubmit={shouldSubmit} initialValues={initialValues}>
        <Section>
          {requiresOldPassword ? (
            <PasswordField
              name="oldPassword"
              label={t.oldPasswordLabel}
              placeholder={t.oldPasswordPlaceholder}
              validation={{ required: true }}
            />
          ) : null}

          <PasswordField
            name="password"
            label={t.passwordLabel}
            placeholder={t.passwordPlaceholder}
            validation={{ required: true }}
          />

          <PasswordField
            name="passwordConfirmation"
            label={t.passwordConfirmationLabel}
            placeholder={t.passwordConfirmationPlaceholder}
            validation={{ required: true }}
          />
        </Section>
      </Form>
    </>
  );
}

const initialValues = {};
