type Props = {
  id: string;
  onChange: (value?: string) => void;
  autoFocus: boolean;
  placeholder?: string;
};

export default function Password({ id, onChange, autoFocus, placeholder }: Props) {
  return (
    <input
      type="password"
      placeholder={placeholder}
      id={id}
      onBlur={(event) => onChange(event.target.value.trim() || undefined)}
      autoFocus={autoFocus}
    />
  );
}
