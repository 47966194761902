import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { fetchCenters } from 'api/endpoints/centers';
import { fetchPersonTypes } from 'api/endpoints/personTypes';
import { fetchUser } from 'api/endpoints/users/$userId';
import { fetchRoles } from 'api/endpoints/users/roles';

import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

import titleOptions from 'translatedResources/titles';
import { userPermissionOptions } from 'translatedResources/userPermission';

import {
  useAuthenticationStrategy,
  useMulticenterAccessRights,
  usePasswordEnforcingAuthentication,
  useStrongPasswordPolicy,
  useUserAccessType,
} from 'hooks/useConfig';

import { useCurrentUser } from 'providers';

import { ShouldSubmit } from 'components/ActionBar';
import Flash from 'components/Flash';
import Form from 'components/Form';
import { and, eq, not, or } from 'components/Form/utils/dependency';
import { imageContentTypes, required } from 'components/Form/validators';
import Loading from 'components/Loading';
import Section from 'components/Section';

import { fetchIdentifierTypeAssignments } from 'views/admin/iam/identifiers/fetchers';

import t from './translations';

const transformValues = (values: any) => {
  const otherValues = Object.keys(values)
    .filter((key) => !key.startsWith('identifier') && !key.startsWith('allCenters'))
    .reduce((acc, key) => ({ ...acc, [key]: values[key] }), {});

  const identifiers = Object.keys(values)
    .filter((key) => key.startsWith('identifier'))
    .map((key) => ({
      value: values[key] || null,
      identifierTypeId: key.split('_')[1],
    }));

  return { ...otherValues, identifiers };
};

interface Props {
  userId?: string;
  update?: boolean;
  basicMode?: boolean;
  submit: (arg0: any) => void;
  shouldSubmit?: ShouldSubmit;
}

export default function UserForm({ userId, update = false, basicMode = false, submit, shouldSubmit }: Props) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { isLoading: userIsLoading, data: user } = useQuery(['user', userId], () => fetchUser(userId!), {
    enabled: !!userId,
    cacheTime: 0,
  });

  const { data: { centers = [] } = {} } = useQuery(['centers'], () => fetchCenters());

  const centerOptions = centers.map(labelize());

  const { data: roles = [] } = useQuery(['roles'], () => fetchRoles(), {
    enabled: !basicMode,
  });

  const roleOptions = roles.map(labelize()).sort(sortInline('label'));

  const { isLoading: personTypesIsLoading, data: personTypes } = useQuery(['personTypes'], () => fetchPersonTypes());

  const { isLoading: identifierTypeIsLoading, data: identifierTypeAssignments } = useQuery(
    ['identifierTypeAssignments'],
    () => fetchIdentifierTypeAssignments()
  );

  const sortedIdentifierTypeAssignments =
    identifierTypeAssignments?.sort(sortInline(['primary', 'name'], ['desc', 'asc'])) || [];

  const allCenterOption = {
    label: formatMessage(t.allCenterLabel),
    value: 'all',
  };

  const showMulticenter = useMulticenterAccessRights();
  const defaultUserPermission = useUserAccessType();

  const authenticationStrategy = useAuthenticationStrategy();

  const protectLDAPAttributes = update && authenticationStrategy === 'ldap';

  const strongPasswordPolicy = useStrongPasswordPolicy();
  const passwordEnforcingAuthentication = usePasswordEnforcingAuthentication();

  const enforcePassword = !update && passwordEnforcingAuthentication;

  const isFetching = userIsLoading || personTypesIsLoading || identifierTypeIsLoading;

  const currentUser = useCurrentUser();

  const professionOptions =
    personTypes
      ?.sort(sortInline('name'))
      .filter((type) => type.isProfession)
      .map((personType) => ({
        value: personType.key,
        label: personType.name,
        personTypeId: personType.id,
      })) || [];

  const configForIdentifier = ({ identifierTypeId, identifierTypeName, required: isRequired, personTypeId }) => {
    const profession = professionOptions.find((option) => option.personTypeId === personTypeId);

    if (!profession) return false;

    const shouldShow = and(not('profession', undefined), eq('profession', profession.value));

    return {
      id: `identifier_${identifierTypeId}`,
      type: 'string',
      title: identifierTypeName,
      placeholder: formatMessage(t.identifierPlaceholder),
      validations: isRequired ? [required(intl)] : undefined,
      dependency: shouldShow,
    };
  };

  const config = {
    sections: [
      {
        id: 'profile',
        title: formatMessage(t.profileSection),
        fields: [
          {
            id: 'avatar',
            type: 'avatar',
            title: formatMessage(t.avatarLabel),
            validations: [imageContentTypes(intl)],
            fileDisplayValue: user?.avatarUrl,
          },
          {
            id: 'title',
            type: 'list',
            title: formatMessage(t.titleLabel),
            placeholder: formatMessage(t.titlePlaceholder),
            options: titleOptions(intl),
          },
          {
            id: 'firstName',
            type: 'string',
            title: formatMessage(t.firstNameLabel),
            placeholder: formatMessage(t.firstNamePlaceholder),
            validations: [required(intl)],
          },
          {
            id: 'lastName',
            type: 'string',
            title: formatMessage(t.lastNameLabel),
            placeholder: formatMessage(t.lastNamePlaceholder),
            validations: [required(intl)],
          },
          !basicMode && {
            id: 'roleId',
            type: 'list',
            title: formatMessage(t.rolesLabel),
            placeholder: formatMessage(t.rolesPlaceholder),
            validations: [required(intl)],
            options: roleOptions,
          },
          {
            id: 'profession',
            type: 'list',
            title: formatMessage(t.professionLabel),
            placeholder: formatMessage(t.professionPlaceholder),
            validations: [required(intl)],
          },
        ].filter(Boolean),
      },
      {
        id: 'multicenter',
        title: formatMessage(t.multicenterSection),
        fields: [
          showMulticenter && {
            id: 'permission',
            type: 'list',
            title: formatMessage(t.userPermission),
            validations: [required(intl)],
            options: userPermissionOptions(intl),
          },
          showMulticenter && {
            id: 'centerIds',
            type: 'multiList',
            title: formatMessage(t.assignedCenters),
            placeholder: formatMessage(t.assignedCentersPlaceholder),
            validations: [required(intl)],
            options: centerOptions,
            dependency: and(not('permission', undefined), eq('permission', 'center_based')),
          },
          showMulticenter && {
            id: 'allCenters',
            type: 'list',
            title: formatMessage(t.assignedCenters),
            validations: [required(intl)],
            options: [allCenterOption],
            dependency: and(
              not('permission', undefined),
              or(eq('permission', 'integration'), eq('permission', 'global'))
            ),
          },
        ].filter(Boolean),
      },
      {
        id: 'identifiers',
        title: formatMessage(t.identifiersSection),
        fields: sortedIdentifierTypeAssignments.map(configForIdentifier).filter(Boolean),
      },
      {
        id: 'account',
        title: formatMessage(t.accountSection),
        fields: [
          {
            id: 'email',
            type: 'email',
            title: formatMessage(t.emailLabel),
            placeholder: formatMessage(t.emailPlaceholder),
            validations: [required(intl)],
            disabled: protectLDAPAttributes,
          },
          {
            id: 'username',
            type: 'string',
            title: formatMessage(t.usernameLabel),
            placeholder: formatMessage(t.usernamePlaceholder),
            validations: [required(intl)],
            disabled: protectLDAPAttributes || basicMode,
          },
          enforcePassword && {
            id: 'password',
            type: 'password',
            title: formatMessage(t.passwordLabel),
            placeholder: formatMessage(t.passwordPlaceholder),
            validations: [required(intl)],
          },
          enforcePassword && {
            id: 'passwordConfirmation',
            type: 'password',
            title: formatMessage(t.passwordConfirmationLabel),
            placeholder: formatMessage(t.passwordConfirmationPlaceholder),
            validations: [required(intl)],
          },
        ].filter(Boolean),
      },
    ],
  };

  if (isFetching) {
    return <Loading type="static" />;
  }

  const defaultValues: any = {
    ...(showMulticenter && {
      permission: defaultUserPermission,
      centerIds: currentUser.centerIds,
    }),
    ...user,
    ...user?.identifiers?.reduce((acc, { identifierTypeAssignmentId, value }) => {
      const { identifierTypeId } =
        sortedIdentifierTypeAssignments.find((assignment) => assignment.id === identifierTypeAssignmentId) || {};

      if (!identifierTypeId) return acc;

      return { ...acc, [`identifier_${identifierTypeId}`]: value };
    }, {}),
  };

  return (
    <>
      {strongPasswordPolicy && enforcePassword && (
        <Section withoutPadding>
          <Flash type="info" close={false}>
            {formatMessage(t.passwordInfo)}
          </Flash>
        </Section>
      )}

      <Form
        config={config}
        dynamicProps={{ profession: { options: professionOptions } }}
        defaultValues={defaultValues}
        submit={(values) => submit(transformValues(values))}
        shouldSubmit={shouldSubmit}
      />
    </>
  );
}
