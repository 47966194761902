import { defineMessages, IntlShape } from 'react-intl';

const t = defineMessages({
  doctorAbbrev: {
    id: 'person_title_doctor_abbrev',
    defaultMessage: 'Dr.',
  },
  maleAbbrev: {
    id: 'person_title_male_abbrev',
    defaultMessage: 'Mr.',
  },
  femaleAbbrev: {
    id: 'person_title_female_abbrev',
    defaultMessage: 'Mrs.',
  },
  profAbbrev: {
    id: 'person_title_prof_abbrev',
    defaultMessage: 'Prof.',
  },
});

export default function titleOptions(intl: IntlShape) {
  return Object.keys(t).map<OptionT>((key) => {
    const translatedTitle = intl.formatMessage(t[key]);
    return {
      value: translatedTitle,
      label: translatedTitle,
    };
  });
}
