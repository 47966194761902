import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link, Location, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAccountLockable, useAuthenticationStrategy, useStagingRibbon } from 'hooks/useConfig';

import { useAuth } from 'providers';

import Button from 'components/Button';
import Flash from 'components/Flash';
import Loading from 'components/Loading';

import SigninContent from '../SigninContent';
import t from '../translations';
import LoginForm from './Form';

export type LocationState = { from: Location } | null;

export default function Login() {
  const { formatMessage } = useIntl();
  const { login } = useAuth();
  const navigate = useNavigate();

  const [error, setError] = useState<string>();

  const location = useLocation();
  const from = (location.state as LocationState)?.from.pathname || '/';

  const { mutate: onLogin, isLoading } = useMutation(login, {
    onSuccess: () => {
      navigate(from, { replace: true });
    },
    onError: (error: any) => {
      setError(error?.message || 'Error');
    },
  });

  const authenticationStrategy = useAuthenticationStrategy();
  const isAccountLockable = useAccountLockable();
  const isStaging = useStagingRibbon();

  useEffect(() => {
    if (authenticationStrategy === 'krb') {
      onLogin(null);
    }
  }, [authenticationStrategy, onLogin]);

  if (authenticationStrategy === 'krb' && error) {
    return <Navigate to="/forbidden" />;
  }

  if (authenticationStrategy === 'krb' || isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const microsoftStrategy = (
    <div className="flex flex-column items-center justify-center">
      <Button color="info" onClick={() => onLogin(null)}>
        <FormattedMessage {...t.loginMicrosoft} />
      </Button>
    </div>
  );

  const credentialsStrategy = (
    <>
      <LoginForm onSubmit={onLogin} />

      <Link to="/login/recover_account">{formatMessage(isAccountLockable ? t.recoverAccount : t.forgotPassword)}</Link>
    </>
  );

  const strategy = authenticationStrategy === 'oidc' ? microsoftStrategy : credentialsStrategy;

  return (
    <div className="login">
      {isStaging ? (
        <div className="login__staging-banner" role="alert">
          <FormattedMessage {...t.acceptationEnvironmentDisclaimer} />
        </div>
      ) : null}

      <SigninContent title={formatMessage(t.signIn)}>
        {error ? (
          <Flash close={false} type="danger">
            {error === 'Unauthorized' ? formatMessage(t.errorMessageLoginFallback) : error}
          </Flash>
        ) : null}

        {strategy}
      </SigninContent>
    </div>
  );
}
