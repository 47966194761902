import { ReactElement, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { cancelActionBarActivity } from 'store/modules/activity';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Portal from 'components/Portal';

const ANIMATION_DURATION = 500;

type ActionBarFunction = (activity: { name: string; payload: any }) => ReactElement<any> | null;

const ActionBarManager = ({
  formMapping,
}: {
  formMapping: {
    [activityName: string]: ActionBarFunction;
  };
}) => {
  const dispatch = useAppDispatch();

  const nodeRef = useRef<HTMLDivElement>(null);
  const activity: any = useAppSelector((state) => state.activity);

  const actionBarFn = formMapping[activity.name];

  const actionBar = typeof actionBarFn === 'function' ? actionBarFn(activity) : null;

  const show = !!actionBar;

  const shouldRender = useDelayedAnimation(show, ANIMATION_DURATION);

  return (
    <Portal>
      <CSSTransition in={show} timeout={ANIMATION_DURATION} classNames="actionbar" nodeRef={nodeRef}>
        {show || shouldRender ? (
          <div ref={nodeRef}>
            <div
              className="actionbar-backdrop"
              role="presentation"
              onClick={() => dispatch(cancelActionBarActivity())}
              onKeyPress={() => dispatch(cancelActionBarActivity())}
            />
            <div className="actionbar">{actionBar}</div>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </Portal>
  );
};

export default ActionBarManager;
