import { ReactNode } from 'react';
import cx from 'classnames';

type CustomProps = {
  className?: string;
  children: ReactNode;
};

type HtmlProps = Omit<JSX.IntrinsicElements['tr'], keyof CustomProps>;

type Props = CustomProps & HtmlProps;

export default function TableAppendixRow({ className, children }: Props) {
  return <tr className={cx('table-row -is-appendix', className)}>{children}</tr>;
}
