import useBooleanInput from './shared/useBooleanInput';
import { BaseInputProps } from './types';

export type ToggleInputProps = BaseInputProps<boolean, boolean>;

export default function ToggleInput(props: ToggleInputProps) {
  const inputProps = useBooleanInput(props);

  return (
    <div className="base-input -type-toggle">
      <label className="toggle">
        <input className="toggle__input" type="checkbox" {...inputProps} />
        <span className="toggle__button"></span>
        <svg className="toggle__icon" width="36" height="18" viewBox="0 0 36 18">
          {inputProps.checked ? (
            <rect x="8" y="4" width="1.5" height="10" rx="1" fill="var(--white)" />
          ) : (
            <circle cx="28" cy="9" r="4" fill="none" stroke="var(--white)" strokeWidth="1.5" />
          )}
        </svg>
      </label>
    </div>
  );
}
