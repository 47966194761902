import { ReactElement } from 'react';

import logoType from 'assets/images/logo-type.png';

type Props = {
  onClick?: () => void;
  children?: ReactElement<any>;
};

export default function Page({ onClick, children }: Props) {
  return (
    <div className="page" onClick={onClick}>
      {children}
      <img src={logoType} className="page-logo" alt="NephroFlow" />
    </div>
  );
}
