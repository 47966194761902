import { ReactNode } from 'react';

import Section from 'components/Section';

interface Props {
  title?: string;
  children?: ReactNode;
  actionButton?: ReactNode;
}

export default function FormSection({ title, children, actionButton }: Props) {
  return (
    <Section withoutPadding>
      {title ? (
        <h4 className="form-header">
          {title}
          {actionButton && <div className="form-header__actions">{actionButton}</div>}
        </h4>
      ) : null}

      {children}
    </Section>
  );
}
