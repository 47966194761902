import { defineMessages } from 'react-intl';

export default defineMessages({
  selectCreateLabel: {
    id: 'form_select_create_label',
    defaultMessage: 'Create "{inputValue}"',
  },
  selectLoading: {
    id: 'form_select_loading',
    defaultMessage: 'Loading...',
  },
  selectPlaceholder: {
    id: 'form_select_placeholder',
    defaultMessage: 'Select option...',
  },
  selectPlaceholderMulti: {
    id: 'form_select_placeholder_multi',
    defaultMessage: 'Select options...',
  },
  selectNoOptions: {
    id: 'form_select_no_options',
    defaultMessage: 'No options',
  },
  selectNoOptionsCreatable: {
    id: 'form_select_no_options_creatable',
    defaultMessage: 'Search for or create an option...',
  },
  selectNoOptionsSearch: {
    id: 'form_select_no_options_search',
    defaultMessage: 'Search for an option...',
  },
  optional: {
    id: 'form_optional_label',
    defaultMessage: 'Optional',
  },
  newlyCreatedOptions: {
    id: 'form_select_recently_created_options',
    defaultMessage: 'Newly created options',
  },
  defaultOptions: {
    id: 'form_select_default_options',
    defaultMessage: 'Default options',
  },
  selectAll: {
    id: 'form_checkbox_group_select_all',
    defaultMessage: '(Select all)',
  },
  deselectAll: {
    id: 'form_checkbox_group_deselect_all',
    defaultMessage: '(Deselect all)',
  },
  placeholderSingle: {
    id: 'date_form_item_placholder_single',
    defaultMessage: 'Select date...',
  },
  placeholderMulti: {
    id: 'date_form_item_placeholder_multi',
    defaultMessage: 'Select date(s)...',
  },
  uploaderButtonTitle: {
    id: 'file_uploader_upload_button',
    defaultMessage: 'Upload new file',
  },
  deleteButtonTitle: {
    id: 'file_uploader_delete_button',
    defaultMessage: 'Delete',
  },
  placeholder: {
    id: 'form_item_list_select',
    defaultMessage: 'Select...',
  },
  hourPlaceholder: {
    id: 'form_item_time_hours_placeholder',
    defaultMessage: 'HH',
  },
  minutePlaceholder: {
    id: 'form_item_time_minutes_placeholder',
    defaultMessage: 'MM',
  },
  unitSeparator: {
    id: 'form_item_unit_separator',
    defaultMessage: 'x',
  },
  unknownError: {
    id: 'form_item_unknown_error',
    defaultMessage: 'An unknown error occurred',
  },
});
