import { ReactNode, useRef } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { SECOND_IN_MILLIS } from 'utils/date';

import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Portal from 'components/Portal';

const ANIMATION_DURATION = SECOND_IN_MILLIS;

interface Props {
  show: boolean;
  size?: 'small' | 'normal' | 'large' | 'extra-large' | 'auto';
  onClose?: () => void;
  children: ReactNode;
}

export default function Modal({ show, size = 'normal', onClose, children }: Props) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const shouldRender = useDelayedAnimation(show, ANIMATION_DURATION);

  return (
    <Portal>
      <CSSTransition in={show} timeout={ANIMATION_DURATION} classNames="modal" nodeRef={nodeRef}>
        {show || shouldRender ? (
          <div ref={nodeRef}>
            <div
              className="modal-backdrop"
              role="presentation"
              onClick={(event) => {
                event.stopPropagation();
                onClose?.();
              }}
            />
            <div
              className={cx('modal', {
                'is-small': size === 'small',
                'is-large': size === 'large',
                'is-extra-large': size === 'extra-large',
                'is-auto': size === 'auto',
              })}
            >
              <div className="modal-container">{children}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </Portal>
  );
}
