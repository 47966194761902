import { ComponentType } from 'react';

import { BaseInputProps } from 'components/Inputs/types';

export type FilterFieldProps<P extends BaseInputProps<any> = BaseInputProps<any>> = P & {
  label?: string;
  Input: ComponentType<P>;
};

export default function FilterField<P extends BaseInputProps<any> = BaseInputProps<any>>({
  label,
  Input,
  value,
  onChange,
  ...props
}: FilterFieldProps<P>) {
  return (
    <div className="filter__field">
      {label ? (
        <label className="filter__field__label" htmlFor={props.name}>
          {label}
        </label>
      ) : null}
      <div className="filter__field__input">
        <Input value={value} onChange={onChange} {...(props as any)} />
      </div>
    </div>
  );
}
