import { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'store';

import {
  AuthProvider,
  ConfigurationProvider,
  CurrentUserProvider,
  DefaultQueryClientProvider,
  ErrorBoundary,
  LocaleProvider,
  RouteWatcher,
  ToastProvider,
  useAuth,
} from 'providers';

import { locale } from 'locale';

import AuthenticatedApp from './components/Authenticated';
import UnauthenticatedApp from './components/Unauthenticated';

export default function Root() {
  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.lang = locale;
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <DefaultQueryClientProvider>
        <LocaleProvider locale={locale}>
          <ErrorBoundary>
            <Router>
              <RouteWatcher />

              <AuthProvider>
                <App />
              </AuthProvider>

              <ToastProvider />
            </Router>
          </ErrorBoundary>
        </LocaleProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </DefaultQueryClientProvider>
    </StoreProvider>
  );
}

function App() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <CurrentUserProvider>
      <ConfigurationProvider>
        <AuthenticatedApp />
      </ConfigurationProvider>
    </CurrentUserProvider>
  ) : (
    <ConfigurationProvider>
      <UnauthenticatedApp />
    </ConfigurationProvider>
  );
}
