import cx from 'classnames';
import { FormattedDate } from 'react-intl';
import { components, SingleValueProps } from 'react-select';

import Age from 'components/Age';
import Avatar from 'components/Avatar';
import Sex from 'components/Sex';
import Title from 'components/Title';

interface Option extends PatientOptionT {
  disabled?: boolean;
}

export default function PatientSingleValue(props: SingleValueProps<any>) {
  const { label, avatarThumbUrl, avatarUrl, birthdate, sex } = props.data as Option;

  return (
    <components.SingleValue {...props} className={cx('select__single-value--is-patient', props.className)}>
      <Avatar url={avatarThumbUrl || avatarUrl} />
      <Title
        subtext={
          <>
            {birthdate ? (
              <span>
                <FormattedDate value={birthdate} />
              </span>
            ) : null}
            {birthdate ? <Age birthdate={birthdate} /> : null}
            {sex ? <Sex value={sex} /> : null}
          </>
        }
      >
        {label}
      </Title>
    </components.SingleValue>
  );
}
