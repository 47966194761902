import { decamelizeKeys } from 'humps';
import { serialize as objectToFormData } from 'object-to-formdata';

import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

export interface UpdateCurrentUserArgsT {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  profession: string;
  avatar: File;
  identifiers: { identifierTypeId: string; value: string }[];
}

export async function updateCurrentUser({
  title,
  firstName,
  lastName,
  email,
  username,
  profession,
  avatar,
  identifiers,
}: UpdateCurrentUserArgsT) {
  const formData = objectToFormData({
    user: {
      title: title || null,
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      profession,
      avatar,
      identifiers: decamelizeKeys(identifiers),
    },
  });

  const { data } = await http.put<{ user: UserT }>('current_user', formData);

  return deserialize(data.user, {
    'updatedAt': parseDateTime,
    'createdAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}

export interface UpdateCurrentUserPasswordArgsT {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export async function updateCurrentUserPassword(currentUser: UpdateCurrentUserPasswordArgsT) {
  await http.put('current_user_password', {
    currentUser,
  });

  return undefined;
}
