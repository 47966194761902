import { MutableRefObject, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import Select, { GroupBase, SelectComponentsConfig, Props as SelectProps } from 'react-select';
import Async, { AsyncProps } from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable, { CreatableProps } from 'react-select/creatable';

import { isMessageDescriptor } from 'utils/intl';

import useConstant from 'hooks/useConstant';
import useIsMounted from 'hooks/useIsMounted';
import useSyncedRef from 'hooks/useSyncedRef';

import { useSelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import { ClearIndicator, DropdownIndicator, MultiValueContainer, MultiValueRemove } from './shared/reactSelect';
import { BaseInputProps } from './types';

export type Value = string | number | Date | (string | number | Date)[];

export interface ListOption {
  label: string;
  value: string | number | Date;
  disabled?: boolean;

  /** @private */
  _isInternal?: boolean;
}

type FetchOptions<O extends ListOption> = (inputValue: string) => Promise<O[]> | O[];

type CreateOption<O extends ListOption> = (inputValue: string) => Promise<O | null> | O | null;

export interface ListInputProps<V extends Value = Value, O extends ListOption = ListOption, M extends boolean = boolean>
  extends BaseInputProps<V> {
  options: O[];
  multiple?: M;
  clearable?: boolean;
  loading?: boolean;
  components?: SelectComponentsConfig<O, M, GroupBase<O>>;
  placeholder?: MessageDescriptor | string;
  loadingMessage?: string;
  noOptionsMessage?: MessageDescriptor | string;
  fetchOptions?: FetchOptions<O>;
  createOption?: CreateOption<O>;
}

export default function ListInput<
  V extends Value = Value,
  O extends ListOption = ListOption,
  M extends boolean = boolean
>({
  defaultValue,
  value,
  onChange,
  onBlur,
  name,
  disabled = false,
  autoFocus = false,
  options: externalOptions,
  multiple,
  clearable = true,
  loading = undefined,
  components,
  placeholder,
  loadingMessage,
  noOptionsMessage: rawNoOptionsMessage,
  fetchOptions,
  createOption,
  ...restProps
}: ListInputProps<V, O, M>) {
  const { formatMessage } = useIntl();
  const isControlled = !!onChange;

  const menuPortalTarget = useSelectMenuPortalTargetContext();

  const state = useManagedSelect<V, O>({
    externalOptions,
    value: isControlled ? value : defaultValue,
    onChange,
  });

  const customComponents: SelectComponentsConfig<O, M, GroupBase<O>> = {
    ClearIndicator,
    DropdownIndicator,
    MultiValueContainer,
    MultiValueRemove,
    ...components,
  };

  const Component: any = (() => {
    if (!!fetchOptions && !!createOption) return AsyncCreatable;
    if (fetchOptions) return Async;
    if (createOption) return Creatable;
    return Select;
  })();

  const handleBlur = () => {
    onBlur?.();
  };

  const getPlaceholder = () => {
    if (placeholder) return isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder;
    if (fetchOptions) return formatMessage(t.placeholderAsync);
    return formatMessage(t.placeholder);
  };

  const noOptionsMessage = isMessageDescriptor(rawNoOptionsMessage)
    ? formatMessage(rawNoOptionsMessage)
    : rawNoOptionsMessage;

  const baseProps: SelectProps<O, M> = {
    inputId: name,
    name: name,
    value: state.selected,
    onChange: state.handleChange,
    onBlur: handleBlur,
    isDisabled: disabled,
    isLoading: loading,
    autoFocus: autoFocus,
    options: state.options,
    isMulti: (multiple === true) as M,
    isClearable: clearable,
    components: customComponents,
    openMenuOnFocus: true,
    menuPlacement: 'auto',
    closeMenuOnSelect: multiple !== true,
    isOptionDisabled: ({ disabled }) => disabled === true,
    className: 'react-select select',
    classNamePrefix: 'react-select select',
    menuPortalTarget,
    placeholder: getPlaceholder(),
    loadingMessage: () => loadingMessage || formatMessage(t.loading),
    noOptionsMessage: () => (rawNoOptionsMessage ? noOptionsMessage : formatMessage(t.noOptions)),
  };

  const asyncProps = useAsyncProps<O, M>({
    options: state.options,
    fetchOptions,
  });

  const creatableProps = useCreatableProps<O, M>({
    handleChange: state.handleChange,
    selected: state.selected,
    createOption,
    multiple: (multiple === true) as M,
  });

  return (
    <div className="base-input -type-list">
      <Component {...restProps} {...baseProps} {...asyncProps} {...creatableProps} />
    </div>
  );
}

function useAsyncProps<O extends ListOption, M extends boolean>(props: {
  options: ManagedState<O>['options'];
  fetchOptions: FetchOptions<O> | undefined;
}): AsyncProps<O, M, GroupBase<O>> {
  const debouncedFetchOptions = useDebounce(async (inputValue: string) => {
    if (props.fetchOptions) {
      return props.fetchOptions(inputValue);
    }
    return [];
  }, 700);

  if (!props.fetchOptions) return {};

  return {
    defaultOptions: props.options,
    loadOptions: async (inputValue: string) => {
      const fetchedOptions = await debouncedFetchOptions(inputValue);
      return fetchedOptions.map(internalizeOption);
    },
  };
}

function useCreatableProps<O extends ListOption, M extends boolean>(props: {
  handleChange: ManagedState<O>['handleChange'];
  selected: ManagedState<O>['selected'];
  createOption: CreateOption<O> | undefined;
  multiple: M;
}): CreatableProps<O, M, GroupBase<O>> {
  const { formatMessage } = useIntl();
  const isMounted = useIsMounted();

  if (!props.createOption) return {};

  return {
    formatCreateLabel: (inputValue: string) => {
      return formatMessage(t.createLabel, { text: inputValue });
    },
    onCreateOption: async (inputValue: string) => {
      const createdOption = await props.createOption?.(inputValue);
      if (createdOption && isMounted()) {
        if (props.multiple) {
          props.handleChange([...props.selected, internalizeOption(createdOption)]);
        } else {
          props.handleChange(internalizeOption(createdOption));
        }
      }
    },
  };
}

interface ManagedState<O extends ListOption> {
  selected: O[];
  options: O[];
  handleChange: (incomingSelection: O | readonly O[] | null) => void;
}

function useManagedSelect<V extends Value, O extends ListOption>({
  externalOptions,
  value,
  onChange,
}: {
  externalOptions: O[];
  value: V | null | undefined;
  onChange: ((value: V | null) => void) | undefined;
}): ManagedState<O> {
  const [internalOptions, setInternalOptions] = useState<O[]>([]);
  const [options, setOptions] = useState<O[]>(externalOptions);
  const [selected, setSelected] = useState<O[]>(getSelectedOptions(value, externalOptions));

  const lastValueRef = useRef(value);
  const lastExternalOptionsRef = useRef(externalOptions);

  const valueRef = useSyncedRef(value);
  const externalOptionsRef = useSyncedRef(externalOptions);
  const internalOptionsRef = useSyncedRef(internalOptions);

  /**
   * When `value` changes we update `selected` based on the new `value` and the
   * current `externalOptions`.
   */
  useEffect(() => {
    if (!isEqual(value, lastValueRef.current)) {
      setSelected((currentSelected) => {
        const options = optionsUnion(externalOptionsRef.current, currentSelected);
        return getSelectedOptions(value, options);
      });

      lastValueRef.current = value;
    }
  }, [value, externalOptionsRef]);

  /**
   * When `externalOptions` changes we update `selected` based on the current
   * `value` and the new `externalOptions`.
   * We also update `options` based on the new `externalOptions`.
   */
  useEffect(() => {
    if (!isEqual(externalOptions, lastExternalOptionsRef.current)) {
      setSelected((currentSelected) => {
        const options = optionsUnion(externalOptions, currentSelected);
        return getSelectedOptions(valueRef.current, options);
      });

      setOptions(optionsUnion(externalOptions, internalOptionsRef.current));

      lastExternalOptionsRef.current = externalOptions;
    }
  }, [externalOptions, valueRef, internalOptionsRef]);

  /**
   * When `selected` changes we remove all options from `internalOptions` that
   * are not currently selected.
   * This ensures that when `value` changes (and thus `selected` too), we remove
   * any internal options that should not be present anymore.
   */
  useEffect(() => {
    setInternalOptions((currentInternalOptions) => optionsIntersection(currentInternalOptions, selected));
  }, [selected]);

  /**
   * When `internalOptions` changes we update `options` based on the new
   * `internalOptions`.
   */
  useEffect(() => {
    setOptions(optionsUnion(externalOptionsRef.current, internalOptions));
  }, [internalOptions, externalOptionsRef]);

  const selectionAllowed = ({ value, disabled }: O) => {
    const notDisabled = disabled !== true;
    const alreadySelected = !!selected.find((o) => o.value === value);

    return notDisabled || alreadySelected;
  };

  const persistChange = (selection: O[], value: V | null) => {
    setInternalOptions(selection.filter(({ _isInternal }) => !!_isInternal));
    setSelected(selection);
    onChange?.(value);
  };

  const handleChange = (incomingSelection: O | readonly O[] | null) => {
    if (Array.isArray(incomingSelection)) {
      incomingSelection = incomingSelection as O[];

      const selectedDisabledOptions = selected.filter(({ disabled }) => disabled === true);

      const selection = [
        ...optionsUnion(selectedDisabledOptions, incomingSelection)
          .filter(selectionAllowed)
          .sort(sortBasedOnOptions(externalOptions))
          .sort(sortDisabledOptionsFirst()),
      ];

      const nextSelection = selection;
      const nextValue = selection.map(({ value }) => value) as V;

      persistChange(nextSelection, nextValue);
    } else {
      if (incomingSelection && 'value' in incomingSelection) {
        if (selectionAllowed(incomingSelection)) {
          const nextSelection = [incomingSelection];
          const nextValue = incomingSelection.value as V;

          persistChange(nextSelection, nextValue);
        }
      } else {
        persistChange([], null);
      }
    }
  };

  return { selected, options, handleChange };
}

function getSelectedOptions<V extends Value, O extends ListOption>(value: V | null | undefined, options: O[]) {
  if (Array.isArray(value)) {
    return options.filter((o) => value.includes(o.value)).sort(sortDisabledOptionsFirst());
  }

  if (value !== null && value !== undefined) {
    const selection = options.find((o) => o.value === value);
    return selection ? [selection] : [];
  }

  return [];
}

function optionsIntersection<O extends ListOption>(aOptions: readonly O[], bOptions: readonly O[]) {
  const bValues = bOptions.map(({ value }) => value);
  return aOptions.filter(({ value }) => bValues.includes(value));
}

function optionsUnion<O extends ListOption>(aOptions: readonly O[], bOptions: readonly O[]) {
  const aValues = aOptions.map(({ value }) => value);
  return [...aOptions, ...bOptions.filter(({ value }) => !aValues.includes(value))];
}

function sortBasedOnOptions<O extends ListOption>(options: O[]) {
  const optionValues = options.map(({ value }) => value);

  return (a: O, b: O) => {
    return optionValues.indexOf(a.value) - optionValues.indexOf(b.value);
  };
}

function sortDisabledOptionsFirst<O extends ListOption>() {
  return (a: O, b: O) => {
    if (a.disabled === true && b.disabled !== true) {
      return -1;
    }

    if (a.disabled !== true && b.disabled === true) {
      return 1;
    }

    return 0;
  };
}

function internalizeOption<O extends ListOption>(option: O): O {
  return { ...option, _isInternal: true };
}

function useDebounce<T extends (...args: any[]) => Promise<any>>(func: T, wait: number) {
  const funcRef = useSyncedRef(func);
  return useConstant(() => debounce(funcRef, wait));
}

function debounce<T extends (...args: any[]) => Promise<any>>(funcRef: MutableRefObject<T>, wait: number) {
  let timeout: number | undefined;

  function cleanup() {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = undefined;
    }
  }

  function debounced(...args: any[]) {
    return new Promise((resolve, reject) => {
      function callFunc() {
        cleanup();

        funcRef
          .current(...args)
          .then(resolve)
          .catch(reject);
      }

      cleanup();
      timeout = window.setTimeout(callFunc, wait);
    });
  }

  return debounced as T;
}

const t = defineMessages({
  placeholder: {
    id: 'list_input_placeholder',
    defaultMessage: 'Select...',
  },
  placeholderAsync: {
    id: 'list_input_placeholder_async',
    defaultMessage: 'Search for an option...',
  },
  loading: {
    id: 'list_input_loading',
    defaultMessage: 'Loading...',
  },
  noOptions: {
    id: 'list_input_no_options',
    defaultMessage: 'No options',
  },
  createLabel: {
    id: 'list_input_create_label',
    defaultMessage: 'Create "{text}"',
  },
});
