type Props = {
  innerRef?: any;
  innerProps?: any;
  style?: any;
  onClick: () => void;
};

const ClearIndicator = ({ innerProps, innerRef, style, onClick }: Props) => (
  <div
    ref={innerRef}
    {...innerProps}
    role="presentation"
    style={style}
    className="Select__clear-indicator-container"
    onClick={onClick}
  >
    <span className="Select__clear-indicator">×</span>
  </div>
);

export default ClearIndicator;
