import { useQuery } from 'react-query';

import { http } from 'api/client';
import { UserPermissionT } from 'api/endpoints/users';

import { HOUR_IN_MILLIS } from 'utils/date';

type AuthStrategy = 'db' | 'ldap' | 'krb' | 'oidc';
type AccessType = Exclude<UserPermissionT, 'integration'>;
type PosologyType = 'structured' | 'free_text';
type LetterSendingMethod = 'after_validation' | 'manual' | 'never';

type ConfigurationType = null | AuthStrategy | AccessType | string | number | boolean | string[];

export interface Configuration {
  id: string;
  type: 'string' | 'integer' | 'boolean' | 'list';
  value?: ConfigurationType | null;
  default?: ConfigurationType | null;
  key: string;
  title: string;
  description: string;
  allowedValues: OptionT[];
  required: boolean;
}

const days: Day[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

// Fetch configuration hooks
export const CONFIGURATIONS_QUERY_KEY = 'configurations';

export function useConfigurations() {
  const { data, isLoading } = useQuery(CONFIGURATIONS_QUERY_KEY, () => fetchConfigurations());

  return { configurations: data, isLoading };
}

function useConfiguration<T extends ConfigurationType>(key: string, defaultValue: T) {
  // Refetch on hook mount after 3 hours of stale data
  const { data = [] } = useQuery(CONFIGURATIONS_QUERY_KEY, () => fetchConfigurations(), {
    staleTime: 3 * HOUR_IN_MILLIS,
  });

  const configuration = data.find((config) => config.key === key);

  return (configuration?.value as T) ?? defaultValue;
}

async function fetchConfigurations() {
  const { data } = await http.get<{ configurations: Configuration[] }>('configurations');

  return data.configurations;
}

// System configuration hooks

export function useAccountLockable() {
  return useConfiguration<boolean>('auth.lockable', false);
}

export function useAgendaExternalRoomIdentifier() {
  return useConfiguration<boolean>('telegraph.agenda', false);
}

export function useAllModulesEnabled() {
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const patientCardsEnabled = usePatientCardsEnabled();
  const workbenchEnabled = useWorkbenchEnabled();

  return recordEnabled && trackEnabled && patientCardsEnabled && workbenchEnabled;
}

export function useAmbulatoryIsStructuredPosologyDefault() {
  const posology = useConfiguration<PosologyType>('record.ambulatory_medication.posology.default', 'free_text');

  return posology === 'structured';
}

export function useAmbulatoryMedicationEnabled() {
  return useConfiguration<boolean>('record.home_medication', false);
}

export function useAmbulatoryPrescriptionEnabled() {
  return useConfiguration<boolean>('record.ambulatory_medication.prescriber', false);
}

export function useAmbulatoryPrescriptionsEnabled() {
  const country = useCountry();
  const ambulatoryMedicationEnabled = useAmbulatoryMedicationEnabled();
  const ambulatoryPrescriptionEnabled = useAmbulatoryPrescriptionEnabled();

  const isBE = country === 'BE';
  const isFR = country === 'FR';
  const isSupported = isBE || isFR;

  return isSupported && ambulatoryMedicationEnabled && ambulatoryPrescriptionEnabled;
}

export function useAuthenticationStrategy() {
  return useConfiguration<AuthStrategy>('auth.authenticator', 'db');
}

export function useBillingByTreatmentModality() {
  return useConfiguration<boolean>('record.billing.by_treatment_modality', false);
}

export function useChartDataPoints() {
  return useConfiguration<'5' | '10'>('charts.number_of_data_points', '5');
}

export function useCountry() {
  return useConfiguration<'BE' | 'FR' | null>('country', null)?.toUpperCase();
}

export function useDisposableConsumptionRegistration() {
  return useConfiguration<boolean>('disposables.consumption_registration', false);
}

export type HdChartTypes = 'average_pressure' | 'blood_pressure' | 'ktv' | 'ultrafiltration' | 'weight';

export function useHdDashboardCharts() {
  const charts = useConfiguration<HdChartTypes[]>('charts.dashboard.hd_types', [
    'average_pressure',
    'blood_pressure',
    'ktv',
    'ultrafiltration',
    'weight',
  ]);

  return {
    hasCharts: charts.length > 0,
    showAveragePressureChart: charts.includes('average_pressure'),
    showBloodPressureChart: charts.includes('blood_pressure'),
    showKtvChart: charts.includes('ktv'),
    showUltrafiltrationChart: charts.includes('ultrafiltration'),
    showWeightChart: charts.includes('weight'),
  };
}

export function useJournalEHRPublishingEnabled() {
  return useConfiguration<boolean>('telegraph.journal_item', false);
}

export function useLetterSendingMethod() {
  return useConfiguration<LetterSendingMethod>('telegraph.letter', 'never');
}

export function useManualPatientCreationEnabled() {
  return !useConfiguration<boolean>('record.integration.adt', true);
}

export function useManualSessionEHRPublishingEnabled() {
  const isSessionSummaryEnabled = useConfiguration<boolean>('telegraph.session_summary', false);

  const isManual = useConfiguration<string>('telegraph.session_summary.frequency', 'always') === 'adhoc';

  return isSessionSummaryEnabled && isManual;
}

export function useMulticenterAccessRights() {
  return useConfiguration<boolean>('multicenter_access_rights', false);
}

export function usePasswordEnforcingAuthentication() {
  const authStrategyOIDC = useAuthenticationStrategy() === 'oidc';
  const authStrategyLDAP = useAuthenticationStrategy() === 'ldap';

  return !(authStrategyOIDC || authStrategyLDAP);
}

export function usePatientAgendaEnabled() {
  return useConfiguration<boolean>('record.agenda', false);
}

export function usePatientCardsEnabled() {
  return useConfiguration<boolean>('patient.cards', false);
}

type PdChartTypes = 'blood_pressure' | 'ultrafiltration' | 'weight';

export function usePdDashboardCharts() {
  const charts = useConfiguration<PdChartTypes[]>('charts.dashboard.pd_types', [
    'blood_pressure',
    'ultrafiltration',
    'weight',
  ]);

  return {
    hasCharts: charts.length > 0,
    showBloodPressureChart: charts.includes('blood_pressure'),
    showUltrafiltrationChart: charts.includes('ultrafiltration'),
    showWeightChart: charts.includes('weight'),
  };
}

export function useRecordEnabled() {
  return useConfiguration<boolean>('record', false);
}

export function useRightScopes() {
  const workbenchEnabled = useWorkbenchEnabled();
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const patientCardsEnabled = usePatientCardsEnabled();

  const scopes: RightScopesT[] = ['admin'];

  if (patientCardsEnabled) scopes.push('patient_cards');
  if (recordEnabled || trackEnabled) scopes.push('patients');
  if (workbenchEnabled) scopes.push('workbench');
  if (recordEnabled) scopes.push('record');

  return scopes;
}

export function useSessionMetricsDebug() {
  return useConfiguration<boolean>('session.metrics.debug', false);
}

export function useStagingRibbon() {
  return useConfiguration<boolean>('display_staging_ribbon', false);
}

export function useStrongPasswordPolicy() {
  return useConfiguration<boolean>('auth.enforce_password_policy', false);
}

export function useTrackEnabled() {
  return useConfiguration<boolean>('track', false);
}

export function useUserAccessType() {
  return useConfiguration<AccessType>('user.access_type', 'global');
}

export function useWeekDayOffset() {
  const firstDayOfWeek = useConfiguration<Day>('first_day_of_week', 'monday');

  return days.indexOf(firstDayOfWeek) as WeekDay;
}

export function useWeekendDays() {
  const weekendDays = useConfiguration<Day[]>('weekend_days', ['saturday', 'sunday']);

  return weekendDays.map((day) => days.indexOf(day) as WeekDay);
}

export function useWorkbenchEnabled() {
  return useConfiguration<boolean>('workbench', false);
}
