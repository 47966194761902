import { defineMessages, useIntl } from 'react-intl';

import Button, { ButtonProps } from 'components/Button';

type Props = ButtonProps;

export default function SubmitButton({ children, ...buttonProps }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Button color="danger" {...buttonProps}>
      {children || formatMessage(t.submit)}
    </Button>
  );
}

const t = defineMessages({
  submit: {
    id: 'modal_submit_title',
    defaultMessage: 'Submit',
  },
});
