import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { injectIntl, IntlShape } from 'react-intl';
import Select from 'react-select';

import { ClearIndicator, DropdownIndicator } from 'components/ReactSelect';
import { SelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import t from './translations';

type valueT = string | number;

type Props = {
  id?: string;
  label?: string;
  value: valueT | null | undefined;
  defaultValue?: valueT;
  options: OptionT[];
  placeholder?: string;
  onChange: (val?: valueT) => void;
  disabled?: boolean;
  optional?: boolean;
  notClearable?: boolean;
  notSearchable?: boolean;
  autoFocus?: boolean;
  menuPlacement?: 'bottom' | 'auto' | 'top' | undefined;

  intl: IntlShape;
};

class List extends Component<Props> {
  static displayName = 'ListFormItem';
  static contextType = SelectMenuPortalTargetContext;

  componentDidMount() {
    const { id, options, value, optional, onChange } = this.props;

    if (import.meta.env.DEV && options === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`[ListFormItem] ${id}: Options shouldn't be `, undefined);
    }

    if (options.length === 1 && value !== options[0].value && !optional) {
      onChange(options[0].value);
    }
  }

  componentDidUpdate(prevProps) {
    const { onChange, value, optional } = this.props;

    const options = this.listOptions();
    const oldOptions = this.listOptions(prevProps.options);

    const singleOption = options.length === 1;
    const differentOptions = !isEqual(options, oldOptions);
    const currentValueNotInOptions = !options.find((opt) => opt.value === value);

    if (singleOption && currentValueNotInOptions && !optional) {
      onChange(options[0].value);
    } else if (differentOptions && currentValueNotInOptions) {
      onChange(undefined);
    }
  }

  listOptions(newOptions = undefined): { label: valueT; value: valueT }[] {
    const { options: propOptions, value, defaultValue } = this.props;

    const options = newOptions || propOptions;
    const currentValue = value || defaultValue;

    const option = options.find((o) => o.value === currentValue) || undefined;
    const filteredOptions = options.filter((o) => o.value !== currentValue);

    return option ? [option, ...filteredOptions] : filteredOptions;
  }

  render() {
    const {
      id,
      value,
      defaultValue,
      onChange,
      placeholder,
      disabled,
      notClearable,
      optional,
      autoFocus,
      intl: { formatMessage },
      menuPlacement = 'bottom',
    } = this.props;

    const menuPortalTarget = this.context;

    const val = value || defaultValue;
    const options = this.listOptions();
    const selectedOption = val ? options.find((o) => o.value === val) : null;

    return (
      <Select
        className="Select form-select"
        classNamePrefix="Select"
        isDisabled={disabled}
        isClearable={optional || (notClearable !== undefined && !notClearable)}
        name={id}
        options={options}
        placeholder={placeholder || formatMessage(t.placeholder)}
        value={selectedOption}
        onChange={(option) => onChange((option as OptionT | null)?.value)}
        openMenuOnFocus
        autoFocus={autoFocus}
        menuPlacement={menuPlacement}
        components={{
          ClearIndicator: ClearIndicator as any,
          DropdownIndicator: DropdownIndicator as any,
        }}
        menuPortalTarget={menuPortalTarget}
        styles={menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 4 }) } : undefined}
      />
    );
  }
}

export default injectIntl(List);
