import cx from 'classnames';
import { camelize } from 'humps';
import { FormattedMessage, useIntl } from 'react-intl';

import Flash from 'components/Flash';

import {
  AvatarField,
  BooleanField,
  CheckboxGroupField,
  DateField,
  EmailField,
  FileField,
  FloatField,
  IntegerField,
  ListField,
  MultiListField,
  PasswordField,
  RadioButtonGroupField,
  RichTextField,
  StringField,
  fieldTranslations as t,
  TextAreaField,
  TimeField,
} from '../Fields';

const TextAreaBig = (props: any) => <TextAreaField {...props} isBig />;

const FORM_FIELDS = {
  avatar: AvatarField,
  file: FileField,
  string: StringField,
  text: TextAreaField,
  textBig: TextAreaBig,
  text_big: TextAreaBig,
  richText: RichTextField,
  rich_text: RichTextField,
  password: PasswordField,
  email: EmailField,
  integer: IntegerField,
  float: FloatField,
  boolean: BooleanField,
  list: ListField,
  multiList: MultiListField,
  multi_list: MultiListField,
  date: DateField,
  time: TimeField,
  checkboxGroup: CheckboxGroupField,
  check_box_group: CheckboxGroupField,
  radioButton: RadioButtonGroupField,
  radio_button: RadioButtonGroupField,
};

interface Props {
  id: string;
  label: string;
  placeholder: string | null | undefined;
  value: any;
  defaultValue: any;
  optional: boolean;
  disabled: boolean;
  autoFocus: boolean;
  type: string;
  allowedContentTypes: string[] | null | undefined;
  options: any[] | null | undefined;
  unit: any;
  onChange: (arg0: any) => void;
  errors: Record<string, any> | null | undefined;
}

export default function FormItem(props: Props) {
  const { formatMessage } = useIntl();

  const { id, label, value, optional, type, disabled, unit, onChange } = props;

  const handleChange = (updatedValue) => {
    if (!disabled) {
      onChange({ [id]: updatedValue });
    }
  };

  const Field = FORM_FIELDS[type];

  if (!Field) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.error(`Unable to render formItem of type '${type}' for id '${id}'`);
    }

    return null;
  }

  const { errors } = props;
  const fieldErrors = (errors && (errors[id] || errors[camelize(id)])) || [];

  const errorMessages = fieldErrors.map((e) => e.title || e.detail || formatMessage(t.unknownError));

  return (
    <div
      className={cx('form-item', {
        'has-error': errorMessages.length,
        'is-disabled': disabled,
      })}
    >
      <label htmlFor={id} title={label}>
        {label}

        {optional && (
          <small>
            <FormattedMessage {...t.optional} />
          </small>
        )}
      </label>

      <Field {...props} value={value} onChange={handleChange} />

      {unit && (
        <span className="form-unit">
          {unit.withSeparator ? (
            <span className="form-unit__separator">
              <FormattedMessage {...t.unitSeparator} />
            </span>
          ) : null}
          <span>{unit.abbrev || null}</span>
        </span>
      )}

      {errorMessages.length > 0 && (
        <Flash close={false} type="danger" list={errorMessages} style={{ fontSize: 'var(--font-size)' }} />
      )}
    </div>
  );
}
