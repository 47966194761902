import t from '../errorTranslations';
import { MaxLengthValidationT } from '../schema';
import { createBaseValidation, IntlOrErrMsg } from './utils';

export function maxLengthValidator(validator: MaxLengthValidationT) {
  const maxLength = validator.args[0];

  return {
    allowedTypes: ['string', 'array'],
    validator: (val: string) => val === undefined || val.length <= maxLength,
    errorMessage: formatErrorMessage(validator),
  };
}

export function maxLength(length: number, intlOrErrMsg: IntlOrErrMsg): MaxLengthValidationT {
  return {
    type: 'max_length',
    args: [length],
    ...createBaseValidation(intlOrErrMsg),
  };
}

function formatErrorMessage({ errorMessage, intl, args }: MaxLengthValidationT) {
  const maxLength = String(args[0]);

  if (errorMessage) return errorMessage.replace('#{max_length}', maxLength);
  if (intl) return intl.formatMessage(t.maxLength, { max: maxLength });
  return '';
}
