import checkAuth from 'utils/checkAuth';

import { usePatientAgendaEnabled, useRecordEnabled, useTrackEnabled } from 'hooks/useConfig';

import { useCurrentUser } from 'providers';

import { RIGHTS } from 'rights';

export const toolsMedicationRights = [
  RIGHTS.MANAGE_MEDICATION_PRESCRIPTION,
  RIGHTS.PRESCRIBE_HOME_MEDICATION_PRESCRIPTION,
  RIGHTS.VALIDATE_MEDICATION,
];

export const toolsUnknownMachineSessionsRights = [RIGHTS.ENROLL_PATIENT];

export function useToolsEnabled() {
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const patientAgendaEnabled = usePatientAgendaEnabled();
  const currentUser = useCurrentUser();

  const showAgendaTools = recordEnabled && patientAgendaEnabled;
  const showMedcationTools = recordEnabled && checkAuth({ currentUser, rights: toolsMedicationRights });
  const showUknownMachineSessionTools =
    (recordEnabled || trackEnabled) && checkAuth({ currentUser, rights: toolsUnknownMachineSessionsRights });

  return showAgendaTools || showMedcationTools || showUknownMachineSessionTools;
}
