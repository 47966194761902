import { MouseEvent, useState } from 'react';
import { useFloating } from '@floating-ui/react-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';

import { useManualPatientCreationEnabled, useRecordEnabled } from 'hooks/useConfig';

import Icon from 'components/Icon';

import RequireAuth from 'sharedComponents/RequireAuth';

import { adminRights, useAdminEnabled } from 'views/admin';

import { RIGHTS } from 'rights';

export default function SettingsNavigation() {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const { reference, floating, strategy, x, y } = useFloating({ placement: 'right-end' });

  const isManualPatientCreationEnabled = useManualPatientCreationEnabled();
  const recordEnabled = useRecordEnabled();
  const adminEnabled = useAdminEnabled([...adminRights, ...(recordEnabled ? [RIGHTS.EDIT_ADMINISTRATIVE_RECORD] : [])]);

  const closePerspectivePicker = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const togglePerspectivePicker = () => {
    setIsOpen((current) => !current);
  };

  const cx = ({ isActive }) => 'sidebar__item' + (isActive ? ' -is-active' : '');

  const buttonClassName = `sidebar__item ${
    pathname.startsWith('/admin') || pathname.startsWith('/record/settings') ? '-is-active' : ''
  }`;

  if (!adminEnabled) return null;

  return (
    <li role="presentation" onClick={togglePerspectivePicker} ref={reference}>
      <button className={buttonClassName} title={formatMessage(t.settings)}>
        <Icon>settings</Icon>
      </button>

      {isOpen ? (
        <ul
          ref={floating}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
          className="sidebar__admin-nav"
          onMouseLeave={closePerspectivePicker}
        >
          <RequireAuth rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <li role="presentation">
              <NavLink to="/admin/iam" className={cx}>
                <Icon>account_box</Icon>
                <FormattedMessage {...t.iam} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_APPLICATION_DATA]} booleanMatch={!isManualPatientCreationEnabled}>
            <li role="presentation">
              <NavLink to="/admin/adt" className={cx}>
                <Icon>integration_instructions</Icon>
                <FormattedMessage {...t.adt} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_CENTERS]}>
            <NavLink to="/admin/infrastructure" className={cx}>
              <Icon>business</Icon>
              <FormattedMessage {...t.infrastructure} tagName="span" />
            </NavLink>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <li role="presentation">
              <NavLink to="/admin/devices" className={cx}>
                <Icon>devices</Icon>
                <FormattedMessage {...t.devices} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
            <li role="presentation">
              <NavLink to="/admin/imports" className={cx}>
                <Icon>system_update_alt</Icon>
                <FormattedMessage {...t.imports} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_APPLICATION_DATA]} booleanMatch={recordEnabled}>
            <li role="presentation">
              <NavLink to="/admin/document_templates" className={cx}>
                <Icon>description</Icon>
                <FormattedMessage {...t.documentTemplates} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth
            rights={[RIGHTS.MANAGE_APPLICATION_DATA, RIGHTS.EDIT_ADMINISTRATIVE_RECORD, RIGHTS.MANAGE_SYSTEM]}
            booleanMatch={recordEnabled}
          >
            <li role="presentation">
              <NavLink to="record/settings" title={formatMessage(t.application)} className={cx}>
                <Icon>app:record</Icon>
                <FormattedMessage {...t.application} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>

          <RequireAuth rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <li role="presentation">
              <NavLink to="/admin/system" className={cx}>
                <Icon>settings_applications</Icon>
                <FormattedMessage {...t.system} tagName="span" />
              </NavLink>
            </li>
          </RequireAuth>
        </ul>
      ) : null}
    </li>
  );
}

const t = defineMessages({
  settings: {
    id: 'settings_navigation_title',
    defaultMessage: 'Settings',
  },
  application: {
    id: 'settings_navigation_application_configuration',
    defaultMessage: 'Application configuration',
  },
  adt: {
    id: 'settings_navigation_adt',
    defaultMessage: 'ADT',
  },
  iam: {
    id: 'settings_navigation_iam',
    defaultMessage: 'Identity & Access',
  },
  infrastructure: {
    id: 'settings_navigation_infrastructure',
    defaultMessage: 'Infrastructure',
  },
  devices: {
    id: 'settings_navigation_devices',
    defaultMessage: 'Device management',
  },
  imports: {
    id: 'settings_navigation_imports',
    defaultMessage: 'Imports',
  },
  documentTemplates: {
    id: 'settings_navigation_document_templates',
    defaultMessage: 'Document templates',
  },
  system: {
    id: 'settings_navigation_system',
    defaultMessage: 'System configurations',
  },
});
