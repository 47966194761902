import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';

import { useStrongPasswordPolicy } from 'hooks/useConfig';

import Flash from 'components/Flash';

import { resetPassword } from '../fetchers';
import SigninContent from '../SigninContent';
import t from '../translations';
import ResetPasswordForm from './Form';

export default function ResetPassword() {
  const { formatMessage } = useIntl();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const strongPasswordPolicy = useStrongPasswordPolicy();

  const reset = useMutation(resetPassword, {
    onSuccess: () => {
      setSuccess(true);
      setError(undefined);
    },
    onError: (error: any) => {
      setError(error?.response?.data?.errors?.[0]?.detail || formatMessage(t.errorMessageFallback));
    },
  });

  return (
    <div className="login">
      <SigninContent title={formatMessage(t.resetPassword)}>
        {error ? (
          <Flash close={false} type="danger">
            {error}
          </Flash>
        ) : null}

        {success ? (
          <FormattedMessage {...t.resetSuccessMessage} tagName="p" />
        ) : (
          <>
            {strongPasswordPolicy && (
              <Flash type="info" close={false}>
                <FormattedMessage {...t.passwordInfo} />
              </Flash>
            )}

            <ResetPasswordForm onSubmit={(values) => reset.mutate({ ...values, token: token! })} />
          </>
        )}

        <Link style={{ marginTop: '10px' }} to="/login">
          <FormattedMessage {...t.returnLogin} />
        </Link>
      </SigninContent>
    </div>
  );
}
