const injectAdditionalValues = (form, formValues) => {
  const processor = (values, field) => {
    const { id } = field;
    const value = values[id];

    if (field.type !== 'list' || value === undefined) return values;

    if (field.type === 'list' && field.options === undefined) return values;

    const option = field.options.find((x) => x.value === value);

    if (option === undefined || option.environment === undefined) return values;

    return option.environment.reduce((acc, e) => ({ ...acc, [`${id}.${e.id}`]: e.value }), values);
  };

  if (form.sections === undefined) return form.reduce(processor, formValues);

  return form.sections.reduce((values, section) => section.fields.reduce(processor, values), formValues);
};

export default injectAdditionalValues;
