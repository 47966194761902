import { useEffect, useRef, useState } from 'react';

import RTE from 'components/RTE';
import { Rte } from 'components/RTE/domain';

export default function RichText({
  disabled = false,
  defaultValue,
  onChange,
  placeholder,
  autoFocus,
}: {
  disabled?: boolean;
  defaultValue: Rte.Node[] | null;
  onChange: (value: Rte.Node[] | null) => void;
  placeholder: string | null | undefined;
  autoFocus: boolean;
}) {
  const [value, setValue] = useState(defaultValue);

  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      onChangeRef.current(value);
    }, 250);

    return () => window.clearTimeout(timeout);
  }, [value]);

  return (
    <div style={{ display: 'inline-block', margin: '15px 0' }}>
      <RTE
        isDisabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        placeholder={placeholder || undefined}
        autoFocus={autoFocus}
      />
    </div>
  );
}
