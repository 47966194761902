import { useEffect, useState } from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import Select, {
  components,
  GroupBase,
  MenuListProps,
  SelectComponentsConfig,
  Props as SelectProps,
  SingleValueProps,
} from 'react-select';

import ColorPreview from 'components/ColorPreview';
import { useSelectMenuPortalTargetContext } from 'components/SelectMenuPortalTargetProvider';

import { ClearIndicator, DropdownIndicator } from './shared/reactSelect';
import { BaseInputProps } from './types';

const selectComponents: SelectComponentsConfig<OptionT, false, GroupBase<OptionT>> = {
  SingleValue,
  MenuList,
  ClearIndicator,
  DropdownIndicator,
};

export interface ColorInputProps extends BaseInputProps<string> {
  clearable?: boolean;
}

interface CustomSelectProps {
  color: string | null;
  colorOptions: OptionT[];
}

export default function ColorInput({
  defaultValue,
  value: externalValue,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  name,
  disabled = false,
  autoFocus = false,
  clearable = true,
}: ColorInputProps) {
  const { formatMessage } = useIntl();
  const menuPortalTarget = useSelectMenuPortalTargetContext();
  const colorOptions = useColorOptions();

  const isControlled = !!externalOnChange;

  const [color, setColor] = useState<string | null>(isControlled ? externalValue || null : defaultValue || null);

  const value = color ? ({ label: color, value: color } as OptionT) : null;

  useEffect(() => {
    if (isControlled) setColor(externalValue || null);
  }, [externalValue, isControlled]);

  const onBlur = () => {
    externalOnBlur?.();
  };

  const onChange = (option: OptionT | null) => {
    setColor(option?.value || null);
    externalOnChange?.(option?.value || null);
  };

  return (
    <div className="base-input -type-color">
      <Select<OptionT, false>
        inputId={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={disabled}
        autoFocus={autoFocus}
        options={[]}
        components={selectComponents}
        className="react-select select"
        classNamePrefix="react-select select"
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        isSearchable={false}
        isClearable={clearable}
        placeholder={formatMessage(t.selectColor)}
        // custom
        color={color}
        colorOptions={colorOptions}
      />
    </div>
  );
}

function SingleValue(props: SingleValueProps<OptionT, false, GroupBase<OptionT>>) {
  const { color, colorOptions } = props.selectProps as SelectProps<OptionT> & CustomSelectProps;

  const displayValue = color ? colorOptions.find(({ value }) => value === color)?.label || color : null;

  return (
    <components.SingleValue {...props} className={cx('select__single-value--is-color', props.className)}>
      <>
        {color ? <ColorPreview color={color} className="mr-2" /> : null}
        {displayValue}
      </>
    </components.SingleValue>
  );
}

function MenuList(props: MenuListProps<OptionT, false>) {
  const { color, colorOptions } = props.selectProps as SelectProps<OptionT> & CustomSelectProps;

  const renderOption = (option: OptionT) => (
    <div
      key={option.value}
      className={cx('colorpicker__item', {
        '-is-selected': option.value === color,
      })}
      title={option.label}
    >
      <ColorPreview
        color={option.value}
        isSelected={option.value === color}
        isHoverable
        onClick={() => props.setValue(option, 'select-option', undefined as any)}
      />
    </div>
  );

  return (
    <components.MenuList {...props}>
      <div className="colorpicker">
        <div className="colorpicker__color-group">{colorOptions.map(renderOption)}</div>
      </div>
    </components.MenuList>
  );
}

function useColorOptions(): OptionT[] {
  const { formatMessage: fmt } = useIntl();

  const dark = ` (${fmt(t.dark)})`;

  return [
    { label: fmt(t.brown), value: '#8e7159' },
    { label: fmt(t.maroon), value: '#863e3d' },
    { label: fmt(t.red), value: '#cd2f2d' },
    { label: fmt(t.orange), value: '#d2822e' },
    { label: fmt(t.yellow), value: '#c2b102' },
    { label: fmt(t.olive), value: '#6b9800' },
    { label: fmt(t.green), value: '#1f9923' },
    { label: fmt(t.cyan), value: '#03aeae' },
    { label: fmt(t.purple), value: '#5c60bc' },
    { label: fmt(t.blue), value: '#4576d1' },
    { label: fmt(t.magenta), value: '#b33fcb' },
    { label: fmt(t.grey), value: '#787878' },

    { label: fmt(t.brown) + dark, value: '#5c4a3a' },
    { label: fmt(t.maroon) + dark, value: '#572828' },
    { label: fmt(t.red) + dark, value: '#851f1d' },
    { label: fmt(t.orange) + dark, value: '#89551e' },
    { label: fmt(t.yellow) + dark, value: '#7e7301' },
    { label: fmt(t.olive) + dark, value: '#466300' },
    { label: fmt(t.green) + dark, value: '#146417' },
    { label: fmt(t.cyan) + dark, value: '#027171' },
    { label: fmt(t.purple) + dark, value: '#3c3e7a' },
    { label: fmt(t.blue) + dark, value: '#2d4d88' },
    { label: fmt(t.magenta) + dark, value: '#752984' },
    { label: fmt(t.grey) + dark, value: '#4e4e4e' },
  ];
}

const t = defineMessages({
  brown: {
    id: 'color_input_brown',
    defaultMessage: 'Brown',
  },
  maroon: {
    id: 'color_input_maroon',
    defaultMessage: 'Maroon',
  },
  red: {
    id: 'color_input_red',
    defaultMessage: 'Red',
  },
  orange: {
    id: 'color_input_orange',
    defaultMessage: 'Orange',
  },
  yellow: {
    id: 'color_input_yellow',
    defaultMessage: 'Yellow',
  },
  olive: {
    id: 'color_input_olive',
    defaultMessage: 'Olive',
  },
  green: {
    id: 'color_input_green',
    defaultMessage: 'Green',
  },
  cyan: {
    id: 'color_input_cyan',
    defaultMessage: 'Cyan',
  },
  purple: {
    id: 'color_input_purple',
    defaultMessage: 'Purple',
  },
  blue: {
    id: 'color_input_blue',
    defaultMessage: 'Blue',
  },
  magenta: {
    id: 'color_input_magenta',
    defaultMessage: 'Magenta',
  },
  grey: {
    id: 'color_input_grey',
    defaultMessage: 'Grey',
  },
  dark: {
    id: 'color_input_dark',
    defaultMessage: 'dark',
  },
  selectColor: {
    id: 'color_input_select_color',
    defaultMessage: 'Select a color...',
  },
});
