import { http } from 'api/client';

import { CenterT, PositionT, RoomT } from './centers';

export interface DialysisMachineT {
  id: string;
  systemDesign: string;
  brand: string;
  displayName: string;
  model: string;
}

export interface DeviceT {
  id: string;
  name: string;
  fullName: string;
  systemDesign: { id: string; name: string };
  brand: { id: string; name: string } | null;
  model: { id: string; name: string } | null;
  serialNumber: string;
  softwareVersion: string | null | undefined;
  ipAddress: string | null;
  linkId: string | null;
  status: 'unknown' | 'normal' | 'warning';
  online: boolean;
  alerts: number;
  center?: CenterT;
  room?: RoomT;
  position?: PositionT;
  createdAt: Date;
  updatedAt: Date;
  lastSeenAt: Date;
}

export interface DeviceModelT {
  id: string;
  systemDesign: string;
  brand: string;
  displayName: string;
  model: string;
}

export async function fetchEnabledDialysisMachines() {
  const data = await http.get<{
    models: DialysisMachineT[];
  }>('devices/enabled_dialysis_models');

  return data.data.models;
}

export async function fetchCardTypeOptions() {
  const models = await fetchEnabledDialysisMachines();

  return models.map<OptionT>((model) => ({
    label: model.displayName,
    value: model.id,
  }));
}
