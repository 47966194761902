import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { MessageDescriptor } from 'react-intl';

import {
  CheckboxGroupInput,
  CheckboxGroupInputProps,
  CheckboxOption,
  ColorInput,
  ColorInputProps,
  DateTimeInput,
  DateTimeInputProps,
  EmailInput,
  EmailInputProps,
  FileInput,
  FileInputProps,
  FloatInput,
  FloatInputProps,
  IntegerInput,
  IntegerInputProps,
  ListInput,
  ListInputProps,
  PasswordInput,
  PasswordInputProps,
  RadioGroupInput,
  RadioGroupInputProps,
  RadioOption,
  RichTextInput,
  RichTextInputProps,
  SearchInput,
  SearchInputProps,
  TextAreaInput,
  TextAreaInputProps,
  TextInput,
  TextInputProps,
  ToggleInput,
  ToggleInputProps,
} from 'components/Inputs';
import { UI } from 'components/Inputs/DateTimeInput';
import { ListOption, Value } from 'components/Inputs/ListInput';
import { BaseInputProps } from 'components/Inputs/types';

import FormField, { getIn } from '../FormField';
import { Validation } from '../validation';

export type FormFieldProps<P extends BaseInputProps<any> = BaseInputProps<any>> = Pick<BaseInputProps, 'name'> & {
  label: MessageDescriptor | string | false;
  validation?: Validation;
} & CustomOmit<P, 'defaultValue' | 'value'>;

export function CheckboxGroupField<O extends CheckboxOption = CheckboxOption>(
  props: FormFieldProps<CheckboxGroupInputProps<O>>
) {
  return (
    <FormField
      Input={CheckboxGroupInput}
      {...props}
      neverOptional
      orientation={props.orientation === undefined ? 'column' : props.orientation}
    />
  );
}

export function ColorField(props: FormFieldProps<ColorInputProps>) {
  return <FormField Input={ColorInput} {...props} />;
}

export function DateTimeField<T extends UI = 'date'>(props: FormFieldProps<DateTimeInputProps<T>>) {
  return <FormField Input={DateTimeInput} {...(props as any)} />;
}

export function EmailField(props: FormFieldProps<EmailInputProps>) {
  return <FormField Input={EmailInput} {...props} />;
}

export function FileField<Multi extends boolean = false>(props: FormFieldProps<FileInputProps<Multi>>) {
  return <FormField Input={FileInput} {...(props as any)} />;
}

export function FloatField(props: FormFieldProps<FloatInputProps>) {
  return <FormField Input={FloatInput} {...props} />;
}

export function IntegerField(props: FormFieldProps<IntegerInputProps>) {
  return <FormField Input={IntegerInput} {...props} />;
}

export function ListField<V extends Value = Value, O extends ListOption = ListOption, M extends boolean = boolean>(
  props: FormFieldProps<ListInputProps<V, O, M>>
) {
  return <FormField Input={ListInput} {...(props as any)} />;
}

export function PasswordField(props: FormFieldProps<PasswordInputProps>) {
  return <FormField Input={PasswordInput} {...props} />;
}

export function RadioGroupField<
  V extends string | number | boolean = string,
  O extends RadioOption<V> = RadioOption<V>
>(props: FormFieldProps<RadioGroupInputProps<V, O>>) {
  return <FormField Input={RadioGroupInput} {...(props as any)} neverOptional />;
}

export function RichTextField(props: FormFieldProps<RichTextInputProps>) {
  return <FormField Input={RichTextInput} {...props} />;
}

export function SearchField(props: FormFieldProps<SearchInputProps>) {
  return <FormField Input={SearchInput} {...props} />;
}

export function TextAreaField(props: FormFieldProps<TextAreaInputProps>) {
  return <FormField Input={TextAreaInput} {...props} />;
}

export function TextField(props: FormFieldProps<TextInputProps>) {
  return <FormField Input={TextInput} {...props} />;
}

export function ToggleField(props: FormFieldProps<ToggleInputProps>) {
  const { setFieldValue, values } = useFormikContext();
  const value = getIn(values, props.name);

  useEffect(() => {
    if (value === null || value === undefined) {
      setFieldValue(props.name, false, false);
    }
  }, [setFieldValue, value, props.name]);

  return <FormField Input={ToggleInput} {...props} neverOptional />;
}
