import { ReactNode } from 'react';
import cx from 'classnames';
import { useFocused, useSelected } from 'slate-react';

import { Rte, RTE_BLOCK, RTE_HEADING, RTE_INLINE, RTE_LIST } from '../domain';
import Leaf from './Leaf';

interface Props {
  children: ReactNode;
  attributes: any;
  element: Rte.Element;
}

export default function Element({ children, attributes, element }: Props) {
  const selected = useSelected();
  const focused = useFocused();

  const { type } = element;

  switch (type) {
    case RTE_LIST.ol:
      return <ol {...attributes}>{children}</ol>;

    case RTE_LIST.ul:
      return <ul {...attributes}>{children}</ul>;

    case RTE_BLOCK.listItem:
      return <li {...attributes}>{children}</li>;

    case RTE_HEADING.h1:
    case RTE_HEADING.h2:
    case RTE_HEADING.h3:
    case RTE_HEADING.h4:
    case RTE_HEADING.h5:
    case RTE_HEADING.h6: {
      const levelNr = parseInt(type.slice(-1), 10);
      const Component = `h${levelNr}`;

      return <Component {...attributes}>{children}</Component>;
    }

    case RTE_INLINE.variable:
      return (
        <span
          {...attributes}
          contentEditable={false}
          className={cx(attributes.className, 'rte-variable', {
            '-is-selected': selected && focused,
          })}
        >
          <Leaf attributes={{}} leaf={element as any}>
            {`{{${element.variable}}}`}
          </Leaf>
          {children}
        </span>
      );

    default:
      return <p {...attributes}>{children}</p>;
  }
}
