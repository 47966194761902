import { CSSProperties } from 'react';

type Props = {
  id: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (value?: string) => void;
  placeholder?: string;
  autoFocus: boolean;
  style: CSSProperties;
};

export default function String({
  id,
  defaultValue,
  disabled = false,
  onChange,
  placeholder,
  autoFocus,
  style = {},
}: Props) {
  return (
    <input
      id={id}
      type="text"
      disabled={disabled}
      defaultValue={defaultValue}
      onBlur={(event) => onChange(event.target.value.trim() || undefined)}
      placeholder={placeholder}
      autoFocus={autoFocus}
      style={style}
    />
  );
}
