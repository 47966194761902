const hasSymbol = typeof Symbol === 'function' && Symbol.for;

// https://github.com/facebook/react/blob/8482cbe22d1a421b73db602e1f470c632b09f693/packages/shared/ReactSymbols.js#L14-L16
const REACT_ELEMENT_TYPE = hasSymbol ? Symbol.for('react.element') : 0xeac7;

function isReactElement(el: any) {
  return el?.$$typeof === REACT_ELEMENT_TYPE;
}

export default function injectType<T>(Component: T, name: string | symbol) {
  const TYPE = typeof name === 'string' && hasSymbol ? Symbol.for(name) : name;
  const TypedComponent = Object.assign(Component, { $$typeof: TYPE });

  function isComponentElement(el: any) {
    return isReactElement(el) && el.type?.$$typeof === TYPE;
  }

  return [TypedComponent, isComponentElement] as const;
}
