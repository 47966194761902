import {
  Children,
  cloneElement,
  CSSProperties,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { defineMessages } from 'react-intl';

import useFold from 'hooks/useFold';

import ActionButton from 'components/ActionButton';
import Section from 'components/Section';

import { FilterFieldProps } from './Field';

interface Props<F extends Record<string, any>> {
  value: F;
  onChange: (value: F) => void;
  defaultOpen?: boolean;
  style?: CSSProperties;
  size?: 'regular' | 'small';
  children: ReactNode;
}

export default function Filter<F extends Record<string, any>>({
  value: filter,
  onChange: onChangeFilter,
  defaultOpen = false,
  style,
  size = 'regular',
  children,
}: Props<F>) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [foldRef, foldStyle] = useFold<HTMLDivElement>(isOpen);

  const filterFields = Children.toArray(children)
    .filter((field): field is ReactElement<FilterFieldProps> => isValidElement(field))
    .map((field) => {
      const { name } = field.props;

      const value = filter[name];
      const onChange = (value: unknown) => {
        onChangeFilter({ ...filter, [name]: value });
      };

      return cloneElement(field, { value, onChange });
    });

  const filterFieldNames = filterFields.map((field) => field.props.name);
  const filterFieldNamesRef = useRef(filterFieldNames);

  useEffect(() => {
    const currentNames = filterFieldNames;
    const previousNames = filterFieldNamesRef.current;

    if (currentNames.length !== previousNames.length || !currentNames.every((name) => previousNames.includes(name))) {
      filterFieldNamesRef.current = currentNames;

      onChangeFilter(
        Object.entries(filter)
          .filter(([key]) => currentNames.includes(key))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as F)
      );
    }
  }, [filterFieldNames, onChangeFilter, filter]);

  const toggleFilters = () => {
    setIsOpen((currentValue) => !currentValue);
  };

  const filterCanExpand = filterFields.length > 1;

  return (
    <Section
      className={cx('filter', `-size-${size}`, {
        '-is-indented': filterCanExpand,
      })}
      style={style}
      withoutPadding
    >
      {filterCanExpand ? (
        <>
          <div className="filter__group">
            {filterFields[0]}

            <ActionButton
              title={isOpen ? t.hideFilters : t.showAllFilters}
              icon="expand_more"
              className={cx('filter__group__toggle', {
                '-is-flipped': isOpen,
              })}
              onClick={toggleFilters}
            />
          </div>
          <div className="filter__group" ref={foldRef} style={foldStyle}>
            {filterFields.slice(1)}
          </div>
        </>
      ) : (
        <div className="filter__group">{filterFields}</div>
      )}
    </Section>
  );
}

const t = defineMessages({
  hideFilters: {
    id: 'filters_hide_filters',
    defaultMessage: 'Hide filters',
  },
  showAllFilters: {
    id: 'filters_show_all_filters',
    defaultMessage: 'Show all filters',
  },
});
